import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowDownOutlined, ArrowUpOutlined } from "@common-packages/react-icons";
import { DropdownMenu, DropdownMenuRoot } from "@digdir/designsystemet-react";
import { PersonSuitIcon } from "@navikt/aksel-icons";
import { useState } from "react";
import useEmailDomain from "@auth/hooks/useEmailDomain";
import { useMenuContext } from "@contexts/MenuContext";
import "./ImpersonateDomain.scss";
import { UserDomains } from "@contexts/PermissionsContext";
export const ImpersonateDomain = () => {
    var _a;
    const { domain, setDomain } = useEmailDomain();
    const [open, setOpen] = useState(false);
    const { setMenuOpen } = useMenuContext();
    const options = [
        { value: UserDomains.Avinor, label: "Avinor" },
        { value: UserDomains.Unknown, label: "External" },
    ];
    const selectedLabel = (_a = options.find((option) => option.value === domain)) === null || _a === void 0 ? void 0 : _a.label;
    const handleChange = (selectedValue) => {
        setDomain(selectedValue);
        setOpen(false);
    };
    const toggleSwitcher = (value) => {
        if (value) {
            setMenuOpen(false);
        }
        setOpen(value);
    };
    return (_jsx("div", { className: "impersonate-domain", children: _jsxs(DropdownMenuRoot, { open: open, size: "sm", onClose: () => toggleSwitcher(false), children: [_jsx(DropdownMenu.Trigger, { asChild: true, onClick: () => toggleSwitcher(!open), children: _jsxs("button", { className: "impersonate-domain__button", children: [_jsx(PersonSuitIcon, { color: "white", width: "1.5rem", height: "1.5rem" }), _jsx("span", { children: selectedLabel }), open ? (_jsx(ArrowUpOutlined, { color: "white", width: "0.625rem" })) : (_jsx(ArrowDownOutlined, { color: "white", width: "0.625rem" }))] }) }), _jsx(DropdownMenu.Content, { children: options.map((option) => (_jsx(DropdownMenu.Item, { value: option.value, onClick: () => handleChange(option.value), children: option.label }, option.value))) })] }) }));
};
