import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import Widget from "@components/Widget/Widget";
import { FlightMovementsNextHourCard } from "@features/FlightMovements/components/FlightMovementsNextHourCard";
import { PaxNextHourSummaryCard } from "../components/PaxNextHourSummaryCard";
export const PaxNextHourSummaryWidget = ({ direction }) => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { style: { justifyContent: "space-between" }, children: [_jsx(Widget.Header, { title: direction == "arrival" ? t("Arrival") : t("Departure"), subtitle: t("timePeriod.nextHour"), alignment: "1line" }), _jsx(FlightMovementsNextHourCard, { direction: direction }), _jsx(PaxNextHourSummaryCard, { format: "xsmall", direction: direction })] }));
};
PaxNextHourSummaryWidget.displayName = "PaxNextHourSummaryWidget";
