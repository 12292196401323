export var EWeatherLevel;
(function (EWeatherLevel) {
    EWeatherLevel["NO"] = "No Deice";
    EWeatherLevel["FZFG"] = "Level 1 [7m 30s]";
    EWeatherLevel["FZDZ"] = "Level 2 [12m 30s]";
    EWeatherLevel["DEFAULT"] = "Level 3 [15m]";
    EWeatherLevel["SN"] = "Level 4 [17m 30s]";
    EWeatherLevel["FZRA"] = "Level 5 [19m 30s]";
})(EWeatherLevel || (EWeatherLevel = {}));
export var EDeIceHandler;
(function (EDeIceHandler) {
    EDeIceHandler["SGH"] = "SGH";
    EDeIceHandler["WGH"] = "WGH";
    EDeIceHandler["MENZIES"] = "Menzies";
    EDeIceHandler["MENZIES_SGH"] = "Menzies_SGH";
    EDeIceHandler["OTHER"] = "OTHER";
    EDeIceHandler["ALL"] = "ALL";
})(EDeIceHandler || (EDeIceHandler = {}));
export var EDeIcePad;
(function (EDeIcePad) {
    EDeIcePad["AN"] = "AN";
    EDeIcePad["AS"] = "AS";
})(EDeIcePad || (EDeIcePad = {}));
