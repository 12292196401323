var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMsal } from "@azure/msal-react";
import { CardOverview, DiagramBars, PlaneLanding, Settings, BoardingCard } from "@common-packages/react-icons";
import { BaggagePage, DelaysPage, FacilityStatusPage, FlightMovementsPage, NotFoundPage, OperationalMessagesPage, PaxPage, PunctualityRegularityPage, MainPage, AirportTransportationPage, DeIcePage, } from "@pages";
import { addDays, addMinutes, startOfDay } from "date-fns";
import { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import FeedbackButton from "@components/Buttons/FeedbackButton/FeedbackButton";
import ToggleUTCButton from "@components/Buttons/ToggleUtcButton/ToggleUtcButton";
import Footer from "@components/Footer/Footer";
import Header from "@components/Header/Header";
import Navigation from "@components/Navigation/Navigation";
import Spinner from "@components/Spinner/Spinner";
import { breakpoints } from "@constants";
import { ERoute, ESubRoute } from "@enums";
import { createAuthRequest } from "@auth/authUtils";
import NavSignOutButton from "@auth/components/NavSignOutButton/NavSignOutButton";
import { AuthScope } from "@auth/constants";
import { useMediaQuery } from "@hooks";
import BigScreenPage from "@pages/BigScreenPage/BigScreenPage";
import PlanOverviewPage from "@pages/PlanOverviewPage";
import ProcessesPage from "@pages/ProcessesPage";
import { MenuProvider, useMenuContext } from "@contexts/MenuContext";
import { TopLevelNavItemContext } from "@contexts/NavContext";
import { useScreenMode } from "@contexts/ScreenModeContext";
import { useUtcContext } from "@contexts/UtcContext";
const AuthenticatedApp = () => {
    const { isTvScreen } = useScreenMode();
    const { instance, accounts } = useMsal();
    useEffect(() => {
        const forceRefreshToken = async () => {
            const account = accounts[0];
            const request = createAuthRequest([AuthScope.AopAccessApi]);
            if (account) {
                try {
                    await instance.acquireTokenSilent(Object.assign(Object.assign({}, request), { // Replace with actual scopes
                        account, forceRefresh: true }));
                }
                catch (error) {
                    console.error("Token refresh failed:", error);
                }
            }
        };
        forceRefreshToken();
    }, [instance, accounts]);
    return isTvScreen ? _jsx(FullScreenModeApp, {}) : _jsx(StandardModeApp, {});
};
const FullScreenModeApp = () => {
    useEffect(() => {
        const scheduleMidnightRefresh = () => {
            const now = new Date();
            const nextMidnightPlusOne = addMinutes(startOfDay(addDays(now, 1)), 1); // 00:01 of the next day
            const delay = nextMidnightPlusOne.getTime() - now.getTime();
            return setTimeout(() => {
                window.location.reload();
                // After the first midnight refresh, re-schedule for the next midnight
                scheduleMidnightRefresh();
            }, delay);
        };
        // Schedule refresh every 6 hours
        const intervalId = setInterval(() => {
            window.location.reload();
        }, 21600000); // 6 hours in milliseconds
        // Schedule refresh for 1 minute past midnight
        const midnightTimeoutId = scheduleMidnightRefresh();
        return () => {
            clearInterval(intervalId);
            clearTimeout(midnightTimeoutId);
        };
    }, []);
    return (_jsx("div", { className: "app", children: _jsx("div", { className: "app__body", children: _jsx("main", { className: "app__body__fullscreen-mode", "data-ds-theme": "dark", "data-ds-color-mode": "dark", children: _jsx(Routes, { children: _jsx(Route, { path: "/tv", element: _jsx(BigScreenPage, {}) }) }) }) }) }));
};
const StandardModeApp = () => {
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    const routes = [
        { path: ERoute.Overview, component: _jsx(MainPage, {}) },
        { path: `${ERoute.Performance}${ESubRoute.Punctuality}`, component: _jsx(PunctualityRegularityPage, {}) },
        { path: `${ERoute.Performance}${ESubRoute.Delays}`, component: _jsx(DelaysPage, {}) },
        { path: `${ERoute.Performance}${ESubRoute.OperationalMessages}`, component: _jsx(OperationalMessagesPage, {}) },
        { path: `${ERoute.Performance}${ESubRoute.AirportTransportation}`, component: _jsx(AirportTransportationPage, {}) },
        { path: `${ERoute.Performance}${ESubRoute.Processes}`, component: _jsx(ProcessesPage, {}) },
        { path: `${ERoute.Terminal}${ESubRoute.Passengers}`, component: _jsx(PaxPage, {}) },
        { path: `${ERoute.Terminal}${ESubRoute.Baggage}`, component: _jsx(BaggagePage, {}) },
        { path: `${ERoute.Terminal}${ESubRoute.Facility}`, component: _jsx(FacilityStatusPage, {}) },
        { path: `${ERoute.Airside}${ESubRoute.FlightMovements}`, component: _jsx(FlightMovementsPage, {}) },
        { path: `${ERoute.Airside}${ESubRoute.WinterOps}`, component: _jsx(DeIcePage, {}) },
        { path: `${ERoute.Plan}${ESubRoute.PlanOverview}`, component: _jsx(PlanOverviewPage, {}) },
    ];
    return (_jsxs("div", { className: "app", children: [_jsxs(MenuProvider, { children: [_jsx(Header, {}), isMobile && _jsx(StandardModeAppNavigation, {})] }), _jsxs("div", { className: "app__body", children: [!isMobile && (_jsx(MenuProvider, { children: _jsx(StandardModeAppNavigation, {}) })), _jsx("main", { className: "app__body__main", children: _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsxs(Routes, { children: [routes.map(({ path, component }) => (_jsx(Route, { path: path, element: component }, `Route for path: ${path}`))), _jsx(Route, { path: "*", element: _jsx(NotFoundPage, {}) })] }) }) })] }), _jsx(Footer, {})] }));
};
const StandardModeAppNavigation = () => {
    const { menuOpen, setMenuOpen } = useMenuContext();
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    const handleItemClicked = () => {
        setMenuOpen(false);
    };
    const { setUtc } = useUtcContext();
    const { t } = useTranslation();
    useEffect(() => {
        if (isMobile) {
            document.body.style.overflow = menuOpen ? "hidden" : "hidden auto";
        }
    }, [menuOpen]);
    const navItems = [
        {
            rootPath: ERoute.Overview,
            title: t("nav.overview"),
            icon: CardOverview,
            path: ERoute.Overview,
            onItemClicked: handleItemClicked,
        },
        {
            rootPath: ERoute.Performance,
            title: t("nav.performance"),
            icon: DiagramBars,
            childItems: [
                {
                    name: t("nav.capacityPlanning"),
                    path: `${ERoute.Plan}${ESubRoute.PlanOverview}`,
                    onChildItemClicked: handleItemClicked,
                },
                {
                    name: t("nav.punctualityAndRegularity"),
                    path: `${ERoute.Performance}${ESubRoute.Punctuality}`,
                    onChildItemClicked: handleItemClicked,
                },
                {
                    name: t("nav.delays"),
                    path: `${ERoute.Performance}${ESubRoute.Delays}`,
                    onChildItemClicked: handleItemClicked,
                },
                {
                    name: t("nav.operationalMessages"),
                    path: `${ERoute.Performance}${ESubRoute.OperationalMessages}`,
                    onChildItemClicked: handleItemClicked,
                },
                {
                    name: t("nav.airportTransportation"),
                    path: `${ERoute.Performance}${ESubRoute.AirportTransportation}`,
                    onChildItemClicked: handleItemClicked,
                },
                {
                    name: t("nav.processes"),
                    path: `${ERoute.Performance}${ESubRoute.Processes}`,
                    onChildItemClicked: handleItemClicked,
                },
            ],
        },
        {
            rootPath: ERoute.Terminal,
            title: t("nav.terminal"),
            icon: BoardingCard,
            childItems: [
                {
                    name: t("nav.passengers"),
                    path: `${ERoute.Terminal}${ESubRoute.Passengers}`,
                    onChildItemClicked: handleItemClicked,
                },
                {
                    name: t("nav.baggage"),
                    path: `${ERoute.Terminal}${ESubRoute.Baggage}`,
                    onChildItemClicked: handleItemClicked,
                },
                {
                    name: t("nav.facility"),
                    path: `${ERoute.Terminal}${ESubRoute.Facility}`,
                    onChildItemClicked: handleItemClicked,
                },
            ],
        },
        {
            rootPath: ERoute.Airside,
            title: t("nav.airside"),
            icon: PlaneLanding,
            childItems: [
                {
                    name: t("nav.flightMovements"),
                    path: `${ERoute.Airside}${ESubRoute.FlightMovements}`,
                    onChildItemClicked: handleItemClicked,
                },
                {
                    name: t("nav.winterOps"),
                    path: `${ERoute.Airside}${ESubRoute.WinterOps}`,
                    onChildItemClicked: handleItemClicked,
                },
            ],
        },
        {
            title: t("nav.settings"),
            icon: Settings,
            childItems: [
                {
                    onChildItemClicked: () => setUtc((utc) => !utc),
                    children: _jsx(ToggleUTCButton, {}),
                },
                {
                    children: _jsx(FeedbackButton, {}),
                },
                {
                    children: _jsx(NavSignOutButton, {}),
                },
            ],
        },
    ];
    return (_jsx(Navigation, { open: menuOpen, onClose: () => setMenuOpen(false), defaultExpandedTopLevelNavItems: isMobile
            ? ["/", "Driftsstatus", "Operational status", "Flyside", "Terminal", "Airside"]
            : ["/", "Driftsstatus", "Operational status", "Flyside", "Terminal", "Airside"], children: navItems.map((_a) => {
            var { rootPath, title } = _a, restProps = __rest(_a, ["rootPath", "title"]);
            return (_jsx(TopLevelNavItemContext.Provider, { value: title, children: _jsx(Navigation.Item, Object.assign({ rootPath: rootPath }, restProps)) }, title));
        }) }));
};
export default AuthenticatedApp;
