import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./AirportTransportationAlertIndicator.scss";
import AlertIndicator from "../AlertIndicator/AlertIndicator";
const AirportTransportationAlertIndicator = ({ value, variant }) => {
    if (value === 0)
        return null;
    if (value && value > 3) {
        return (_jsx(AlertIndicator, { variant: variant, value: value, styles: { padding: "0.75rem" } }));
    }
    const circles = Array(value)
        .fill(null)
        .map((_, idx) => (_jsx(AlertIndicator, { styles: { width: "0.625rem", height: "0.625rem" }, variant: variant }, idx)));
    return (_jsxs("span", { className: "airport-transportation-alert-indicator", children: [circles, " ", _jsx("span", { children: value })] }));
};
export default AirportTransportationAlertIndicator;
