import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { InfoICircleOutlined } from "@avinor/react-icons";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { PunctualityProgressBar } from "../components";
export const PunctualityProgressBarWidget = () => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t("onTimePerformance.punctuality.widgets.stats.title"), subtitleVisibility: "none", icon: {
                    component: (_jsx(InfoICircleOutlined, { width: "1rem", height: "1rem" })),
                    tooltip: {
                        id: "punctualityProgressBarWidget",
                        html: `<h3>${t("onTimePerformance.punctuality.widgets.stats.title")}</h3><p>${t("onTimePerformance.punctuality.widgets.stats.explanation")}</p>`,
                    },
                } }), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Widget.Body, { children: _jsx(PunctualityProgressBar, { direction: "D" }) }) })] }));
};
PunctualityProgressBarWidget.displayName = "PunctualityProgressBarWidget";
