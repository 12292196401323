import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Progress.scss";
import clsx from "clsx";
export const progressStatus = (percentage, thresholds) => {
    thresholds = thresholds || [
        { threshold: 0, status: "danger" },
        { threshold: 80, status: "warning" },
        { threshold: 90, status: "success" },
    ];
    return (thresholds.sort((a, b) => b.threshold - a.threshold).find(({ threshold }) => percentage >= threshold) || {
        status: "neutral",
    });
};
const Progress = ({ text, textOnBar, width, hasWhiteBackground = false, size = "large", variant = "percentage", completedPercentage = 0, percentageVisibility = "visible", thresholds, withPadding = false, fixedBackground = false, }) => {
    const percentage = Math.floor(Number(completedPercentage));
    const status = variant === "percentage" ? progressStatus(percentage, thresholds).status : "neutral";
    const progressClass = clsx("progress", {
        "progress--no-padding": !withPadding,
    });
    const barContainerClass = clsx("progress__bar-container", {
        [`progress__bar-container--${size}--${status}`]: true,
        "progress__bar-container--white": hasWhiteBackground,
    });
    const barProgressClass = clsx("progress__bar-progress", {
        [`progress__bar-progress--${status}`]: true,
        [`progress__bar-progress--${size}`]: true,
        "progress__bar-progress--with-text": textOnBar, // Add class if textOnBar is not empty
    });
    const headerTextClass = clsx("progress__bar-text", {
        [`progress__bar-text--${status}`]: true,
        [`progress__bar-text--${size}`]: true,
    });
    return (_jsxs("div", { className: progressClass, children: [percentage !== undefined && (_jsxs("div", { className: "progress__bar", children: [_jsx("div", { className: barContainerClass, style: { backgroundColor: fixedBackground ? "var(--avinor-grey-50)" : undefined }, children: _jsx("div", { className: barProgressClass, style: {
                                width: `${percentage}%`,
                                backgroundColor: fixedBackground ? "var(--avinor-grey-100)" : undefined,
                            }, children: textOnBar && _jsx("span", { children: textOnBar }) }) }), percentageVisibility === "visible" && _jsxs("p", { className: headerTextClass, children: [percentage, " %"] })] })), text && (_jsx("div", { className: "progress__footer", children: _jsx("p", { className: "progress__footer-text", style: { width }, children: text }) }))] }));
};
Progress.displayName = "Progress";
export default Progress;
