import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Spinner from "@components/Spinner/Spinner";
import { getWeatherData } from "@features/Plan/services";
import "./PlanOverviewWeatherStatus.scss";
const PlanOverviewWeatherStatus = ({ iata }) => {
    var _a, _b;
    const { data: weatherData, isLoading: weatherLoading, error: weatherError, } = useQuery({
        queryFn: () => getWeatherData(iata),
        queryKey: ["getWeatherData", iata],
    });
    const currentTime = new Date();
    const currentHour = currentTime.getUTCHours();
    // Find the timeseries object that matches the current hour
    const currentWeather = weatherData === null || weatherData === void 0 ? void 0 : weatherData.properties.timeseries.find((timeserie) => {
        const timeserieHour = new Date(timeserie.time).getUTCHours();
        return timeserieHour === currentHour;
    });
    if (weatherLoading) {
        return _jsx(Spinner, {});
    }
    if (weatherError || !weatherData || !currentWeather) {
        return _jsx(NoDataAvailable, {});
    }
    const windDirection = currentWeather === null || currentWeather === void 0 ? void 0 : currentWeather.data.instant.details.wind_from_direction;
    const precipitation = (_a = currentWeather === null || currentWeather === void 0 ? void 0 : currentWeather.data.next_1_hours) === null || _a === void 0 ? void 0 : _a.details.precipitation_amount;
    const airTemperature = currentWeather === null || currentWeather === void 0 ? void 0 : currentWeather.data.instant.details.air_temperature;
    const windSpeed = currentWeather === null || currentWeather === void 0 ? void 0 : currentWeather.data.instant.details.wind_speed;
    const windSpeedGusts = currentWeather === null || currentWeather === void 0 ? void 0 : currentWeather.data.instant.details.wind_speed_of_gust;
    return (_jsxs("div", { className: "WeatherWidget-weather-info", children: [_jsxs("div", { className: "WeatherWidget-temperature-icon", children: [_jsxs("span", { className: "WeatherWidget-temperature" + (airTemperature <= 0 ? " WeatherWidget-temperature-cold" : ""), children: [Math.round(airTemperature), "\u00B0"] }), _jsx("img", { className: "WeatherWidget-weather-icon", src: `/weather-icons/${(_b = currentWeather === null || currentWeather === void 0 ? void 0 : currentWeather.data.next_1_hours) === null || _b === void 0 ? void 0 : _b.summary.symbol_code}.svg`, alt: "weather icon" })] }), _jsxs("div", { className: "WeatherWidget-side-info-wrapper", children: [_jsxs("div", { className: "WeatherWidget-side-info", children: [_jsx("img", { className: "WeatherWidget-side-info-icon", src: `/weather-icons/umbrella.svg`, alt: "weather icon" }), _jsxs("span", { className: "WeatherWidget-precipitation-info", children: [precipitation, " mm"] })] }), _jsxs("div", { className: "WeatherWidget-side-info", children: [_jsx("img", { className: "WeatherWidget-side-info-icon", src: `/weather-icons/wind.svg`, alt: "weather icon" }), _jsxs("span", { className: "WeatherWidget-wind-info", children: [Math.round(windSpeed), " (", Math.round(windSpeedGusts), ") m/s"] }), _jsx("div", { style: { transform: `rotate(${windDirection}deg)` }, className: "WeatherWidget-side-info-wind-direction", children: _jsx("img", { className: "WeatherWidget-side-info-wind-direction-icon", src: `/weather-icons/wind_direction_arrow.svg`, alt: "weather icon" }) })] })] })] }));
};
export default PlanOverviewWeatherStatus;
