import { httpOperationalMessagesService, httpStatusMessagesService } from "@services/HttpService";
import { resolveUrl } from "@utils/UrlUtils";
export async function getAllOperationalMessages({ limit = 20, afterDate, sender, }) {
    const params = new URLSearchParams("");
    if (afterDate)
        params.set("afterDate", afterDate);
    if (sender)
        params.set("sender", sender);
    params.set("limit", limit.toString());
    try {
        const url = resolveUrl({
            url: `/messages/all?${params}`,
            mockEndpoint: "/messages",
        });
        const response = await httpOperationalMessagesService.get(url);
        return response.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}
export async function getOperationalMessages({ iata, limit = 20, afterDate, sender, }) {
    const params = new URLSearchParams("");
    if (afterDate)
        params.set("afterDate", afterDate);
    if (sender)
        params.set("sender", sender);
    params.set("limit", limit.toString());
    try {
        const response = await httpOperationalMessagesService.get(resolveUrl({
            url: `/messages/${iata}?${params}`,
            mockEndpoint: "/messages",
        }));
        return response.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}
// Messages for status banner
export async function getStatusMessages() {
    try {
        const response = await httpStatusMessagesService.get(resolveUrl({
            url: `/`,
            mockEndpoint: "/",
        }));
        // Check the response content type to ensure it's JSON
        const contentType = response.headers["content-type"];
        if (!contentType || !contentType.includes("application/json")) {
            console.error("API did not return JSON. Content-Type is:", contentType);
            return null; // Handle the case where the content is not JSON
        }
        return response.data;
    }
    catch (error) {
        console.error("Error fetching status messages:", error);
        return null; // Return null in case of error
    }
}
