import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./PlanOverviewDisruptions.scss";
import { PlanOverviewDisruptionAreaTooltip } from "./PlanOverviewDisruptionAreaTooltip";
import { PlanOverviewDisruptionTooltip } from "./PlanOverviewDisruptionTooltip";
export const PlanOverviewDisruptions = ({ id, actor, disruptions, small, hasBeenSaved = false, isAreaDisruptions = false, }) => {
    return (_jsx("div", { style: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginRight: 0,
            marginLeft: 0,
        }, children: _jsx("div", { className: "plan-overview-timeline-container", children: disruptions.map((disruption, index) => {
                let segmentClass = `plan-overview-timeline-segment`;
                segmentClass += `${disruption.fyi ? " plan-overview-timeline-segment-info" : ""} ${disruption.someDisturbances ? " plan-overview-timeline-segment-warning" : ""} ${disruption.majorDisturbances ? " plan-overview-timeline-segment-error" : ""}`;
                if (isAreaDisruptions) {
                    segmentClass += ` plan-overview-timeline-segment-area`;
                }
                if (hasBeenSaved) {
                    segmentClass += " plan-overview-timeline-segment-saved";
                }
                if (small) {
                    segmentClass += ` plan-overview-timeline-segment-small`;
                }
                const uniqueTooltipId = `${actor}${id}${index}`;
                const showTooltip = disruption.fyi || disruption.someDisturbances || disruption.majorDisturbances;
                return (_jsxs("div", { className: "plan-overview-timeline-segment-wrapper" +
                        (index == 5 || index == 11 || index == 17
                            ? " plan-overview-timeline-segment-wrapper-margin"
                            : ""), children: [isAreaDisruptions && index === 6 && (_jsx("p", { className: "plan-overview-timeline-segment-label", children: "06" })), isAreaDisruptions && index === 12 && (_jsx("p", { className: "plan-overview-timeline-segment-label", children: "12" })), isAreaDisruptions && index === 18 && (_jsx("p", { className: "plan-overview-timeline-segment-label", children: "18" })), showTooltip ? (_jsxs("div", { className: "plan-overview-timeline-segment-tooltip-hover-area", "data-tooltip-id": "plan-overview-tooltip-" + uniqueTooltipId, "data-tooltip-place": "top", "data-tooltip-position-strategy": "fixed", children: [_jsx("div", { className: segmentClass }), disruption.subDisruptions ? (_jsx(PlanOverviewDisruptionAreaTooltip, { id: "plan-overview-tooltip-" + uniqueTooltipId, disruption: disruption })) : (_jsx(PlanOverviewDisruptionTooltip, { processTypeName: actor, id: "plan-overview-tooltip-" + uniqueTooltipId, disruption: disruption }))] })) : (_jsx("div", { className: segmentClass }))] }, index + "-" + id));
            }) }) }));
};
