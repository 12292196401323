import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./DeIceWeatherCalendar.scss";
import { InfoICircleOutlined } from "@common-packages/react-icons";
import nbLocale from "@fullcalendar/core/locales/nb";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { VStack } from "@components/Layout";
import Spinner from "@components/Spinner/Spinner";
import { breakpoints } from "@constants";
import { useDeIceHasChangesContext } from "@features/DeIce/contexts/DeIceHasChangesContext";
import { useDeIceWeatherContext } from "@features/DeIce/contexts/DeIceWeatherContext";
import { EWeatherLevel } from "@features/DeIce/enums";
import { useMediaQuery } from "@hooks";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { DeIceWeatherCalendarEvent } from "./DeIceWeatherCalendarEvent";
import { createEventKey, createEventObject, getAllDayDeviation, getDeviationEvents } from "./DeIceWeatherCalendarUtils";
import { DeIceWeatherCreateDeviationModal } from "./DeIceWeatherCreateDeviationModal";
const DeIceWeatherCalendar = ({ isLoading, disabled = false }) => {
    const modalRef = useRef(null);
    const [selectInfo, setSelectInfo] = useState();
    const [clickInfo, setClickInfo] = useState();
    const [selectedDeviation, setSelectedDeviation] = useState();
    const { deIceWeather, setDeIceWeather } = useDeIceWeatherContext();
    const { setDeIceHasChanges } = useDeIceHasChangesContext();
    const { t } = useTranslation();
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
    const is4kScreen = useMediaQuery(`(min-width: ${breakpoints.width4k})`);
    const { picker: { from }, } = useDatePickerContext();
    const getActiveDeviations = (deIceWeather, from, isDisabled = false) => {
        const deviations = getDeviationEvents(deIceWeather, from, isDisabled);
        if (selectedDeviation && !deviations.find((deviation) => deviation.id === createEventKey(selectedDeviation))) {
            deviations.push(createEventObject(selectedDeviation, isDisabled));
        }
        return deviations;
    };
    const cancelDeviation = () => {
        clearDateSelection();
    };
    const createOrEditDeviation = (deviation) => {
        var _a;
        if (!deIceWeather)
            return;
        if (deviation.allDay) {
            setDeIceWeather(Object.assign(Object.assign({}, deIceWeather), { baseWeather: deviation.weather }));
        }
        else {
            // Filter out the existing deviation if it exists
            const deviationsFiltered = deIceWeather.weatherDeviations.filter((x) => x.from != deviation.from && x.to != deviation.to);
            // Append the new deviation to the existing deviations list
            const updatedDeviations = [...deviationsFiltered, deviation];
            // Update the deIceWeather context with the updated deviations
            setDeIceWeather(Object.assign(Object.assign({}, deIceWeather), { weatherDeviations: updatedDeviations }));
        }
        // Trigger re-render by setting changes
        setDeIceHasChanges(true);
        // Clear date selection and close modal
        clearDateSelection();
        (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.close();
    };
    const deleteDeviation = (deviation) => {
        if (!deIceWeather || !deIceWeather.weatherDeviations)
            return;
        const deviationsFiltered = deIceWeather.weatherDeviations.filter((x) => x.from != deviation.from && x.to != deviation.to);
        // Update the state with the filtered list
        setDeIceWeather(Object.assign(Object.assign({}, deIceWeather), { weatherDeviations: deviationsFiltered }));
        // Indicate that changes were made
        setDeIceHasChanges(true);
    };
    const clearDateSelection = () => {
        var _a;
        if (selectInfo) {
            const calendarApi = selectInfo.view.calendar;
            (_a = selectInfo.view.calendar.getEventById("new-event-temp")) === null || _a === void 0 ? void 0 : _a.remove();
            calendarApi.unselect(); // clear date selection
            setSelectInfo(null);
        }
        if (clickInfo) {
            setClickInfo(null);
        }
        setSelectedDeviation(null);
    };
    //Called selecting a new date on the calendar, creating a new deviation
    const handleDateSelect = (selectInfo) => {
        var _a, _b;
        setSelectInfo(selectInfo);
        const maxId = Math.max(...(((_a = deIceWeather === null || deIceWeather === void 0 ? void 0 : deIceWeather.weatherDeviations) === null || _a === void 0 ? void 0 : _a.map((deviation) => deviation.id)) || [1]));
        const newDeviation = {
            weather: "",
            from: selectInfo.start.toISOString(),
            to: selectInfo.end.toISOString(),
            allDay: selectInfo.allDay,
            id: maxId + 1,
        };
        setSelectedDeviation(newDeviation);
        (_b = modalRef.current) === null || _b === void 0 ? void 0 : _b.showModal();
    };
    //Called when clicking on events on calendar, editing a deviation
    const handleEventClick = (clickInfo) => {
        var _a, _b;
        if (!disabled) {
            setClickInfo(clickInfo);
            if (clickInfo.event.allDay && deIceWeather) {
                const clickedEvent = getAllDayDeviation(deIceWeather, from, disabled);
                if (clickedEvent === null || clickedEvent === void 0 ? void 0 : clickedEvent.extendedProps) {
                    const deviation = clickedEvent.extendedProps;
                    deviation.allDay = true;
                    setSelectedDeviation(deviation);
                    (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
                }
            }
            else {
                const clickedDeviationsData = deIceWeather === null || deIceWeather === void 0 ? void 0 : deIceWeather.weatherDeviations.find((x) => {
                    var _a, _b;
                    return new Date(x.from).getTime() === ((_a = clickInfo.event.start) === null || _a === void 0 ? void 0 : _a.getTime()) &&
                        new Date(x.to).getTime() === ((_b = clickInfo.event.end) === null || _b === void 0 ? void 0 : _b.getTime());
                });
                if (clickedDeviationsData) {
                    setSelectedDeviation(clickedDeviationsData);
                    (_b = modalRef.current) === null || _b === void 0 ? void 0 : _b.showModal();
                }
            }
        }
    };
    //Called when dragging events on calendar
    const handleEventDrop = (info) => {
        resizeEventOnCalendar(info);
    };
    //Called when reiszing events on calendar
    const handleEventResize = (info) => {
        resizeEventOnCalendar(info);
    };
    const resizeEventOnCalendar = (info) => {
        var _a, _b;
        const { event } = info;
        // Find the deviation to update based on the resized event's start and end time
        const deviationToUpdate = deIceWeather === null || deIceWeather === void 0 ? void 0 : deIceWeather.weatherDeviations.find((deviation) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            return new Date(deviation.from).getTime() === ((_c = (_b = (_a = info.oldEvent) === null || _a === void 0 ? void 0 : _a.start) === null || _b === void 0 ? void 0 : _b.getTime()) !== null && _c !== void 0 ? _c : (_d = event.start) === null || _d === void 0 ? void 0 : _d.getTime()) &&
                new Date(deviation.to).getTime() === ((_g = (_f = (_e = info.oldEvent) === null || _e === void 0 ? void 0 : _e.end) === null || _f === void 0 ? void 0 : _f.getTime()) !== null && _g !== void 0 ? _g : (_h = event.end) === null || _h === void 0 ? void 0 : _h.getTime());
        });
        if (deviationToUpdate && deIceWeather) {
            // Update the deviation's start and end times to reflect the resized event
            const updatedDeviation = Object.assign(Object.assign({}, deviationToUpdate), { from: ((_a = event.start) === null || _a === void 0 ? void 0 : _a.toISOString()) || deviationToUpdate.from, to: ((_b = event.end) === null || _b === void 0 ? void 0 : _b.toISOString()) || deviationToUpdate.to });
            // Update the list of deviations
            const updatedDeviations = deIceWeather.weatherDeviations.map((deviation) => deviation === deviationToUpdate ? updatedDeviation : deviation);
            // Update the context with the modified deviations
            setDeIceWeather(Object.assign(Object.assign({}, deIceWeather), { weatherDeviations: updatedDeviations }));
            // Indicate that changes have been made
            setDeIceHasChanges(true);
        }
        else {
            console.warn("No matching deviation found to update during resize.");
        }
    };
    const renderEventContent = (eventInfo) => {
        return _jsx(DeIceWeatherCalendarEvent, { eventInfo: eventInfo });
    };
    return (_jsxs("div", { className: "deice-weather-calendar", children: [_jsxs("div", { className: "deice-weather-calendar-header", children: [_jsxs(VStack, { justify: "space-between", children: [_jsx("h4", { className: "deice-weather-calendar-title", children: t("deice.weather.title") }), _jsx("h4", { className: "deice-weather-calendar-subtitle", children: t("deice.weather.subTitle") })] }), _jsxs(Tooltip, { className: "deice-weather-calendar-tooltip", id: "deice-weather-informationTooltip", children: [_jsx("h4", { className: "deice-weather-calendar-tooltip-title", children: t("deice.weather.informationTooltip.weatherCondition") }), _jsx("div", { className: "deice-weather-calendar-tooltip-paragraph", style: { marginTop: "0" }, children: t("deice.weather.informationTooltip.weatherConditionInformationP1") }), _jsx("div", { className: "deice-weather-calendar-tooltip-paragraph", children: t("deice.weather.informationTooltip.weatherConditionInformationP2") }), _jsx("h4", { children: t("deice.weather.informationTooltip.weatherTypes") }), Object.entries(EWeatherLevel).map(([key, value]) => (_jsx("div", { children: value }, key)))] }), _jsx(InfoICircleOutlined, { "data-tooltip-id": "deice-weather-informationTooltip", width: "1rem", height: "1rem" })] }), isLoading ? (_jsx(Spinner, {})) : (_jsx("div", { className: disabled ? "full-calendar-disabled" : "", children: _jsx(FullCalendar, { plugins: [timeGridPlugin, interactionPlugin], initialView: "timeGridDay", initialDate: from.toISOString(), editable: !disabled, selectable: !disabled, selectMirror: true, selectOverlap: (selectInfo) => {
                        // Allow overlap if the existing event is all-day
                        if (selectInfo.allDay)
                            return true;
                        // For timed events, check if the selection overlaps
                        if (selectInfo.start && selectInfo.end) {
                            const isNoOverlap = selectInfo.start >= selectInfo.end || selectInfo.end <= selectInfo.start;
                            return isNoOverlap;
                        }
                        return true;
                    }, eventOverlap: (stillEvent, movingEvent) => {
                        // Allow overlap only if the still event or moving event is all-day
                        if (stillEvent.allDay || (movingEvent === null || movingEvent === void 0 ? void 0 : movingEvent.allDay)) {
                            return true;
                        }
                        // For timed events, prevent overlap
                        return false;
                    }, dayMaxEvents: true, height: isTablet ? "auto" : is4kScreen ? 800 : 400, dayHeaders: false, events: getActiveDeviations(deIceWeather, from, disabled), select: handleDateSelect, eventContent: renderEventContent, eventClick: handleEventClick, locale: nbLocale, slotLabelFormat: {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                    }, eventTimeFormat: {
                        hour: "2-digit",
                        minute: "2-digit",
                        meridiem: false,
                    }, slotMinTime: "00:00:00", slotMaxTime: "24:00:00", slotLabelInterval: "06:00:00", slotDuration: "01:00:00", eventResizableFromStart: true, eventDurationEditable: true, longPressDelay: 100, eventDrop: (info) => {
                        if (info.event && info.event.end && info.event.start) {
                            if (info.event.end >
                                new Date(info.event.start.getFullYear(), info.event.start.getMonth(), info.event.start.getDate(), 24, 0)) {
                                info.revert(); // Revert the event to avoid overlap beyond the day
                            }
                            else {
                                handleEventDrop(info); // Custom drop handler logic
                            }
                        }
                    }, eventResize: (info) => handleEventResize(info), allDaySlot: true, eventConstraint: {
                        start: "00:00", // Start of the day
                        end: "24:00", // End of the day
                    }, headerToolbar: false }, from.toISOString()) })), _jsx(DeIceWeatherCreateDeviationModal, { modalRef: modalRef, selectedDeviation: selectedDeviation, createOrEditDeviation: (updatedDeviation) => {
                    createOrEditDeviation(updatedDeviation);
                }, deleteDeviation: (deviation) => {
                    deleteDeviation(deviation);
                }, cancelDeviation: cancelDeviation })] }));
};
export default DeIceWeatherCalendar;
