import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, Heading, Modal, Paragraph } from "@digdir/designsystemet-react";
import { CheckmarkCircleFillIcon, ClockIcon, FloppydiskIcon } from "@navikt/aksel-icons";
import { useQuery } from "@tanstack/react-query";
import { isBefore, parseISO, startOfToday } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./DeIceSettingsModal.scss";
import { prettyPrintDate } from "@utils/DateUtils";
import AuthorizedButton from "@components/Buttons/AuthorizedButton/AuthorizedButton";
import { LeaveWithoutSavingModal } from "@components/Modals/LeaveWithoutSavingModal";
import Spinner from "@components/Spinner/Spinner";
import { Delay } from "@enums";
import { useDeIceHasChangesContext } from "@features/DeIce/contexts/DeIceHasChangesContext";
import { useDeIceWeatherContext } from "@features/DeIce/contexts/DeIceWeatherContext";
import useDeIceCapacityData from "@features/DeIce/hooks/useDeIceCapacityData";
import { useDeIceDateSelector } from "@features/DeIce/hooks/useDeIceDateSelector";
import useDeIceWeatherData from "@features/DeIce/hooks/useDeIceWeatherData";
import { useSubmitDeIceCapacityAndPadConfig } from "@features/DeIce/hooks/useSubmitDeIceCapacity";
import { getPadConfiguration } from "@features/DeIce/services";
import useRoles from "@auth/hooks/useRoles";
import { useIataContext } from "@contexts/IataContext";
import DeIceBays from "../DeIceBays/DeIceBays";
import DeIceCapacityScheduler from "../DeIceCapacityScheduler/DeIceCapacityScheduler";
import DeIcePads from "../DeIcePads/DeIcePads";
import DeIceWeatherCalendar from "../DeIceWeatherCalendar/DeIceWeatherCalendar";
export const DeIceSettingsModal = ({ modalRef, onClose }) => {
    const from = useDeIceDateSelector();
    const { data: deIceHandlerCapacityData } = useDeIceCapacityData(from);
    const [deIceHandlerCapacity, setDeIceHandlerCapacity] = useState([]);
    const { iata } = useIataContext();
    const deIceDataDate = useDeIceDateSelector();
    const [modifiedPads, setModifiedPads] = useState([]);
    const { deIceHasChanges, setDeIceHasChanges } = useDeIceHasChangesContext();
    const { t, i18n } = useTranslation();
    const { operationStatus, submitDeIceCapacityAndPadConfig } = useSubmitDeIceCapacityAndPadConfig();
    const { deIceWeather, setDeIceWeather } = useDeIceWeatherContext();
    const { hasAdminRole } = useRoles();
    const { data: deIceConfiguration, isLoading } = useQuery({
        queryFn: () => getPadConfiguration({
            date: deIceDataDate,
            iata,
        }),
        refetchInterval: 0,
        staleTime: 0,
        refetchOnWindowFocus: false,
        gcTime: 0,
        queryKey: ["getPadConfiguration", { deIceDataDate, iata }],
    });
    const { data: deiceWeatherData, isLoading: isLoadingWeather } = useDeIceWeatherData();
    const leaveWithoutSavingModalRef = useRef(null);
    useEffect(() => {
        if (deIceHandlerCapacityData === null || deIceHandlerCapacityData === void 0 ? void 0 : deIceHandlerCapacityData.handlerCapacity) {
            setDeIceHandlerCapacity(deIceHandlerCapacityData.handlerCapacity);
        }
    }, [deIceHandlerCapacityData]);
    useEffect(() => {
        setDeIceWeather(deiceWeatherData || {});
    }, [deiceWeatherData]);
    useEffect(() => {
        if (deIceConfiguration) {
            setModifiedPads(deIceConfiguration.pads);
        }
    }, [deIceConfiguration]);
    useEffect(() => {
        if (operationStatus == "success") {
            setTimeout(() => {
                setDeIceHasChanges(false);
                onClose();
            }, Delay.SaveSuccessDelay);
        }
    }, [operationStatus]);
    //Fix for designsystemet, when having multiple modals open at the same time.
    //This will prevent scrolling on the body when the modal is open
    useEffect(() => {
        // Add the class when the component mounts
        document.body.classList.add("deice-modal-open");
        // Clean up: Remove the class when the component unmounts
        return () => {
            document.body.classList.remove("deice-modal-open");
        };
    }, []);
    const closeModal = () => {
        var _a, _b, _c;
        if (deIceHasChanges) {
            (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.close();
            (_b = leaveWithoutSavingModalRef.current) === null || _b === void 0 ? void 0 : _b.showModal();
        }
        else {
            (_c = leaveWithoutSavingModalRef.current) === null || _c === void 0 ? void 0 : _c.close();
            onClose();
        }
    };
    const closeLeaveWithoutSavingModal = () => {
        var _a;
        setDeIceHasChanges(false);
        (_a = leaveWithoutSavingModalRef.current) === null || _a === void 0 ? void 0 : _a.close();
        onClose();
    };
    const saveAllDeIceSettings = () => {
        const baseWeather = (deIceWeather === null || deIceWeather === void 0 ? void 0 : deIceWeather.baseWeather) || "DEFAULT";
        if (deIceWeather) {
            submitDeIceCapacityAndPadConfig(deIceHandlerCapacity, modifiedPads, baseWeather, deIceWeather.updatedAt, deIceWeather.weatherDeviations);
        }
        else {
            console.error("Couldn't find weather data");
        }
    };
    const isDisabled = isBefore(parseISO(deIceDataDate), startOfToday());
    return (_jsxs(_Fragment, { children: [_jsx(Modal.Root, { children: _jsxs(Modal.Dialog, { ref: modalRef, onClose: () => closeModal(), style: {
                        minWidth: "0",
                        width: "95vw",
                        maxWidth: "75rem",
                    }, children: [_jsxs(Modal.Header, { children: [(deiceWeatherData === null || deiceWeatherData === void 0 ? void 0 : deiceWeatherData.updatedAt) && (_jsxs("span", { className: "deice-settings-modal-last-updated", children: [_jsx(ClockIcon, { title: "a11y-title", fontSize: "0.625rem" }), t("lastUpdated"), ": ", prettyPrintDate(deiceWeatherData === null || deiceWeatherData === void 0 ? void 0 : deiceWeatherData.updatedAt, i18n.language).toLowerCase()] })), t("deice.capacity.controlPanel.settings")] }), _jsx(Modal.Content, { className: "deice-settings-modal-content", children: _jsxs("div", { style: {
                                    minHeight: operationStatus != "success" ? "39.0625rem" : "auto",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }, children: [operationStatus == "error" && (_jsxs(Alert, { severity: "danger", size: "sm", children: [_jsx(Heading, { level: 2, size: "2xs", spacing: true, children: "Det har skjedd en feil" }), _jsx(Paragraph, { size: "sm", children: "Det oppsto en feil under lagringen av konfigurasjonen. Vennligst pr\u00F8v igjen senere." })] })), (operationStatus == "idle" || operationStatus == "error") && (_jsxs("div", { className: "deice-control-panel-content", children: [_jsxs("div", { className: "deice-control-panel-top-row", children: [_jsx("div", { className: "deice-control-panel-top-row-col1", children: _jsx(DeIcePads, { modifiedPads: modifiedPads, setModifiedPads: setModifiedPads, isLoading: isLoading, isDisabled: isDisabled }) }), _jsx("div", { className: "deice-control-panel-top-row-col2", children: _jsx(DeIceBays, { modifiedPads: modifiedPads, setModifiedPads: setModifiedPads, isLoading: isLoading, isDisabled: isDisabled }) })] }), _jsxs("div", { className: "deice-control-panel-bottom-row", children: [_jsx(DeIceWeatherCalendar, { isLoading: isLoadingWeather, disabled: isDisabled }), _jsx(DeIceCapacityScheduler, { deIceHandlerCapacity: deIceHandlerCapacity, setDeIceHandlerCapacity: setDeIceHandlerCapacity, isDisabled: isDisabled })] })] })), operationStatus == "success" && (_jsxs("div", { style: {
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "0.625rem",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }, children: [_jsx("div", { style: { marginTop: "20px" }, children: _jsx(CheckmarkCircleFillIcon, { title: "a11y-title", fontSize: "3.5rem", color: "#2a9d5a" }) }), _jsx("h2", { style: { color: "var(--avinor-green-400)" }, children: t("saved") }), _jsx("div", { style: { marginBottom: "30px" }, children: t("deice.capacity.controlPanel.modal.savedMessage") })] })), (isLoading || operationStatus === "pending") && _jsx(Spinner, {})] }) }), _jsx(Modal.Footer, { children: _jsxs("div", { style: { display: "flex", justifyContent: "flex-end", width: "100%", gap: "0.625rem" }, children: [operationStatus != "pending" && (_jsx(Button, { color: "accent", size: "sm", variant: "tertiary", onClick: () => closeModal(), children: t("cancel") })), (operationStatus == "idle" || operationStatus == "error") && (_jsxs(AuthorizedButton, { hasRole: hasAdminRole, variant: "primary", size: "sm", color: "accent", style: { minWidth: "100px" }, disabled: isDisabled, onClick: () => saveAllDeIceSettings(), children: [_jsx(FloppydiskIcon, { "aria-hidden": true, fontSize: "1.5rem" }), t("save")] }))] }) })] }) }), _jsx(LeaveWithoutSavingModal, { onHasChangesModalRef: leaveWithoutSavingModalRef, onConfirm: closeLeaveWithoutSavingModal, onCancel: () => { var _a; return (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.showModal(); } })] }));
};
