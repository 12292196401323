import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { PaxPredictionDirection } from "@components/Filters/PaxPredictionDirectionFilter/PaxPredictionDirectionFilter";
import { getPaxPredictionGraphData } from "@features/Pax/services";
import { usePlanDayPickerContext } from "@features/Plan/contexts/PlanDayPickerContext";
import "./PlanOverviewAirport.scss";
const PlanOverviewAirportPax = ({ iata }) => {
    const { planDateFormatted } = usePlanDayPickerContext();
    const { t } = useTranslation();
    const { data: paxDeparture, isLoading: isLoadingDeparture } = useQuery({
        queryFn: () => getPaxPredictionGraphData({ iata, date: planDateFormatted, direction: PaxPredictionDirection.Departure }),
        queryKey: [
            "getPaxPredictionGraphData",
            { iata, planDateFormatted, direction: PaxPredictionDirection.Departure },
        ],
        refetchInterval: 0,
        staleTime: 0,
        refetchOnWindowFocus: false,
        gcTime: 0,
    });
    const { data: paxArrival, isLoading: isLoadingArrival } = useQuery({
        queryFn: () => getPaxPredictionGraphData({ iata, date: planDateFormatted, direction: PaxPredictionDirection.Arrival }),
        queryKey: ["getPaxPredictionGraphData", { iata, planDateFormatted, direction: PaxPredictionDirection.Arrival }],
        refetchInterval: 0,
        staleTime: 0,
        refetchOnWindowFocus: false,
        gcTime: 0,
    });
    if (isLoadingDeparture || isLoadingArrival || !paxDeparture || !paxArrival) {
        return null;
    }
    const totalDeparture = paxDeparture.intervals.reduce((sum, x) => sum + x.total, 0);
    const totalArrival = paxArrival.intervals.reduce((sum, x) => sum + x.total, 0);
    const total = totalDeparture + totalArrival;
    return (_jsxs("div", { className: "plan-overview-airport", children: [_jsx("p", { className: "plan-overview-airport-label-main", children: "Pax" }), _jsx("p", { className: "plan-overview-airport-label-secondary", children: t("common.arrival") }), _jsx("p", { className: "plan-overview-airport-label-secondary", children: t("common.departure") }), _jsx("p", { className: "plan-overview-airport-value-main", children: total }), _jsx("p", { className: "plan-overview-airport-value-secondary", children: totalArrival }), _jsx("p", { className: "plan-overview-airport-value-secondary", children: totalDeparture })] }));
};
export default PlanOverviewAirportPax;
