import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Accordion, AccordionRoot } from "@digdir/designsystemet-react";
import "./PlanOverviewAccordion.scss";
import { ClockIcon } from "@navikt/aksel-icons";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { prettyPrintDate } from "@utils/DateUtils";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Spinner from "@components/Spinner/Spinner";
import { usePlanDayPickerContext } from "@features/Plan/contexts/PlanDayPickerContext";
import { getPlanOverview } from "@features/Plan/services";
import { useIataContext } from "@contexts/IataContext";
import { PlanOverviewDisruptions } from "./PlanOverviewDisruptions";
import { PlanOverviewEditButton } from "./PlanOverviewEditButton";
import { PlanCreateModal } from "../PlanCreate/PlanCreateModal";
const getAreaProcessDisruptions = (overview) => {
    // Create a new array by mapping the disruptions
    const planAreaDisruptions = overview.areaProcess.disruptions.map((areaDisruption) => {
        const newAreaDisruption = Object.assign({}, areaDisruption); // Shallow clone of the area disruption
        newAreaDisruption.timePeriodTo =
            newAreaDisruption.timePeriodTo == "24:00" ? "23:59" : newAreaDisruption.timePeriodTo;
        overview.processes.forEach((x) => x.disruptions.forEach((d) => {
            if (!d.normalOperation &&
                d.timePeriodFrom === newAreaDisruption.timePeriodFrom &&
                d.timePeriodTo === newAreaDisruption.timePeriodTo) {
                // Ensure subDisruptions is not modified directly
                if (!newAreaDisruption.subDisruptions) {
                    newAreaDisruption.subDisruptions = [];
                }
                else {
                    // Clone subDisruptions to avoid mutations
                    newAreaDisruption.subDisruptions = [...newAreaDisruption.subDisruptions];
                }
                const subDisruption = Object.assign({}, d);
                subDisruption.processTypeName = x.processType.name;
                newAreaDisruption.subDisruptions.push(subDisruption);
            }
        }));
        return newAreaDisruption;
    });
    return planAreaDisruptions;
};
export const PlanOverviewAccordion = ({ bigScreen = false }) => {
    const { iata } = useIataContext();
    const { i18n } = useTranslation();
    const { planDateFormatted, planDate, dateIsPast } = usePlanDayPickerContext();
    const [openItems, setOpenItems] = useState(bigScreen ? [0, 1] : []);
    const modalRef = useRef(null);
    const [selectedProcessTypeId, setSelectedProcessTypeId] = useState(null);
    const { data: planOverview, isLoading } = useQuery({
        queryFn: () => getPlanOverview({
            iata,
            date: planDateFormatted,
        }),
        queryKey: ["getPlanOverview", { iata, planDateFormatted }],
    });
    useEffect(() => {
        if (selectedProcessTypeId !== null && (modalRef === null || modalRef === void 0 ? void 0 : modalRef.current)) {
            modalRef.current.showModal();
        }
    }, [selectedProcessTypeId, modalRef]);
    const handleAccordionToggle = (e, index) => {
        if (e.target.classList.contains("plan-overview-timeline-segment")) {
            return; // Do nothing if the target is the excluded element
        }
        setOpenItems((prevOpenItems) => {
            if (prevOpenItems.includes(index)) {
                return prevOpenItems.filter((item) => item !== index);
            }
            else {
                return [...prevOpenItems, index];
            }
        });
    };
    const getProcessesSorted = (planPlans) => {
        const processesSorted = planPlans.map((plan) => {
            return {
                id: plan.processType.id,
                actor: plan.processType.name,
                updatedAt: plan.updatedAt,
                disruptions: plan.disruptions,
                updatedBy: plan.updatedBy,
            };
        });
        if (bigScreen) {
            return processesSorted.filter((x) => x.disruptions.some((x) => !x.normalOperation));
        }
        else {
            return processesSorted;
        }
    };
    if (isLoading)
        return (_jsx("div", { className: "plan-overview-time-table", children: _jsx(Spinner, {}) }));
    if (!planOverview || !(planOverview === null || planOverview === void 0 ? void 0 : planOverview.processTables) || (planOverview === null || planOverview === void 0 ? void 0 : planOverview.processTables.length) == 0)
        return (_jsx("div", { className: "plan-overview-time-table", children: _jsx(NoDataAvailable, {}) }));
    return (_jsx(_Fragment, { children: _jsxs("div", { className: `plan-overview-time-table ${dateIsPast ? "plan-overview-time-table-yesterday" : ""}`, style: bigScreen ? { overflowX: "hidden" } : {}, children: [_jsx(AccordionRoot, { color: "subtle", children: planOverview === null || planOverview === void 0 ? void 0 : planOverview.processTables.map((overview, index) => (_jsxs(Accordion.Item, { open: openItems.includes(index), children: [_jsx(Accordion.Heading, { level: 3, className: "plan-overview-accordion-heading", onClick: (e) => handleAccordionToggle(e, index), children: _jsxs("div", { className: "plan-overview-accordion-heading-content", children: [_jsx("p", { className: "plan-overview-accordion-heading-content-area", children: overview.areaProcess.areaName }), _jsx(PlanOverviewDisruptions, { id: "areagroup-" + index, actor: "areagroup-" + index, disruptions: getAreaProcessDisruptions(overview), small: false, hasBeenSaved: false, isAreaDisruptions: true })] }) }), _jsx(Accordion.Content, { children: getProcessesSorted(overview.processes).map((process) => {
                                    return (_jsxs("div", { className: "plan-overview-accordion-process-status-wrapper", children: [_jsx(PlanOverviewEditButton, { processId: process.id, processActor: process.actor, setSelectedProcessTypeId: setSelectedProcessTypeId }), _jsxs("div", { className: "plan-overview-accordion-process-status", children: [_jsxs("div", { className: "plan-overview-accordion-process-status-actor", children: [_jsxs("div", { className: "plan-overview-accordion-process-status-actor-name", children: [process.actor, !process.disruptions.some((x) => !x.normalOperation) &&
                                                                        process.updatedAt && (_jsx("div", { className: "plan-overview-accordion-process-status-actor-dot" }))] }), process.updatedAt && (_jsx(_Fragment, { children: _jsxs("span", { className: "plan-overview-accordion-process-status-updatedAt", children: [_jsx(ClockIcon, { title: "a11y-title", fontSize: "0.625rem" }), prettyPrintDate(process.updatedAt, i18n.language)] }) }))] }), _jsx(PlanOverviewDisruptions, { id: `${process.id}`, actor: process.actor, disruptions: process.disruptions, small: true, hasBeenSaved: !!process.updatedAt, isAreaDisruptions: false })] })] }, process.actor));
                                }) })] }, index))) }), selectedProcessTypeId !== null && (_jsx(PlanCreateModal, { modalRef: modalRef, processTypeId: selectedProcessTypeId, setProcessTypeId: setSelectedProcessTypeId, planDate: planDate }))] }) }));
};
