import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { formatDate } from "@utils/DateUtils";
import { PaxPredictionDirection } from "@components/Filters/PaxPredictionDirectionFilter/PaxPredictionDirectionFilter";
import { Grid } from "@components/Layout";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import Spinner from "@components/Spinner/Spinner";
import { FormatPattern } from "@enums";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
import { getPaxPredictionGraphData } from "../services";
// Utility function to filter intervals for the current day
const getPaxPredictionDataToday = (data, utc, date) => {
    const planDateNumber = utc ? date.getUTCDate() : date.getDate();
    return data.intervals.filter((interval) => {
        const intervalDate = utc ? new Date(interval.from).getUTCDate() : new Date(interval.from).getDate();
        return intervalDate === planDateNumber;
    });
};
// Custom hook to fetch and process passenger prediction data
const usePaxPredictionData = (iata, formattedDate, direction) => {
    const { utc } = useUtcContext();
    const { picker } = useDatePickerContext();
    const { data, isLoading } = useQuery({
        queryFn: () => getPaxPredictionGraphData({
            iata,
            date: formattedDate,
            direction,
        }),
        queryKey: ["getPaxPredictionGraphData", iata, formattedDate, direction],
    });
    const totalPax = data
        ? getPaxPredictionDataToday(data, utc, picker.from).reduce((acc, curr) => acc + curr.total, 0)
        : 0;
    return { totalPax, isLoading };
};
// Component to calculate and display total passengers
export const PaxTotal = () => {
    const { iata } = useIataContext();
    const { picker: { from }, } = useDatePickerContext();
    const { utc } = useUtcContext();
    const formattedFrom = formatDate(utc, from, FormatPattern.yyyy_MM_dd);
    const { totalPax: arrivalPax, isLoading: isLoadingArrival } = usePaxPredictionData(iata, formattedFrom, PaxPredictionDirection.Arrival);
    const { totalPax: departurePax, isLoading: isLoadingDeparture } = usePaxPredictionData(iata, formattedFrom, PaxPredictionDirection.Departure);
    if (isLoadingArrival || isLoadingDeparture)
        return _jsx(_Fragment, {});
    const totalPax = arrivalPax + departurePax;
    if (totalPax === 0) {
        return _jsx(_Fragment, { children: "-" });
    }
    return _jsx(_Fragment, { children: totalPax === null || totalPax === void 0 ? void 0 : totalPax.toLocaleString("nb-NO") });
};
export const PaxCards = ({ format = "small" }) => {
    const { iata } = useIataContext();
    const { picker: { from }, } = useDatePickerContext();
    const { utc } = useUtcContext();
    const formattedFrom = formatDate(utc, from, FormatPattern.yyyy_MM_dd);
    const { t } = useTranslation();
    const { totalPax: arrivalPax, isLoading: isLoadingArrival } = usePaxPredictionData(iata, formattedFrom, PaxPredictionDirection.Arrival);
    const { totalPax: departurePax, isLoading: isLoadingDeparture } = usePaxPredictionData(iata, formattedFrom, PaxPredictionDirection.Departure);
    return (_jsxs(Grid, { columns: 2, children: [isLoadingDeparture ? (_jsx(Spinner, {})) : (_jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", title: t("common.departure"), value: departurePax > 0 ? departurePax === null || departurePax === void 0 ? void 0 : departurePax.toLocaleString("nb-NO") : "-", valueSize: format })), isLoadingArrival ? (_jsx(Spinner, {})) : (_jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", title: t("common.arrival"), value: arrivalPax > 0 ? arrivalPax === null || arrivalPax === void 0 ? void 0 : arrivalPax.toLocaleString("nb-NO") : "-", valueSize: format }))] }));
};
