import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowDownOutlined, ArrowUpOutlined } from "@common-packages/react-icons";
import { DropdownMenu, DropdownMenuRoot } from "@digdir/designsystemet-react";
import { PadlockLockedIcon } from "@navikt/aksel-icons";
import { useState } from "react";
import useRoles from "@auth/hooks/useRoles";
import { useMenuContext } from "@contexts/MenuContext";
import { Roles } from "@contexts/PermissionsContext";
import "./ImpersonateUserRole.scss";
export const ImpersonateUserRole = () => {
    var _a;
    const { roles, setRoles } = useRoles();
    const [open, setOpen] = useState(false);
    const { setMenuOpen } = useMenuContext();
    const options = [
        { value: Roles.Admin, label: "Admin" },
        { value: Roles.PlanWriter, label: "Plan Writer" },
        { value: Roles.Read, label: "Read" },
    ];
    const selectedLabel = roles.length > 0 ? (_a = options.find((x) => x.value == roles[0])) === null || _a === void 0 ? void 0 : _a.label : "None";
    const handleChange = (selectedValue) => {
        // Update roles while keeping the ability to switch back
        setRoles([selectedValue]);
        setOpen(false);
    };
    const toggleSwitcher = (value) => {
        if (value) {
            setMenuOpen(false);
        }
        setOpen(value);
    };
    return (_jsx("div", { className: "impersonate-role", children: _jsxs(DropdownMenuRoot, { open: open, size: "sm", onClose: () => toggleSwitcher(false), children: [_jsx(DropdownMenu.Trigger, { asChild: true, onClick: () => toggleSwitcher(!open), children: _jsxs("button", { className: "impersonate-role__button", children: [_jsx(PadlockLockedIcon, { color: "white", width: "1.5rem", height: "1.5rem" }), _jsx("span", { children: selectedLabel }), open ? (_jsx(ArrowUpOutlined, { color: "white", width: "0.625rem" })) : (_jsx(ArrowDownOutlined, { color: "white", width: "0.625rem" }))] }) }), _jsx(DropdownMenu.Content, { children: options.map((option) => (_jsx(DropdownMenu.Item, { value: option.value, onClick: () => handleChange(option.value), children: option.label }, option.value))) })] }) }));
};
