import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SignInPage } from "@pages";
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "@components/Header/Header";
import Spinner from "@components/Spinner/Spinner";
import { MenuProvider } from "@contexts/MenuContext";
const UnauthenticatedApp = () => {
    return (_jsxs("div", { className: "app unauthorized-app", children: [_jsx(MenuProvider, { children: _jsx(Header, {}) }), _jsx("div", { className: "app__body", children: _jsx("main", { className: "app__body__main", children: _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Routes, { children: _jsx(Route, { path: "*", element: _jsx(SignInPage, {}) }) }) }) }) })] }));
};
export default UnauthenticatedApp;
