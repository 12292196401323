import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import Widget from "@components/Widget/Widget";
import PlanOverviewWeatherStatus from "@features/Plan/components/PlanOverview/PlanOverviewWeatherStatus";
import PlanOverviewWeatherTable from "@features/Plan/components/PlanOverview/PlanOverviewWeatherTable";
import { getTextForecast } from "@features/Plan/services";
import "./PlanOverviewWeatherWidget.scss";
import { useIataContext } from "@contexts/IataContext";
export const PlanOverviewWeatherWidget = () => {
    const { iata } = useIataContext();
    const { data: textForecast, isLoading: textLoading, error: textError, } = useQuery({
        queryFn: () => getTextForecast(),
        queryKey: ["getTextForecast"],
    });
    return (_jsx(Widget.Container, { children: _jsx(Widget.Body, { children: _jsxs("div", { className: "WeatherWidget-content", children: [_jsx("h2", { children: "V\u00E6rvarsel: Oslo Lufthavn" }), _jsxs("div", { className: "WeatherWidget-wrapper", children: [_jsx("div", { className: "WeatherWidget-weather-status", children: _jsx(PlanOverviewWeatherStatus, { iata: iata }) }), _jsx(PlanOverviewWeatherTable, { iata: iata }), _jsx("div", { style: { marginTop: "1.25rem" }, children: !textLoading && textForecast && !textError && (_jsxs(_Fragment, { children: [_jsx("h3", { children: "V\u00E6rforhold \u00D8stlandet" }), _jsx("p", { children: textForecast.text })] })) })] })] }) }) }));
};
PlanOverviewWeatherWidget.displayName = "PlanOverviewWeatherWidget";
