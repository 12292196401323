import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense, useState } from "react";
import HoursToFilterButtons from "@components/Buttons/HoursToFilterButtons/HoursToFilterButtons";
import FlightStageFilter from "@components/Filters/FlightStageFilter/FlightStageFilter";
import { HStack, VStack } from "@components/Layout";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { useIataContext } from "@contexts/IataContext";
import { BaggageDepartedTable } from "../components/BaggageDepartedTable";
export const BaggageDepartedTableWidget = () => {
    /**TODO: Change this to use the correct data. Uses dummy data from another endpoint for now! */
    const [displayHoursLimit, setDisplayHoursLimit] = useState(4);
    const { iata } = useIataContext();
    const title = `Bagasje sendt fra ${iata}`;
    const subTitle = `Antall bagasje ${iata} har sendt fra seg de siste timene. OBS vi har ikke tall fra SAS.`;
    return (_jsxs(Widget.Container, { children: [_jsxs(VStack, { gap: 0, children: [_jsx(Widget.Header, { title: title, subtitle: subTitle }), _jsxs(HStack, { gap: 1, justify: "space-between", style: { padding: "0.5rem" }, children: [_jsx(HoursToFilterButtons, { setHoursTo: setDisplayHoursLimit }), _jsx(FlightStageFilter, { flightStageKey: "baggageTable" })] })] }), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Widget.Body, { children: _jsx(BaggageDepartedTable, { displayHoursLimit: displayHoursLimit }) }) })] }));
};
BaggageDepartedTableWidget.displayName = "BaggageDepartedTableWidget";
