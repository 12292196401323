import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Modal } from "@digdir/designsystemet-react";
import { useTranslation } from "react-i18next";
import "./SignInHelpModal.scss";
export const SignInHelpModal = ({ signInHelpModalRef }) => {
    const { t } = useTranslation();
    const backButtonClicked = () => {
        var _a;
        (_a = signInHelpModalRef.current) === null || _a === void 0 ? void 0 : _a.close();
    };
    return (_jsx(Modal.Root, { children: _jsxs(Modal.Dialog, { ref: signInHelpModalRef, children: [_jsx(Modal.Header, { children: t("signin.helpModal.title") }), _jsx(Modal.Content, { children: _jsxs("div", { className: "signin-modal-content", children: [_jsxs("div", { children: [_jsx("h3", { children: t("signin.helpModal.avinorAccessTitle") }), _jsx("p", { children: t("signin.helpModal.avinorAccessDescription") })] }), _jsxs("div", { children: [_jsx("h3", { children: t("signin.helpModal.externalAccessTitle") }), _jsx("p", { children: t("signin.helpModal.externalAccessDescription") })] }), _jsxs("div", { children: [_jsx("span", { className: "signin-modal-subtitle", children: t("signin.helpModal.externalAcessRoleTitle") }), _jsx("p", { children: t("signin.helpModal.externalAccessRoleParagrah1") }), _jsx("p", { children: t("signin.helpModal.externalAccessRoleParagrah2") })] })] }) }), _jsx(Modal.Footer, { children: _jsx("div", { style: { display: "flex", justifyContent: "flex-end", width: "100%", gap: "0.625rem" }, children: _jsx(Button, { color: "accent", size: "sm", variant: "tertiary", onClick: () => backButtonClicked(), children: t("signin.helpModal.backButton") }) }) })] }) }));
};
