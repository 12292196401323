import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import Widget from "@components/Widget/Widget";
import { DelaySummary } from "../components";
import { DelayCausesProgressBars } from "../components/DelayCausesProgressBars";
import { DelayTotalFlightDelays } from "../components/DelaySummary";
export const DelaySummaryWithCodesWidget = () => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t("onTimePerformance.delay.widgets.summary.title"), subtitleVisibility: "none" }), _jsxs("div", { style: { display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }, children: [_jsxs("div", { style: { display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1rem" }, children: [_jsx(Widget.NumericIndicator, { children: _jsx(DelayTotalFlightDelays, {}) }), _jsx(DelaySummary, { format: "xsmall" })] }), _jsxs("div", { children: [_jsxs("div", { style: { display: "flex", alignItems: "center", gap: "0.5rem", marginBottom: "0.5rem" }, children: [_jsx("h4", { children: t("onTimePerformance.delay.widgets.top3delayCodes") }), _jsx("h5", { style: { color: "var(--ds-color-neutral-12)" }, children: "> 15 min" })] }), _jsx(DelayCausesProgressBars, {})] })] })] }));
};
DelaySummaryWithCodesWidget.displayName = "DelaySummaryWithCodesWidget";
