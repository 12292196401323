import { jsx as _jsx } from "react/jsx-runtime";
import { Alert } from "@digdir/designsystemet-react";
import { useTranslation } from "react-i18next";
import { prettyPrintDate } from "@utils/DateUtils";
import { getDomainName } from "@utils/Utils";
import useProcessStatus from "@features/Plan/hooks/useProcessStatus";
import "./PlanCreateUpdatedAlert.scss";
import useRoles from "@auth/hooks/useRoles";
export const PlanCreateUpdatedAlert = ({ selectedDayFormatted, processTypeId, }) => {
    const { processStatus } = useProcessStatus({
        date: selectedDayFormatted,
        processTypeId: processTypeId,
    });
    const { t, i18n } = useTranslation();
    const planExists = (processStatus === null || processStatus === void 0 ? void 0 : processStatus.updatedBy) !== "";
    const { hasAdminRole } = useRoles();
    if (processStatus && planExists) {
        const message = hasAdminRole
            ? t("plan.create.alertInfo.updatedInfoAdmin", {
                updatedBy: processStatus.updatedBy.toLowerCase(),
                updatedAt: prettyPrintDate(processStatus.updatedAt, i18n.language),
            })
            : t("plan.create.alertInfo.updatedInfo", {
                updatedBy: getDomainName(processStatus.updatedBy.toLowerCase()),
                updatedAt: prettyPrintDate(processStatus.updatedAt, i18n.language),
            });
        return (_jsx(Alert, { className: "plan-alert-custom", children: _jsx("p", { className: "plan-alert-paragraph", children: message }) }));
    }
    else {
        return null;
    }
};
