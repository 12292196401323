import { startOfDay } from "date-fns";
import { t } from "i18next";
import { AvinorColor } from "@constants";
export const defaultApexChartOptions = {
    chart: {},
    colors: [],
    dataLabels: {},
    fill: {},
    forecastDataPoints: {},
    grid: {
        show: true,
        strokeDashArray: 4,
        borderColor: AvinorColor.Grey200,
        xaxis: {
            lines: {
                show: true,
            },
        },
    },
    labels: [],
    legend: {
        markers: {
            size: 6,
            shape: "circle",
        },
        horizontalAlign: "left",
        position: "bottom",
    },
    markers: {},
    noData: {},
    plotOptions: {},
    responsive: [],
    series: [],
    states: {},
    stroke: {},
    subtitle: {},
    annotations: {
        xaxis: [
            {
                x: startOfDay(new Date()).getTime(),
                x2: new Date().getTime(),
                borderColor: "#fff",
                fillColor: "#fff",
                opacity: 0.5,
            },
            {
                x: new Date().getTime(),
                borderColor: "#000",
                borderWidth: 2,
                strokeDashArray: 10,
                label: {
                    text: t("common.now"),
                    position: "top",
                    offsetY: -10,
                    orientation: "horizontal",
                    style: {
                        color: "#000",
                    },
                },
            },
        ],
    },
    theme: {},
    title: {},
    tooltip: {},
    xaxis: {},
    yaxis: {},
};
