import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoICircleOutlined } from "@common-packages/react-icons";
import HoursToFilterButtons from "@components/Buttons/HoursToFilterButtons/HoursToFilterButtons";
import FlightStageFilter from "@components/Filters/FlightStageFilter/FlightStageFilter";
import { HStack, VStack } from "@components/Layout";
import Widget from "@components/Widget/Widget";
import { BaggageEnroutePerFlightEstimateTable } from "@features/Baggage/components";
import { UserDomains } from "@contexts/PermissionsContext";
import { useIataContext } from "@contexts/IataContext";
export const BaggageEnroutePerFlightEstimateTableWidget = () => {
    const [displayHoursLimit, setDisplayHoursLimit] = useState(4);
    const { iata } = useIataContext();
    const { t } = useTranslation();
    const title = t("baggage.widgets.enroutePerFlightEstimateTable.title", { iata: iata });
    const subTitle = t("baggage.widgets.enroutePerFlightEstimateTable.description", { iata: iata });
    const explanation = t("baggage.widgets.enroutePerFlightEstimateTable.explanation");
    return (_jsxs(Widget.Container, { children: [_jsxs(VStack, { gap: 0, children: [_jsx(Widget.Header, { title: title, subtitle: subTitle, icon: {
                            component: (_jsx(InfoICircleOutlined, { width: "1rem", height: "1rem" })),
                            tooltip: {
                                id: "baggageEnroutePerFlightEstimateTableWidget",
                                html: `<h3>${title}</h3><p>${explanation}</p>`,
                            },
                        } }), _jsxs(HStack, { gap: 1, justify: "space-between", style: { padding: "0.5rem" }, children: [_jsx(HoursToFilterButtons, { setHoursTo: setDisplayHoursLimit }), _jsx(FlightStageFilter, { flightStageKey: "baggageTable" })] })] }), _jsx(Widget.Body, { children: _jsx(BaggageEnroutePerFlightEstimateTable, { displayHoursLimit: displayHoursLimit }) })] }));
};
BaggageEnroutePerFlightEstimateTableWidget.displayName = "BaggageEnroutePerFlightEstimateTableWidget";
BaggageEnroutePerFlightEstimateTableWidget.permissions = { requiredDomain: UserDomains.Avinor };
