import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Cell } from "@table-library/react-table-library";
import { useQuery } from "@tanstack/react-query";
import { secondsToMinutes } from "date-fns/esm";
import { useTranslation } from "react-i18next";
import { formatDate } from "@utils/DateUtils";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Table from "@components/Table/Table";
import { addIdToObj } from "@components/Table/utils";
import { FormatPattern, Granularity } from "@enums";
import { mapAirlineIataToIcon, mapAirlineIataToName } from "@mappings";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
import { getBaggageOnBeltPerformanceAverage } from "../services";
const tableColumnHeaders = {
    airlineIata: "operator",
    numberOfFlights: "table.baggage.onBeltPerformance.numberOfFlights",
    averageTime: "table.baggage.onBeltPerformance.averageTime",
    airlineMedian: "Median tid",
    airlineMedianNoCutoff: "Median tid (no cutoff)",
};
const headers = Object.keys(tableColumnHeaders);
const getTableColumnHeaders = (t) => {
    return [
        {
            headers: [
                {
                    content: t(tableColumnHeaders.airlineIata),
                    key: headers[0],
                    cellProps: {
                        weight: "1.2",
                    },
                },
                {
                    content: t(tableColumnHeaders.numberOfFlights),
                    key: headers[1],
                    cellProps: {},
                },
                {
                    content: t(tableColumnHeaders.averageTime),
                    key: headers[2],
                    cellProps: {},
                },
                {
                    content: t(tableColumnHeaders.airlineMedian),
                    key: headers[3],
                    cellProps: {},
                },
                {
                    content: t(tableColumnHeaders.airlineMedianNoCutoff),
                    key: headers[4],
                    cellProps: {},
                },
            ],
        },
    ];
};
const formatBaggageOnBeltPerformanceAverageAirline = (airlineAverages) => {
    return airlineAverages.map(({ airlineIata, totalFlights, airlineAverage, airlineMedian, airlineMedianNoCutoff }) => {
        return {
            airlineIata,
            totalFlights,
            airlineAverage,
            airlineMedian,
            airlineMedianNoCutoff,
        };
    });
};
export const BaggageOnBeltPerformanceAverageTable = () => {
    var _a;
    const { picker: { from, to }, } = useDatePickerContext();
    const { iata } = useIataContext();
    const { utc } = useUtcContext();
    const formattedFrom = formatDate(utc, from, FormatPattern.yyyy_MM_dd_HH_mm);
    const formattedTo = formatDate(utc, to, FormatPattern.yyyy_MM_dd_HH_mm);
    const { data: baggageOnBeltPerformanceAverageData } = useQuery({
        queryFn: () => getBaggageOnBeltPerformanceAverage({
            params: {
                iata,
                from: formattedFrom,
                to: formattedTo,
                interval: Granularity.Day,
            },
        }),
        queryKey: ["getBaggageOnBeltPerformanceAverage", { from, to, iata }],
    });
    const { t } = useTranslation();
    if (!baggageOnBeltPerformanceAverageData || !baggageOnBeltPerformanceAverageData.timeInterval)
        return _jsx(NoDataAvailable, {});
    const baggageOnBeltPerformanceAverage = baggageOnBeltPerformanceAverageData.timeInterval;
    const formattedBaggageOnBeltPerformanceAverageAirline = formatBaggageOnBeltPerformanceAverageAirline(((_a = baggageOnBeltPerformanceAverage[0]) === null || _a === void 0 ? void 0 : _a.airlineAverages) || []);
    const { nodes } = addIdToObj(formattedBaggageOnBeltPerformanceAverageAirline);
    const customCellRenderers = {
        airlineIata: ({ cellIndex, row, gridColumnStart, gridColumnEnd }) => {
            const AirlineIataIcon = mapAirlineIataToIcon(row.airlineIata);
            return (_jsxs(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: [_jsx(AirlineIataIcon, {}), _jsx("p", { children: mapAirlineIataToName(row.airlineIata, () => {
                            return t(row.airlineIata);
                        }) })] }, cellIndex));
        },
        totalFlights: ({ cellIndex, row, gridColumnStart, gridColumnEnd }) => {
            return (_jsx(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: row.totalFlights }, cellIndex));
        },
        airlineAverage: ({ cellIndex, row, gridColumnStart, gridColumnEnd }) => {
            return (_jsxs(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: [secondsToMinutes(row.airlineAverage), " min"] }, cellIndex));
        },
        airlineMedian: ({ cellIndex, row, gridColumnStart, gridColumnEnd }) => {
            return (_jsxs(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: [secondsToMinutes(row.airlineMedian), " min"] }, cellIndex));
        },
        airlineMedianNoCutoff: ({ cellIndex, row, gridColumnStart, gridColumnEnd }) => {
            return (_jsxs(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: [secondsToMinutes(row.airlineMedianNoCutoff), " min"] }, cellIndex));
        },
    };
    return (_jsx(Table, { data: { nodes }, headerGroups: getTableColumnHeaders(t), cellRenderers: customCellRenderers, useDesignsystemetTheme: true, style: { minWidth: "37.5rem" }, customTheme: {
            Cell: `

                    &:first-of-type {
                        & > div {
                            display: flex;
                            align-items: center;
                            gap: 0.75rem;

                            & p {
                                margin: 0;
                            }
                        }
                    }
                `,
        } }));
};
