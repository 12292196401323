import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Modal } from "@digdir/designsystemet-react";
import { ChatExclamationmarkIcon } from "@navikt/aksel-icons";
import { useTranslation } from "react-i18next";
export const PlanCreateErrorModal = ({ errorModalRef, modalState }) => {
    const { t } = useTranslation();
    return (_jsx(Modal.Root, { children: _jsx(Modal.Dialog, { ref: errorModalRef, children: _jsxs(_Fragment, { children: [_jsx(Modal.Header, { children: _jsxs("div", { style: { display: "flex", alignItems: "center", gap: "0.625rem" }, children: [_jsx(ChatExclamationmarkIcon, { title: "a11y-title", fontSize: "1.5rem", color: "#243142" }), _jsx("span", { children: t("plan.create.planCreateErrorModal.headerError") })] }) }), _jsx(Modal.Content, { children: modalState.message
                            ? modalState.message
                            : t("plan.create.planCreateErrorModal.defaultErrorMessage") }), _jsx(Modal.Footer, { children: _jsx("div", { style: { display: "flex", justifyContent: "flex-end", width: "100%", gap: "0.625rem" }, children: _jsx(Button, { color: "accent", size: "sm", variant: "tertiary", onClick: () => { var _a; return (_a = errorModalRef.current) === null || _a === void 0 ? void 0 : _a.close(); }, children: t("plan.create.planCreateErrorModal.buttonOk") }) }) })] }) }) }));
};
