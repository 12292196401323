import { jsx as _jsx } from "react/jsx-runtime";
import { addDays, subDays } from "date-fns";
import { createContext, useContext, useState } from "react";
import { formatDate } from "@utils/DateUtils";
import { FormatPattern } from "@enums";
import { useDatePickerContext } from "@contexts/DatePickerContext";
export var PlanOverviewDay;
(function (PlanOverviewDay) {
    PlanOverviewDay["YESTERDAY"] = "yesterday";
    PlanOverviewDay["TODAY"] = "today";
    PlanOverviewDay["TOMORROW"] = "tomorrow";
})(PlanOverviewDay || (PlanOverviewDay = {}));
const PlanDayPickerContext = createContext(undefined);
export const PlanDayPickerProvider = ({ children }) => {
    const [selectedDayState, setSelectedDayState] = useState(PlanOverviewDay.TODAY);
    const { setPicker: setSelectedOption } = useDatePickerContext();
    const setSelectedDay = (selectedDay) => {
        setSelectedDayState(selectedDay);
        if (selectedDay === PlanOverviewDay.TODAY) {
            setSelectedOption({ from: new Date(), to: new Date() });
        }
        else if (selectedDay === PlanOverviewDay.YESTERDAY) {
            setSelectedOption({ from: subDays(new Date(), 1), to: subDays(new Date(), 1) });
        }
        else if (selectedDay === PlanOverviewDay.TOMORROW) {
            setSelectedOption({ from: addDays(new Date(), 1), to: addDays(new Date(), 1) });
        }
    };
    const planDate = new Date();
    if (selectedDayState === PlanOverviewDay.TOMORROW) {
        planDate.setDate(planDate.getDate() + 1);
    }
    else if (selectedDayState === PlanOverviewDay.YESTERDAY) {
        planDate.setDate(planDate.getDate() - 1);
    }
    const planDateFormatted = formatDate(false, planDate, FormatPattern.yyyy_MM_dd);
    return (_jsx(PlanDayPickerContext.Provider, { value: { selectedDay: selectedDayState, setSelectedDay, planDate, planDateFormatted }, children: children }));
};
export const usePlanDayPickerContext = () => {
    const context = useContext(PlanDayPickerContext);
    if (!context) {
        throw new Error("usePlanDayPickerContext must be used within a PlanDayPickerProvider");
    }
    return context;
};
