import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Home, ZoomArea, Move, CirclePlus, CircleMinus } from "@common-packages/react-icons";
import { FormatPattern } from "@enums/format";
import { useQuery } from "@tanstack/react-query";
import ReactApexChart from "react-apexcharts";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { formatDate, secondsToMinutesRounded } from "@utils/DateUtils";
import { extractUniqueKeys } from "@utils/Utils";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import { AvinorColor, breakpoints } from "@constants";
import { mapAirlineIataToColor, mapAirlineIataToName } from "@mappings";
import { getBaggageOnBeltPerformanceAverage } from "@features/Baggage/services";
import { defaultApexChartOptions } from "@charts/apexcharts/defaultApexChartOptions";
import Tooltip from "@charts/shared/components/Tooltip/Tooltip";
import { formatXAxisTick } from "@charts/utils";
import { useMediaQuery } from "@hooks";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useGranularityContext } from "@contexts/GranularityContext";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
import { formatBaggageOnBeltAverageData } from "../AveragePerFlightCombined/LineAndScatterChartDataUtils";
const createChartLines = (baggageOnBeltPerformanceAverageData) => {
    const excludeKeys = new Set([
        "date",
        "totalFlights",
        "airlines",
    ]);
    const uniqueAirlineCodes = extractUniqueKeys(baggageOnBeltPerformanceAverageData, excludeKeys);
    return uniqueAirlineCodes.map((iata) => ({
        dataKey: iata,
        stroke: mapAirlineIataToColor(iata),
    }));
};
const BaggageOnBeltPerformanceAverageLineChart = () => {
    const { utc } = useUtcContext();
    const { picker: { id, from, to }, } = useDatePickerContext();
    const { granularity } = useGranularityContext({ from, currentPage: id });
    const { iata } = useIataContext();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    const is4kScreen = useMediaQuery(`(min-width: ${breakpoints.width4k})`);
    const formattedFrom = formatDate(utc, from, FormatPattern.yyyy_MM_dd_HH_mm);
    const formattedTo = formatDate(utc, to, FormatPattern.yyyy_MM_dd_HH_mm);
    const { data: baggageOnBeltPerformanceAverageData } = useQuery({
        queryFn: () => getBaggageOnBeltPerformanceAverage({
            params: {
                iata,
                from: formattedFrom,
                to: formattedTo,
                interval: granularity,
            },
        }),
        queryKey: ["getBaggageOnBeltPerformanceAverage", { from, to, iata, granularity }],
    });
    if (!baggageOnBeltPerformanceAverageData ||
        !baggageOnBeltPerformanceAverageData.timeInterval ||
        baggageOnBeltPerformanceAverageData.timeInterval.length == 0)
        return _jsx(NoDataAvailable, {});
    const baggageOnBeltPerformanceAverageDataFormatted = formatBaggageOnBeltAverageData(baggageOnBeltPerformanceAverageData);
    const series = createChartLines(baggageOnBeltPerformanceAverageDataFormatted).map(({ dataKey, stroke }) => ({
        name: mapAirlineIataToName(dataKey, () => {
            return t(dataKey);
        }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: baggageOnBeltPerformanceAverageDataFormatted.map((baggage) => { var _a; return (_a = baggage[dataKey]) !== null && _a !== void 0 ? _a : 0; }),
        stroke,
    }));
    const xaxisCategories = baggageOnBeltPerformanceAverageDataFormatted.map((baggage) => baggage.date);
    const sharedStyles = {
        colors: AvinorColor.Grey900,
        fontSize: isMobile ? "0.75rem" : "0.875rem",
        fontWeight: "normal",
    };
    const options = {
        colors: series.map((s) => s.stroke),
        chart: {
            toolbar: {
                tools: {
                    download: false,
                    zoom: ReactDOMServer.renderToStaticMarkup(_jsx(ZoomArea, {})),
                    zoomin: ReactDOMServer.renderToStaticMarkup(_jsx(CirclePlus, {})),
                    zoomout: ReactDOMServer.renderToStaticMarkup(_jsx(CircleMinus, {})),
                    pan: ReactDOMServer.renderToStaticMarkup(_jsx(Move, {})),
                    reset: ReactDOMServer.renderToStaticMarkup(_jsx(Home, {})),
                },
            },
            fontFamily: "Roboto, sans-serif",
            type: "line",
            zoom: {
                type: "x",
                allowMouseWheelZoom: false,
            },
        },
        grid: {
            show: true,
            strokeDashArray: 4,
            xaxis: {
                lines: {
                    show: true,
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (value) {
                if (!value)
                    return "";
                return secondsToMinutesRounded(value);
            },
        },
        stroke: {
            width: 2,
            curve: "smooth",
        },
        xaxis: {
            categories: xaxisCategories,
            type: "datetime",
            title: {
                text: t("time"),
                style: sharedStyles,
                offsetY: 5,
            },
            labels: {
                datetimeUTC: utc,
            },
        },
        yaxis: {
            title: {
                text: t("charts.baggage.line.onBeltPerformance.numberOfMinutes"),
                style: sharedStyles,
            },
            labels: {
                formatter: function (bagPerformance) {
                    return `${secondsToMinutesRounded(bagPerformance)}min`;
                },
            },
        },
        tooltip: {
            fixed: {
                enabled: true,
                position: "bottomRight",
            },
            custom: function ({ dataPointIndex }) {
                var _a;
                const { date, airlines } = baggageOnBeltPerformanceAverageDataFormatted[dataPointIndex];
                if (Object.keys(airlines).length === 0)
                    return null;
                const tooltipElement = (_jsxs(Tooltip, { children: [_jsx(Tooltip.Title, { children: formatXAxisTick(date, granularity, t("timePrefix")) }), _jsx(Tooltip.List, { children: (_a = Object.entries(airlines)) === null || _a === void 0 ? void 0 : _a.map(([airlineIata, bagPerformance]) => {
                                return (_jsx(Tooltip.ListItem, { style: {
                                        type: "bullet",
                                        bgColor: mapAirlineIataToColor(airlineIata),
                                    }, content: {
                                        value: mapAirlineIataToName(airlineIata, () => {
                                            return t(airlineIata);
                                        }),
                                    }, status: {
                                        value: `${secondsToMinutesRounded(bagPerformance)} min`,
                                    } }, airlineIata));
                            }) })] }));
                return ReactDOMServer.renderToStaticMarkup(tooltipElement);
            },
        },
        legend: defaultApexChartOptions.legend,
        responsive: [
            {
                breakpoint: 768,
                options: {
                    xaxis: {
                        title: {
                            text: undefined,
                        },
                    },
                    yaxis: {
                        title: {
                            text: undefined,
                        },
                        labels: {
                            formatter: function (bagPerformance) {
                                return `${secondsToMinutesRounded(bagPerformance)}min`;
                            },
                        },
                    },
                },
            },
        ],
    };
    return (_jsx(ReactApexChart, { options: options, series: series, type: "line", height: is4kScreen ? 1200 : 600 }));
};
export default BaggageOnBeltPerformanceAverageLineChart;
