import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SuitcaseOutlined, PlaneDownOutlined, PlaneUpRightOutlined } from "@common-packages/react-icons";
import { useQuery } from "@tanstack/react-query";
import { addHours } from "date-fns";
import { formatDate } from "@utils/DateUtils";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { FormatPattern, Granularity } from "@enums";
import { getBaggageAndPaxEnroute } from "@features/Baggage/services";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
import { useFlightMovementDataSource } from "../contexts/FlightMovementsDataSourceContext";
import { getFlightMovements } from "../services";
import "./FlightMovementsNextHourCard.scss";
export const FlightsNextHourTotal = ({ direction }) => {
    var _a, _b;
    const date = new Date();
    const { utc } = useUtcContext();
    const { dataSource } = useFlightMovementDataSource(date);
    const { iata } = useIataContext();
    const from = formatDate(utc, new Date(), FormatPattern.yyyy_MM_dd_HH_mm);
    const to = formatDate(utc, addHours(new Date(), 1), FormatPattern.yyyy_MM_dd_HH_mm);
    const { data, isLoading } = useQuery({
        queryFn: () => getFlightMovements({
            params: {
                from,
                to,
                international: true,
                domestic: true,
                schengen: true,
                granularity: Granularity.Hour,
                dataSource,
                iata,
            },
        }),
        queryKey: ["getFlightMovements", utc, iata, from, to, dataSource, Granularity.Hour],
    });
    if (isLoading)
        return _jsx(Spinner, {});
    if ((data === null || data === void 0 ? void 0 : data.flightCounts) && (data === null || data === void 0 ? void 0 : data.flightCounts.length) > 0) {
        const totalFlights = direction == "arrival"
            ? (_a = data === null || data === void 0 ? void 0 : data.flightCounts) === null || _a === void 0 ? void 0 : _a.reduce((sum, flight) => sum + (flight.arrival || 0), 0)
            : (_b = data === null || data === void 0 ? void 0 : data.flightCounts) === null || _b === void 0 ? void 0 : _b.reduce((sum, flight) => sum + (flight.departure || 0), 0);
        return _jsx(_Fragment, { children: totalFlights });
    }
    return _jsx(_Fragment, { children: "-" });
};
export const BagsNextHourTotal = () => {
    var _a, _b;
    const { iata } = useIataContext();
    const { data: bagsAndPaxEnroute, isLoading } = useQuery({
        queryFn: () => getBaggageAndPaxEnroute({
            iata,
        }),
        queryKey: ["getBaggageAndPaxEnroute", { iata }],
    });
    if (isLoading)
        return _jsx(Spinner, {});
    if (((_a = bagsAndPaxEnroute === null || bagsAndPaxEnroute === void 0 ? void 0 : bagsAndPaxEnroute.paxPeriods) === null || _a === void 0 ? void 0 : _a.length) && ((_b = bagsAndPaxEnroute === null || bagsAndPaxEnroute === void 0 ? void 0 : bagsAndPaxEnroute.paxPeriods) === null || _b === void 0 ? void 0 : _b.length) > 0) {
        const nextHourData = bagsAndPaxEnroute === null || bagsAndPaxEnroute === void 0 ? void 0 : bagsAndPaxEnroute.bagPeriods.find((x) => { var _a; return ((_a = x.timePeriod) === null || _a === void 0 ? void 0 : _a.toLowerCase()) == "neste time"; });
        if (nextHourData) {
            return nextHourData.total.toLocaleString("nb-NO");
        }
    }
    return _jsx(_Fragment, { children: "-" });
};
export const FlightMovementsNextHourCard = ({ direction }) => {
    return (_jsxs("div", { className: "flight-movements-next-hour-card", children: [_jsxs("div", { className: "flight-movements-next-hour-card-total", children: [direction === "arrival" ? (_jsx(PlaneDownOutlined, { width: "2.5rem", height: "2.5rem", color: "var(--ds-color-neutral-13)" })) : (_jsx(PlaneUpRightOutlined, { width: "2.5rem", height: "2.5rem", color: "var(--ds-color-neutral-13)" })), _jsx(Widget.NumericIndicator, { size: "small", children: _jsx(FlightsNextHourTotal, { direction: direction }) })] }), direction === "arrival" && (_jsxs("div", { className: "flight-movements-next-hour-card-total", children: [_jsx(SuitcaseOutlined, { width: "2.5rem", height: "2.5rem", color: "var(--ds-color-neutral-13)" }), _jsx(Widget.NumericIndicator, { size: "small", children: _jsx(BagsNextHourTotal, {}) })] }))] }));
};
