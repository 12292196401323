import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./DeIceCapacityScheduler.scss";
import { NativeSelect } from "@digdir/designsystemet-react";
import { mapHandlerToName } from "@mappings/mapHandlerToName";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { InfoICircleOutlined } from "@avinor/react-icons";
import { VStack } from "@components/Layout";
import { useDeIceHasChangesContext } from "@features/DeIce/contexts/DeIceHasChangesContext";
const styleSheet = {
    root: "deicing-capacity-scheduler",
    title: "deicing-capacity-scheduler__title",
    subTitle: "deicing-capacity-scheduler__sub-title",
    columnHeader: "deicing-capacity-scheduler__column-header",
    handler: "deicing-capacity-scheduler__handler",
};
const selectOptions = () => {
    return Array.from({ length: 12 }, (_, index) => ({
        value: `${index + 1}`,
        label: `${index + 1}`,
    }));
};
const selectOptionsCarsPerPlane = () => {
    return Array.from({ length: 6 }, (_, index) => ({
        value: `${index + 1}`,
        label: `${index + 1}`,
    }));
};
const calculateTotalCapacity = (capacity, type) => {
    let totalCapacity = 0;
    capacity
        .filter((x) => x.handler != "ALL")
        .forEach((handlerCapacity) => {
        if (type === "morning") {
            totalCapacity += handlerCapacity.morningTotalCars / handlerCapacity.morningCarsPerPlane;
        }
        else {
            totalCapacity += handlerCapacity.afternoonTotalCars / handlerCapacity.afternoonCarsPerPlane;
        }
    });
    const formattedTotalCapacity = parseFloat(totalCapacity.toFixed(3));
    return formattedTotalCapacity;
};
const DeIceCapacityScheduler = ({ deIceHandlerCapacity, setDeIceHandlerCapacity }) => {
    const { t } = useTranslation();
    const { setDeIceHasChanges } = useDeIceHasChangesContext();
    const onDeiceHandlerSelected = (selectedValue, handler, value, key) => {
        setDeIceHasChanges(true);
        setDeIceHandlerCapacity((prevHandlerCapacities) => {
            const updatedCapacities = prevHandlerCapacities.map((prevHandlerCapacity) => {
                if (prevHandlerCapacity.handler === handler) {
                    return Object.assign(Object.assign({}, prevHandlerCapacity), { [key]: +selectedValue });
                }
                return prevHandlerCapacity;
            });
            // Calculate the morning and afternoon sums for the "ALL" handler
            const morningTotal = updatedCapacities
                .filter((capacity) => capacity.handler !== "ALL")
                .reduce((total, capacity) => total + capacity.morningTotalCars, 0);
            const afternoonTotal = updatedCapacities
                .filter((capacity) => capacity.handler !== "ALL")
                .reduce((total, capacity) => total + capacity.afternoonTotalCars, 0);
            // Update the "ALL" handler with the new sums
            return updatedCapacities.map((capacity) => capacity.handler === "ALL"
                ? Object.assign(Object.assign({}, capacity), { morningTotalCars: morningTotal, afternoonTotalCars: afternoonTotal }) : capacity);
        });
    };
    const sortOrder = ["MEN", "SGH", "WGH", "ALL"];
    const deIceHandlerCapacitySorted = deIceHandlerCapacity.sort((a, b) => sortOrder.indexOf(a.handler) - sortOrder.indexOf(b.handler));
    const totalCapacityMorning = calculateTotalCapacity(deIceHandlerCapacity, "morning");
    const totalCapacityAfternoon = calculateTotalCapacity(deIceHandlerCapacity, "afternoon");
    return (_jsxs(VStack, { className: styleSheet.root, gap: 1, children: [_jsxs("div", { className: "deicing-capacity-scheduler__header", children: [_jsxs(VStack, { justify: "space-between", children: [_jsx("h4", { className: styleSheet.title, children: t("deice.capacity.controlPanel.scheduler.title") }), _jsx("h4", { className: styleSheet.subTitle, children: t("deice.capacity.controlPanel.scheduler.subTitle") })] }), _jsx(Tooltip, { id: "deice-capacity-information-tooltip", children: _jsx("p", { children: t("deice.capacity.controlPanel.formula.informationTooltip") }) }), _jsx(InfoICircleOutlined, { "data-tooltip-id": "deice-capacity-information-tooltip", width: "1rem", height: "1rem" })] }), _jsxs("div", { className: "deicing-capacity-scheduler__labels", children: [_jsxs("div", { className: "deicing-capacity-scheduler__hour-labels", children: [_jsx("span", { children: "00:00 - 14:00" }), _jsx("span", { children: "14:00 - 23:59" })] }), _jsxs("div", { className: "deicing-capacity-scheduler__select-labels-wrapper", children: [_jsxs("div", { className: "deicing-capacity-scheduler__select-label", children: [_jsx("span", { children: t("deice.capacity.controlPanel.formula.vehicles.text") }), _jsx("span", { children: t("deice.capacity.controlPanel.formula.vehiclesPerPlane.text") })] }), _jsxs("div", { className: "deicing-capacity-scheduler__select-label", children: [_jsx("span", { children: t("deice.capacity.controlPanel.formula.vehicles.text") }), _jsx("span", { children: t("deice.capacity.controlPanel.formula.vehiclesPerPlane.text") })] })] })] }), deIceHandlerCapacitySorted
                .filter((x) => x.handler != "ALL")
                .map(({ handler, morningTotalCars, morningCarsPerPlane, afternoonTotalCars, afternoonCarsPerPlane }) => (_jsxs("div", { className: "deicing-capacity-scheduler__handler-wrapper", children: [_jsx("div", { className: styleSheet.handler, children: mapHandlerToName(handler) }), _jsx(CarsSelector, { handler: handler, totalCars: morningTotalCars, carsPerPlane: morningCarsPerPlane, type: "morning", onDeiceHandlerSelected: onDeiceHandlerSelected }), _jsx(CarsSelector, { handler: handler, totalCars: afternoonTotalCars, carsPerPlane: afternoonCarsPerPlane, type: "afternoon", onDeiceHandlerSelected: onDeiceHandlerSelected })] }, handler))), _jsxs("div", { className: "deicing-capacity-scheduler__capacity", children: [_jsx("span", { children: t("deice.capacity.controlPanel.formula.capacity").toUpperCase() }), _jsx("span", { children: totalCapacityMorning }), _jsx("span", { children: totalCapacityAfternoon })] })] }));
};
const CarsSelector = ({ handler, totalCars, carsPerPlane, type, onDeiceHandlerSelected, }) => {
    return (_jsx(_Fragment, { children: handler == "ALL" ? (_jsx("span", { children: totalCars })) : (_jsxs("div", { className: "deicing-capacity-scheduler__cars-select-wrapper", children: [_jsx(NativeSelect, { size: "sm", onChange: (event) => onDeiceHandlerSelected(event.target.value, handler, totalCars, type == "morning" ? "morningTotalCars" : "afternoonTotalCars"), value: `${totalCars}`, children: selectOptions().map((option) => (_jsx("option", { value: option.value, children: option.label }, option.value))) }), _jsx(NativeSelect, { size: "sm", onChange: (event) => onDeiceHandlerSelected(event.target.value, handler, carsPerPlane, type == "morning" ? "morningCarsPerPlane" : "afternoonCarsPerPlane"), value: `${carsPerPlane}`, children: selectOptionsCarsPerPlane().map((option) => (_jsx("option", { value: option.value, children: option.label }, option.value))) })] })) }));
};
export default DeIceCapacityScheduler;
