import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import "./PieChart.scss";
import ReactApexChart from "react-apexcharts";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { AvinorColor } from "@constants";
import { iataDelayCodes } from "@features/OnTimePerformance/utils";
import ToolTip from "@charts/shared/components/Tooltip/Tooltip";
const PieChart = ({ colors, series, labels, props }) => {
    const { t } = useTranslation();
    const options = Object.assign({ noData: {
            text: t("noDataAvailable"),
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
                fontSize: "1rem",
                fontFamily: "Roboto",
            },
        }, series,
        labels,
        colors, tooltip: {
            fillSeriesColor: false,
            theme: "light",
            style: {
                fontSize: "1rem",
                fontFamily: "Roboto",
            },
            custom: function ({ series, seriesIndex, w }) {
                var _a;
                const component = (_jsxs(ToolTip, { children: [_jsxs(ToolTip.Title, { children: [t("delayChart.numberOfDelays"), " for ", w.config.labels[seriesIndex]] }), _jsx(ToolTip.SubTitle, { children: (_a = iataDelayCodes.find((code) => code.shortName === w.config.labels[seriesIndex])) === null || _a === void 0 ? void 0 : _a.fullName }), _jsx(ToolTip.List, { wide: true, children: _jsx(ToolTip.ListItem, { style: {
                                    type: "bullet",
                                    bgColor: colors[seriesIndex],
                                }, content: {
                                    value: w.config.labels[seriesIndex],
                                }, status: {
                                    value: series[seriesIndex],
                                } }, seriesIndex) })] }));
                return renderToString(component);
            },
        }, legend: {
            position: "bottom",
            fontSize: "16",
            fontFamily: "Roboto",
            markers: {
                size: 6,
                shape: "circle",
            },
            itemMargin: {
                vertical: 9,
            },
        }, dataLabels: {
            enabled: false,
        }, plotOptions: {
            pie: {
                startAngle: 0,
                endAngle: 360,
                expandOnClick: false,
                customScale: 1,
                offsetX: 0,
                offsetY: 16,
                dataLabels: {
                    offset: 0,
                    minAngleToShowLabel: 10,
                },
                donut: {
                    size: "70%",
                    labels: {
                        show: true,
                        total: {
                            fontSize: "1rem",
                            fontWeight: "400",
                            color: "var(--avinor-grey-400)",
                            showAlways: true,
                            show: true,
                            label: "Totalt",
                        },
                        value: {
                            fontWeight: "bold",
                            fontSize: "3rem",
                            offsetY: 20,
                        },
                        name: {
                            fontSize: "1rem",
                            fontWeight: "normal",
                            color: AvinorColor.Grey400,
                        },
                    },
                },
            },
        } }, props);
    return (_jsx(ReactApexChart, { height: 300, options: options, series: options.series, labels: options.labels, className: "apexchart-pie-chart", type: "donut" }));
};
export default PieChart;
