import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import "./StatusMessageBanner.scss";
import { Alert, Paragraph } from "@digdir/designsystemet-react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowDownOutlined, ArrowUpOutlined } from "@avinor/react-icons";
import { getStatusMessages } from "@features/OperationalMessages/services";
import { useIataContext } from "@contexts/IataContext";
const StatusMessageBanner = () => {
    const { iata } = useIataContext();
    const { i18n } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(null);
    const currentLanguage = i18n.language.toLowerCase() == "en" ? "en" : "no";
    const { data: statusMessagesAllAirports, isLoading, isError, } = useQuery({
        queryFn: () => getStatusMessages(),
        queryKey: ["getStatusMessages", { iata }],
    });
    try {
        if (isLoading || isError || !statusMessagesAllAirports || !Array.isArray(statusMessagesAllAirports)) {
            return null;
        }
        // Process the data further if needed
    }
    catch (error) {
        console.error("Error while fetching status messages:", error);
        return null; // Return null or handle the error in a user-friendly way
    }
    const messagesForCurrentIata = statusMessagesAllAirports === null || statusMessagesAllAirports === void 0 ? void 0 : statusMessagesAllAirports.filter((x) => { var _a; return ((_a = x.airport) === null || _a === void 0 ? void 0 : _a.toLowerCase()) == iata.toLowerCase() || (x.global && !x.airport); });
    const messageItemsToShow = messagesForCurrentIata
        .filter((x) => {
        var _a;
        const now = new Date();
        const expireDate = ((_a = x.item) === null || _a === void 0 ? void 0 : _a.expiredate) ? new Date(x.item.expiredate) : null;
        return x.item && (!expireDate || expireDate > now);
    })
        .map((x) => (Object.assign(Object.assign({}, x.item), { airport: x.airport, language: x.lang })));
    if (!messageItemsToShow || messageItemsToShow.length == 0) {
        return null;
    }
    const messageItemsToShowForLanguage = messageItemsToShow.filter((x) => {
        // Check if the message is in the current language and has a title
        if (x.language === currentLanguage && x.title) {
            return true;
        }
        else {
            // If the current message's language does not match, check if we should still keep it, because the message in the other language is null
            if (x.title) {
                const messageInOtherLanguage = messageItemsToShow.find((y) => y.airport == x.airport && y.language == currentLanguage);
                if (messageInOtherLanguage) {
                    return messageInOtherLanguage.title == null;
                }
                else {
                    return true;
                }
            }
            return false;
        }
    });
    return (_jsx(_Fragment, { children: messageItemsToShowForLanguage.map((messageItem, index) => {
            if (messageItem) {
                return (_jsx(Alert, { severity: "warning", size: "sm", className: "status-message-alert", children: isExpanded && isExpanded === `${index}` ? (_jsxs(_Fragment, { children: [_jsxs("div", { className: "status-message-header", children: [_jsxs("span", { className: "status-message-title", onClick: () => {
                                            setIsExpanded(null);
                                        }, children: [messageItem.airport ? `${messageItem.airport}: ` : "", messageItem.title] }), _jsx("button", { onClick: () => {
                                            setIsExpanded(null);
                                        }, children: _jsx(ArrowUpOutlined, { width: "14px" }) })] }), _jsx(Paragraph, { size: "xs", className: "status-message-description", children: messageItem.description })] })) : (_jsxs("div", { className: "status-message-collapsed", onClick: () => {
                            setIsExpanded(`${index}`);
                        }, children: [_jsxs("span", { children: [messageItem.airport ? `${messageItem.airport}: ` : "", messageItem.title] }), _jsx(ArrowDownOutlined, { width: "14px" })] })) }, index));
            }
        }) }));
};
export default StatusMessageBanner;
