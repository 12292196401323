import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Cell } from "@table-library/react-table-library";
import { useSort, SortToggleType } from "@table-library/react-table-library/sort";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ArrowDown, ArrowsUpDown, ArrowUp } from "@common-packages/react-icons";
import ErrorMessageWidget from "@components/ErrorMessageWidget/ErrorMessageWidget";
import { VStack } from "@components/Layout";
import Spinner from "@components/Spinner/Spinner";
import Table from "@components/Table/Table";
import { formatRecentDate } from "@components/Table/utils";
import { useIataContext } from "@contexts/IataContext";
import { OPERATIONAL_MESSAGE_ENGLISH_TEXT_SEPARATOR } from "../constants";
import { getOperationalMessages } from "../services";
const isWithinLast5Minutes = (dateString) => {
    const dateToCheck = new Date(dateString);
    const now = new Date();
    const fiveMinutesAgo = new Date(now.getTime() - 5 * 60 * 1000); // subtracting 5 minutes in milliseconds
    return dateToCheck >= fiveMinutesAgo && dateToCheck <= now;
};
const renderMessage = (value) => {
    if (value.includes(OPERATIONAL_MESSAGE_ENGLISH_TEXT_SEPARATOR)) {
        const parts = value.split(OPERATIONAL_MESSAGE_ENGLISH_TEXT_SEPARATOR);
        return (_jsxs(VStack, { gap: 0.5, children: [_jsx("p", { children: parts[0] }), _jsx("p", { style: { color: "var(--avinor-grey-400)" }, children: parts[1] })] }));
    }
    return _jsx("div", { children: value });
};
const OperationalMessagesTable = ({ headers = [
    {
        key: "createdAt",
        sortKey: "createdAt",
        content: "Tid",
    },
    {
        key: "message",
        content: "Melding",
    },
    {
        key: "newMessage",
        content: "",
    },
], dateColumns = ["createdAt"], rowCap, }) => {
    const { iata } = useIataContext();
    const { t } = useTranslation();
    const { data: operationalMessages, isLoading } = useQuery({
        queryFn: () => getOperationalMessages({
            iata: iata.toLowerCase(),
        }),
        queryKey: ["getOperationalMessages", { iata }],
    });
    const visibleMessages = operationalMessages
        ? operationalMessages.slice(0, rowCap || operationalMessages.length)
        : [];
    const messageTableData = visibleMessages.map((message) => {
        return {
            id: message.Id,
            createdAt: message.CreatedAt,
            message: message.Message,
            newMessage: isWithinLast5Minutes(message.CreatedAt),
        };
    });
    const operationalMessagesSortOptions = useSort({ nodes: messageTableData }, {}, {
        sortToggleType: SortToggleType.AlternateWithReset,
        sortIcon: {
            size: "1.5rem",
            iconDefault: _jsx(ArrowsUpDown, {}),
            iconUp: _jsx(ArrowUp, {}),
            iconDown: _jsx(ArrowDown, {}),
        },
        sortFns: {
            createdAt: (array) => array.sort((a, b) => a.createdAt.localeCompare(b.createdAt)),
        },
    });
    if (isLoading)
        return _jsx(Spinner, {});
    if (!operationalMessages || (operationalMessages === null || operationalMessages === void 0 ? void 0 : operationalMessages.length) === 0) {
        return (_jsx(ErrorMessageWidget, { title: t("pages.operationalMessages.title"), description: `Alle driftsmeldinger for ${iata}`, underline: "header" }));
    }
    const customCellRenderers = {
        message: ({ cellIndex, value }) => _jsx(Cell, { children: renderMessage(value) }, cellIndex),
        newMessage: ({ cellIndex, value, hide }) => (_jsx(Cell, { hide: hide, children: value ? _jsx("span", { children: "Ny" }) : "" }, cellIndex)),
        createdAt: ({ value, cellIndex }) => formatRecentDate(value, cellIndex, t),
    };
    return (_jsx(Table, { data: { nodes: messageTableData }, headerGroups: [
            {
                headers,
            },
        ], dateColumns: dateColumns, sort: operationalMessagesSortOptions, cellRenderers: customCellRenderers, customTheme: {
            Cell: `
                  padding: 1rem;
                  text-align: start;

                  &:first-of-type {
                      align-items: start;
                      border-left: none;
                  }

                  &:last-of-type > div {
                    padding: 0.25rem;

                    & span {
                        border-radius: var(--border-radius-normal);
                        border: 1px solid var(--avinor-blue-400);
                        background-color: var(--avinor-blue-50);
                        padding: 0.25rem;
                    }
                  }

                  &:last-of-type {
                    display: flex;
                    align-items: start;
                  }
               `,
            Table: `
                  grid-template-columns: auto minmax(30rem, 1fr) auto;
                  border: 0;
              `,
            BaseCell: `
                  padding: 0.4rem;
                  text-align: center;
                  border-right: none !important;

                  & > div {
                      white-space: normal;
                  }
              `,
            HeaderRow: `
                    background-color: var(--avinor-white);
                `,
            HeaderCell: `
                  padding: 1rem;
                  color: #00000091;
                  border-right: none;
                  
                  &:first-of-type {
                      border-left: none;
                  }

                  & div > div > div {
                      white-space: normal;
                  }

                  & div > div {
                    justify-content: space-between;
                }
                  
                  & > div {
                      text-align: left;
                  }
                  `,
            Row: `
                  &:last-child td {
                      border-bottom: none;
                  }
                  
                  & td:first-of-type {
                      color: var(--avinor-grey-500);
                  }
                  
                  & td:last-child {
                      color: var(--avinor-grey-600)
                  }
              `,
        } }));
};
OperationalMessagesTable.displayName = "OperationalMessagesTable";
export default OperationalMessagesTable;
