import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chart as ChartJS, Tooltip, Legend, LinearScale, CategoryScale, BarElement, Chart, } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { format, isToday } from "date-fns";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { removeZuluSuffix } from "@utils/DateUtils";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Spinner from "@components/Spinner/Spinner";
import { AvinorColor, breakpoints } from "@constants";
import { FormatPattern, Granularity } from "@enums";
import useFlightMovementsChartData from "@features/FlightMovements/hooks/useFlightMovementsChartData";
import { useMediaQuery } from "@hooks";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useGranularityContext } from "@contexts/GranularityContext";
import { useScreenMode } from "@contexts/ScreenModeContext";
import CustomTooltip from "./CustomTooltip";
ChartJS.register(Tooltip, Legend, LinearScale, CategoryScale, BarElement, annotationPlugin);
const shouldDisplayFlightsAnnotationLabels = (granularity) => {
    if (granularity === Granularity.FiveMin)
        return false;
    return true;
};
const BarChart = () => {
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    const is4kScreen = useMediaQuery(`(min-width: ${breakpoints.width4k})`);
    const { t } = useTranslation();
    const { isTvScreen } = useScreenMode();
    const { picker: { id, from, to }, } = useDatePickerContext();
    const { granularity } = useGranularityContext({ from, currentPage: id });
    const { flightMovements, isLoading } = useFlightMovementsChartData({ date: { from, to } });
    const [flightMovement, setFlightMovement] = useState(null);
    const [chartContext, setChartContext] = useState(null);
    Chart.defaults.font.size = is4kScreen ? 26 : 12;
    const updateTooltipData = (flightMovementToSet, chart) => {
        if (JSON.stringify(flightMovementToSet) !== JSON.stringify(flightMovement)) {
            setChartContext(chart);
            setFlightMovement(flightMovementToSet);
        }
    };
    const resetTooltip = () => {
        setChartContext(null);
        setFlightMovement(null);
    };
    const formatDateAxis = (date) => {
        const currentDate = new Date(removeZuluSuffix(date));
        return format(currentDate, granularity === Granularity.Day ? FormatPattern.d_LLL : FormatPattern.HH_mm);
    };
    if (isLoading)
        return _jsx(Spinner, {});
    if (!flightMovements || flightMovements.length === 0)
        return _jsx(NoDataAvailable, {});
    const sharedDataLabelsStyle = {
        font: {
            weight: "bold",
        },
        formatter: function (value) {
            return value === 0 ? "" : value;
        },
    };
    const dataSets = [
        {
            label: "Non-Schengen",
            data: flightMovements.map((flight) => flight.international),
            backgroundColor: AvinorColor.ChartMintGreen300,
            stack: "bar",
            datalabels: Object.assign({ color: AvinorColor.Grey800 }, sharedDataLabelsStyle),
        },
        {
            label: "Schengen",
            data: flightMovements.map((flight) => flight.schengen),
            backgroundColor: AvinorColor.ChartVioletLight600,
            stack: "bar",
            datalabels: Object.assign({ color: AvinorColor.White }, sharedDataLabelsStyle),
        },
        {
            label: "Domestic",
            data: flightMovements.map((flight) => flight.domestic),
            backgroundColor: AvinorColor.ChartVioletLight400,
            stack: "bar",
            datalabels: Object.assign({ color: AvinorColor.White }, sharedDataLabelsStyle),
        },
    ];
    const shouldRenderAnnotations = !isMobile && granularity !== Granularity.Day && isToday(from);
    const renderAnnotations = () => {
        if (!shouldRenderAnnotations)
            return undefined;
        const maxFlights = flightMovements.reduce((max, flight) => Math.max(max, flight.totalFlights || 0), 0);
        const currentHourIndex = flightMovements.findIndex((flight) => format(new Date(flight.from), FormatPattern.HH_00) === format(new Date(), FormatPattern.HH_00));
        const totalFlightsAnnotationLabels = shouldDisplayFlightsAnnotationLabels(granularity)
            ? flightMovements
                .map(({ totalFlights }, i) => ({
                type: "label",
                xValue: i,
                yValue: (totalFlights !== null && totalFlights !== void 0 ? totalFlights : 0) + 1, // +1 to make room for the label
                content: totalFlights,
                color: isTvScreen ? AvinorColor.White : "",
            }))
                .reduce((acc, current, i) => {
                acc[`flight${i}`] = current;
                return acc;
            }, {})
            : {};
        return Object.assign({ presentTimeHighlightBox: {
                type: "box",
                xMin: 0,
                xMax: currentHourIndex,
                yMin: 0,
                yMax: maxFlights + 9, // +9 to make room for the label
                backgroundColor: isTvScreen ? "rgba(51, 62, 78, 0.5)" : "rgba(255, 255, 255, 0.5)",
                borderWidth: 0,
            }, presentTimeHighlightLine: {
                type: "line",
                xMin: currentHourIndex,
                xMax: currentHourIndex,
                yMin: 0,
                yMax: maxFlights + 8, // +8 to make room for the label
                borderColor: AvinorColor.Grey800,
                borderWidth: 1,
                borderDash: [5, 5],
            }, presentTimeHighlightLabel: {
                type: "label",
                xValue: currentHourIndex,
                yValue: maxFlights + 8, // +8 to make room for the label
                content: t("common.now"),
                borderWidth: 1,
                borderColor: isTvScreen ? "#333e4e" : AvinorColor.Grey800,
                backgroundColor: isTvScreen ? "#333e4e" : AvinorColor.White,
                color: isTvScreen ? "#989ea5" : "black",
                borderRadius: 6,
            } }, totalFlightsAnnotationLabels);
    };
    return (_jsxs("div", { style: isTvScreen ? { position: "relative", height: "24rem" } : { position: "relative", height: "40vh" }, children: [_jsx(Bar, { height: "100%", width: "100%", data: {
                    labels: flightMovements.map((flight) => flight.from),
                    datasets: dataSets,
                }, plugins: !isTvScreen ? [ChartDataLabels] : [], options: {
                    indexAxis: isMobile ? "y" : "x",
                    maintainAspectRatio: false,
                    plugins: {
                        annotation: {
                            annotations: renderAnnotations(),
                        },
                        legend: {
                            position: "bottom",
                            align: "start",
                            labels: {
                                boxWidth: 12,
                                boxHeight: 12,
                                useBorderRadius: true,
                                borderRadius: 6,
                            },
                        },
                        tooltip: {
                            enabled: false,
                            external: ({ tooltip, chart }) => {
                                if (tooltip.opacity === 0) {
                                    resetTooltip();
                                    return;
                                }
                                updateTooltipData(flightMovements[tooltip.dataPoints[0].dataIndex], chart);
                            },
                        },
                    },
                    scales: isMobile
                        ? {
                            x: {
                                beginAtZero: true,
                            },
                            y: {
                                ticks: {
                                    callback: function (date) {
                                        const currentDate = this.getLabelForValue(Number(date));
                                        return formatDateAxis(currentDate);
                                    },
                                },
                            },
                        }
                        : {
                            y: {
                                beginAtZero: true,
                            },
                            x: {
                                ticks: {
                                    callback: function (date) {
                                        const currentDate = this.getLabelForValue(Number(date));
                                        return formatDateAxis(currentDate);
                                    },
                                },
                            },
                        },
                } }), flightMovement && (_jsx(CustomTooltip, { flightMovement: flightMovement, chart: chartContext }))] }));
};
export default BarChart;
