import { jsx as _jsx } from "react/jsx-runtime";
import { useMsal } from "@azure/msal-react";
import { createContext, useContext, useState, useEffect } from "react";
// Define domains
export var UserDomains;
(function (UserDomains) {
    UserDomains["Avinor"] = "avinor.no";
    UserDomains["Unknown"] = "unknown";
})(UserDomains || (UserDomains = {}));
// Define roles
export var Roles;
(function (Roles) {
    Roles["Admin"] = "Aop.Admin";
    Roles["PlanWriter"] = "Aop.Write.Plan";
    Roles["Read"] = "Aop.Read.All";
})(Roles || (Roles = {}));
const PermissionsContext = createContext(undefined);
export const PermissionsProvider = ({ children }) => {
    const { accounts } = useMsal();
    const [domain, setDomain] = useState(UserDomains.Unknown);
    const [roles, setRoles] = useState([]);
    useEffect(() => {
        var _a;
        const account = accounts[0];
        const email = account === null || account === void 0 ? void 0 : account.username;
        // Extract domain from email
        const extractedDomain = email ? email.split("@")[1] : UserDomains.Unknown;
        const userDomain = Object.values(UserDomains).includes(extractedDomain)
            ? extractedDomain
            : UserDomains.Unknown;
        setDomain(userDomain);
        // Extract roles from token claims
        const userRoles = ((_a = account === null || account === void 0 ? void 0 : account.idTokenClaims) === null || _a === void 0 ? void 0 : _a.roles) || [];
        setRoles(userRoles);
    }, [accounts]);
    return (_jsx(PermissionsContext.Provider, { value: { domain, setDomain, roles, setRoles }, children: children }));
};
export const usePermissionsContext = () => {
    const context = useContext(PermissionsContext);
    if (!context) {
        throw new Error("usePermissionsContext must be used within a PermissionsProvider");
    }
    return context;
};
