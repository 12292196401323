import { Airports } from "@constants/airports";
import { httpPlanService, httpWeatherService } from "@services/HttpService";
import { XMLParser } from "fast-xml-parser";
import { resolveUrl } from "@utils/UrlUtils";
import { getMSALToken } from "@auth/authUtils";
export async function getPlanOverview({ iata, date }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("date", date);
    const token = await getMSALToken();
    const response = await httpPlanService.get(resolveUrl({
        url: `/get-plan?${params}`,
        mockEndpoint: "/get-plan",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getProcessStatus({ iata, date, processTypeId, }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("date", date);
    params.set("processTypeId", "" + processTypeId);
    const token = await getMSALToken();
    const response = await httpPlanService.get(resolveUrl({
        url: `/get-process-status?${params}`,
        mockEndpoint: "/get-process-status",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getPlanMetadata({ iata }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    const token = await getMSALToken();
    const response = await httpPlanService.get(resolveUrl({
        url: `/get-metadata?${params}`,
        mockEndpoint: "/get-metadata",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function saveProcessStatuses(body) {
    const token = await getMSALToken();
    const res = await httpPlanService.post(resolveUrl({
        url: `save-process`,
        mockEndpoint: "/save-process",
    }), body, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
}
export const getWeatherData = async (iata) => {
    var _a;
    const coordinates = (_a = Airports.find((airport) => airport.iata.toUpperCase() === iata.toUpperCase())) === null || _a === void 0 ? void 0 : _a.coordinates;
    if (!coordinates) {
        console.error("Could not find coordinates for airport with iata: ", iata);
        return {};
    }
    const response = await httpWeatherService.get("/locationforecast/2.0/complete", {
        //OSL coordinates
        params: coordinates,
    });
    return response.data;
};
export const getTextForecast = async () => {
    var _a, _b, _c, _d;
    const response = await httpWeatherService.get("/textforecast/2.0/landoverview");
    const parser = new XMLParser({
        ignoreAttributes: false, // Ensure attributes are not ignored
        attributeNamePrefix: "", // Remove prefix from attribute names
    });
    const jsonObj = parser.parse(response.data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const areaForecast = (_d = (_c = (_b = (_a = jsonObj === null || jsonObj === void 0 ? void 0 : jsonObj.textforecast) === null || _a === void 0 ? void 0 : _a.time[0]) === null || _b === void 0 ? void 0 : _b.forecasttype) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.find((x) => x.id.includes("0503"));
    return {
        text: areaForecast ? areaForecast["#text"] : "No forecast available",
    };
};
