var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import "./Grid.scss";
import clsx from "clsx";
import { forwardRef } from "react";
// eslint-disable-next-line react/display-name
export const Grid = forwardRef((_a, ref) => {
    var { as: Component = "div", children, gap = 1, columns = 3, rows, className, justify, align, display = "grid", style: _style } = _a, rest = __rest(_a, ["as", "children", "gap", "columns", "rows", "className", "justify", "align", "display", "style"]);
    const style = Object.assign({ gridTemplateColumns: typeof columns === "string" ? columns : `repeat(${columns}, minmax(0, 1fr))`, gridTemplateRows: typeof rows === "string" ? rows : `repeat(${rows}, minmax(0, 1fr))`, gap: typeof gap === "string" ? gap : `${gap}rem`, justifyContent: justify, alignItems: align }, _style);
    return (_jsx(Component, Object.assign({}, rest, { ref: ref, style: style, className: clsx("grid", {
            "grid--grid": display === "grid",
            "grid--inline": display === "inline-grid",
        }, className), children: children })));
});
