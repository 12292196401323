import { jsx as _jsx } from "react/jsx-runtime";
import { ArrowDown, ArrowsUpDown, ArrowUp } from "@common-packages/react-icons";
import { Cell } from "@table-library/react-table-library";
import { usePagination } from "@table-library/react-table-library/pagination";
import { SortToggleType, useSort } from "@table-library/react-table-library/sort";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Spinner from "@components/Spinner/Spinner";
import Table from "@components/Table/Table";
import { alignCellLeftClass } from "@components/Table/themes";
import { addIdToObj, formatDateTimeForTable, getCasePriorityColor } from "@components/Table/utils";
import { FacilityCaseStatus, useFacilityStatusPickerContext, } from "@features/Facility/contexts/FacilityStatusPickerContext";
import useFacilityStatusListData from "@features/Facility/hooks/useFacilityStatusListData";
import { useUtcContext } from "@contexts/UtcContext";
const tableColumnHeaders = {
    caseId: "facility.facilityStatusTable.caseId",
    title: "facility.facilityStatusTable.title",
    caseStatus: "facility.facilityStatusTable.caseStatus",
    casePriority: "facility.facilityStatusTable.casePriority",
    objectId: "facility.facilityStatusTable.objectId",
    objectName: "facility.facilityStatusTable.objectName",
    objectType: "facility.facilityStatusTable.objectType",
    objectStatus: "facility.facilityStatusTable.objectStatus",
    createdDate: "facility.facilityStatusTable.createdDate",
    changedDate: "facility.facilityStatusTable.changedDate",
};
const headers = Object.keys(tableColumnHeaders);
const customCellRenderers = (utc) => ({
    caseId: ({ cellIndex, value, className }) => {
        return (_jsx(Cell, { className: className, children: _jsx("a", { style: { cursor: "pointer" }, href: `https://ifs-app10-prod.avinor.no/main/ifsapplications/web/page/MainCase/Form;$filter=CaseId%20eq%20${value}`, target: "_blank", rel: "noreferrer", children: value }) }, cellIndex));
    },
    casePriority: ({ value, cellIndex, row, className }) => {
        const { color, backgroundColor, borderColor } = getCasePriorityColor(row);
        return (_jsx(Cell, { className: className, children: _jsx("div", { style: {
                    border: `1px solid ${borderColor}`,
                    padding: "0.25rem 0.5rem",
                    margin: "0.25rem",
                    borderRadius: "3.125rem",
                    backgroundColor,
                    color,
                    width: "fit-content",
                }, children: value }) }, cellIndex));
    },
    createdDate: ({ value, cellIndex, className }) => {
        const formattedDate = formatDateTimeForTable(value, utc);
        return (_jsx(Cell, { className: className, children: formattedDate }, cellIndex));
    },
    changedDate: ({ value, cellIndex, className }) => {
        const formattedDate = formatDateTimeForTable(value, utc);
        return (_jsx(Cell, { className: className, children: formattedDate }, cellIndex));
    },
    title: ({ value, cellIndex, className }) => (_jsx(Cell, { className: className, children: value }, cellIndex)),
    objectName: ({ value, cellIndex, className }) => (_jsx(Cell, { className: className, children: value }, cellIndex)),
});
const mapCasePriority = (node) => {
    const casePriorityMapping = {
        "1": "Kritisk",
        "2": "Høy",
        "3": "Normal",
    };
    Object.keys(casePriorityMapping).forEach((key) => {
        if (node.casePriority.includes(key)) {
            node.casePriority = casePriorityMapping[key];
        }
    });
};
const mapObjectStatus = (node) => {
    const objectStatusMapping = {
        //This correct??
        InOperation: "Ute av drift",
        OutOfOperation: "Ute av drift",
    };
    Object.keys(objectStatusMapping).forEach((key) => {
        if (node.objectStatus.includes(key)) {
            node.objectStatus = objectStatusMapping[key];
        }
    });
};
const cleanFacilityNodes = (facilities, selectedStatus) => {
    let filteredFacilities = [];
    if (facilities) {
        if (selectedStatus == FacilityCaseStatus.ALL) {
            filteredFacilities = facilities;
        }
        else if (selectedStatus == FacilityCaseStatus.PLANNED) {
            filteredFacilities = facilities.filter((x) => { var _a; return ((_a = x.caseStatus) === null || _a === void 0 ? void 0 : _a.toLowerCase()) != FacilityCaseStatus.NOW.toLowerCase(); });
        }
        else {
            filteredFacilities = facilities.filter((x) => { var _a; return ((_a = x.caseStatus) === null || _a === void 0 ? void 0 : _a.toLowerCase()) == (selectedStatus === null || selectedStatus === void 0 ? void 0 : selectedStatus.toLowerCase()); });
        }
    }
    if (filteredFacilities) {
        const facilitiesWithIds = addIdToObj(filteredFacilities);
        facilitiesWithIds.nodes.forEach((node) => {
            mapCasePriority(node);
            mapObjectStatus(node);
        });
        return facilitiesWithIds;
    }
    else {
        return addIdToObj([]);
    }
};
const filterFacilityListData = (facilityStatusListData, facilitySearchQuery) => {
    var _a;
    if (!facilityStatusListData || !(facilityStatusListData === null || facilityStatusListData === void 0 ? void 0 : facilityStatusListData.facilities))
        return [];
    if (facilitySearchQuery) {
        return (_a = facilityStatusListData === null || facilityStatusListData === void 0 ? void 0 : facilityStatusListData.facilities) === null || _a === void 0 ? void 0 : _a.filter((facility) => {
            var _a, _b, _c, _d;
            return ((_a = facility.objectId) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(facilitySearchQuery)) ||
                ((_b = facility.title) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(facilitySearchQuery)) ||
                ((_c = facility.objectName) === null || _c === void 0 ? void 0 : _c.toLowerCase().includes(facilitySearchQuery)) ||
                ((_d = facility.objectType) === null || _d === void 0 ? void 0 : _d.toLowerCase().includes(facilitySearchQuery));
        });
    }
    else {
        return facilityStatusListData.facilities;
    }
};
export const FacilityStatusTable = ({ facilitySearchQuery }) => {
    const { utc } = useUtcContext();
    const { t } = useTranslation();
    const { data: facilityStatusList, isLoading } = useFacilityStatusListData();
    const facilities = filterFacilityListData(facilityStatusList, facilitySearchQuery);
    const { selectedStatus } = useFacilityStatusPickerContext();
    const cleanedFacilityNodes = cleanFacilityNodes(facilities, selectedStatus);
    const { nodes } = cleanedFacilityNodes;
    const pagination = usePagination({ nodes: nodes }, {
        state: {
            page: 0,
            size: 10,
        },
    }, {
        isServer: false,
    });
    useEffect(() => {
        if (pagination.state.page > 0) {
            pagination.fns.onSetPage(0);
        }
    }, [facilities]);
    const facilitiesSortOptions = useSort({ nodes: nodes }, {}, {
        sortToggleType: SortToggleType.AlternateWithReset,
        sortIcon: {
            size: "1.5rem",
            iconDefault: _jsx(ArrowsUpDown, {}),
            iconUp: _jsx(ArrowUp, {}),
            iconDown: _jsx(ArrowDown, {}),
        },
        sortFns: {
            caseId: (array) => array.sort((a, b) => a.caseId - b.caseId),
            title: (array) => array.sort((a, b) => a.title.localeCompare(b.title)),
            casePriority: (array) => {
                const priorityOrder = ["Kritisk", "Høy", "Normal"];
                return array.sort((a, b) => priorityOrder.indexOf(a.casePriority) - priorityOrder.indexOf(b.casePriority));
            },
            objectId: (array) => array.sort((a, b) => a.objectId - b.objectId),
            objectName: (array) => array.sort((a, b) => a.objectName.localeCompare(b.objectName)),
            objectType: (array) => array.sort((a, b) => a.objectType.localeCompare(b.objectType)),
            objectStatus: (array) => array.sort((a, b) => a.objectStatus.localeCompare(b.objectStatus)),
            createdDate: (array) => array.sort((a, b) => a.createdDate - b.createdDate),
            changedDate: (array) => array.sort((a, b) => a.changedDate - b.changedDate),
        },
    });
    const getTableColumnHeaders = () => {
        return [
            {
                headers: [
                    {
                        key: headers[0],
                        sortKey: headers[0],
                        content: t(tableColumnHeaders.caseId),
                        cellProps: { className: alignCellLeftClass },
                    },
                    {
                        key: headers[1],
                        sortKey: headers[1],
                        content: t(tableColumnHeaders.title),
                        cellProps: { weight: 2, className: alignCellLeftClass },
                    },
                    {
                        key: headers[2],
                        sortKey: headers[2],
                        content: t(tableColumnHeaders.caseStatus),
                        cellProps: { className: alignCellLeftClass },
                    },
                    {
                        key: headers[3],
                        sortKey: headers[3],
                        content: t(tableColumnHeaders.casePriority),
                        cellProps: { className: alignCellLeftClass },
                    },
                    {
                        key: headers[4],
                        sortKey: headers[4],
                        content: t(tableColumnHeaders.objectId),
                        cellProps: { weight: 1.5, className: alignCellLeftClass },
                    },
                    {
                        key: headers[5],
                        sortKey: headers[5],
                        content: t(tableColumnHeaders.objectName),
                        cellProps: { weight: 2, className: alignCellLeftClass },
                    },
                    {
                        key: headers[6],
                        sortKey: headers[6],
                        content: t(tableColumnHeaders.objectType),
                        cellProps: { className: alignCellLeftClass },
                    },
                    {
                        key: headers[7],
                        sortKey: headers[7],
                        content: t(tableColumnHeaders.objectStatus),
                        cellProps: { className: alignCellLeftClass },
                    },
                    {
                        key: headers[8],
                        sortKey: headers[8],
                        content: t(tableColumnHeaders.createdDate),
                        cellProps: { weight: 1.5, className: alignCellLeftClass },
                    },
                    {
                        key: headers[9],
                        sortKey: headers[9],
                        content: t(tableColumnHeaders.changedDate),
                        cellProps: { weight: 1.5, className: alignCellLeftClass },
                    },
                ],
            },
        ];
    };
    if (isLoading)
        return _jsx(Spinner, {});
    if (!facilities || !nodes || nodes.length == 0)
        return _jsx(NoDataAvailable, {});
    return (_jsx(Table, { data: { nodes }, sort: facilitiesSortOptions, excludeKeys: ["sortType", "caseUrl", "createdBy", "changedBy"], cellRenderers: customCellRenderers(utc), headerGroups: getTableColumnHeaders(), pagination: pagination, useDesignsystemetTheme: true, style: { minWidth: "81.25rem" } }));
};
