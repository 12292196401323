import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import nbLocale from "@fullcalendar/core/locales/nb";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { CheckmarkCircleIcon } from "@navikt/aksel-icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@components/Spinner/Spinner";
import useProcessStatus from "@features/Plan/hooks/useProcessStatus";
import { saveProcessStatuses } from "@features/Plan/services";
import { useIataContext } from "@contexts/IataContext";
import { PlanCreateCalendarEvent } from "./PlanCreateCalendarEvent";
import { defaultDisruption, PlanCreateDisruptionModal } from "./PlanCreateDisruptionModal";
import { PlanCreateErrorModal } from "./PlanCreateErrorModal";
import { formatAndNormalizeTime, getEvents, normalizeTime, parseTime } from "./PlanCreateUtils";
import "./PlanCreateCalendar.scss";
const PlanCreateCalendar = ({ selectedDay, setSelectedDay, processTypeId, selectedDayFormatted, disabled = false, }) => {
    const modalRef = useRef(null);
    const [selectInfo, setSelectInfo] = useState();
    const [clickInfo, setClickInfo] = useState();
    const [selectedDisruption, setSelectedDisruption] = useState();
    const errorModalRef = useRef(null);
    const [errorModalState, setErrorModalState] = useState({});
    const [noIssues, setNoIssues] = useState(false);
    const { iata } = useIataContext();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set today's time to midnight for comparison
    //const isEditable = selectedDay >= today;
    const { processStatus, refetch, isFetching } = useProcessStatus({
        date: selectedDayFormatted,
        processTypeId: processTypeId,
    });
    const saveProcessStatusesMutation = useMutation({
        mutationFn: (processesToSave) => saveProcessStatuses({
            iata: iata,
            processesToSave: processesToSave.map((process) => ({
                id: process.id,
                disruptions: process.disruptions,
                processTypeId: process.processType.id,
                date: process.date,
                updatedAt: process.updatedAt,
                updatedBy: process.updatedBy,
            })),
        }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["getPlanOverview"] });
            queryClient.refetchQueries({ queryKey: ["getPlanOverview"] });
            refetch();
        },
        onError: (error) => {
            var _a, _b, _c, _d;
            console.error(error);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const isUnauthorized = ((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.request) === null || _b === void 0 ? void 0 : _b.status) == 401;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const isConflict = ((_d = (_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.request) === null || _d === void 0 ? void 0 : _d.status) == 409;
            setErrorModalState({
                message: isUnauthorized
                    ? t("plan.create.widget.unauthorizedError")
                    : isConflict
                        ? t("plan.create.widget.conflictError")
                        : t("plan.create.widget.unexpectedError"),
            });
            setTimeout(() => {
                var _a;
                (_a = errorModalRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
            }, 0);
            queryClient.invalidateQueries({ queryKey: ["getPlanOverview"] });
            queryClient.refetchQueries({ queryKey: ["getPlanOverview"] });
            refetch();
        },
    });
    useEffect(() => {
        checkIfNoIssues();
    }, [processStatus]);
    const cancelDisruption = () => {
        clearDateSelection();
        checkIfNoIssues();
    };
    const clearDateSelection = () => {
        var _a;
        if (selectInfo) {
            const calendarApi = selectInfo.view.calendar;
            (_a = selectInfo.view.calendar.getEventById("new-event-temp")) === null || _a === void 0 ? void 0 : _a.remove();
            calendarApi.unselect(); // clear date selection
            setSelectInfo(null);
        }
        if (clickInfo) {
            setClickInfo(null);
        }
    };
    const findDisruptions = (eventFrom, eventTo) => {
        return processStatus === null || processStatus === void 0 ? void 0 : processStatus.disruptions.filter((x) => parseTime(x.timePeriodFrom) >= parseTime(eventFrom) &&
            parseTime(x.timePeriodTo) <= parseTime(normalizeTime(eventTo)));
    };
    //Called when switching between days on the calendar
    const handleDatesSet = (info) => {
        setSelectedDay(info.start); // Set the start date of the visible range
    };
    //Called selecting a new date on the calendar, creating a new disruption
    const handleDateSelect = (selectInfo) => {
        var _a;
        setNoIssues(false);
        setSelectInfo(selectInfo);
        const newDisruption = Object.assign(Object.assign({}, defaultDisruption), { timePeriodFrom: format(selectInfo.start, "HH:mm"), timePeriodTo: formatAndNormalizeTime(selectInfo.end) });
        setSelectedDisruption(newDisruption);
        const calendarApi = selectInfo.view.calendar;
        calendarApi.addEvent({
            id: "new-event-temp",
            start: selectInfo.startStr,
            end: selectInfo.endStr,
            allDay: selectInfo.allDay,
        });
        (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
    };
    //Called when clicking on events on calendar, editing a disruption
    const handleEventClick = (clickInfo) => {
        var _a;
        if (!disabled) {
            setNoIssues(false);
            setClickInfo(clickInfo);
            const clickedDisruptions = findDisruptions(format(clickInfo.event.start, "HH:mm"), format(clickInfo.event.end, "HH:mm"));
            if (clickedDisruptions && clickedDisruptions.length > 0) {
                const clickedDisruptionsData = Object.assign({}, clickedDisruptions[0]);
                clickedDisruptionsData.timePeriodFrom = format(clickInfo.event.start, "HH:mm");
                clickedDisruptionsData.timePeriodTo = format(clickInfo.event.end, "HH:mm");
                setSelectedDisruption(clickedDisruptionsData);
                (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
            }
        }
    };
    //Called when dragging events on calendar
    const handleEventDrop = (info) => {
        resizeEventOnCalendar(info);
    };
    //Called when reiszing events on calendar
    const handleEventResize = (info) => {
        resizeEventOnCalendar(info);
    };
    const resizeEventOnCalendar = (info) => {
        const event = info.event;
        const eventOld = info.oldEvent;
        const oldDisruptions = findDisruptions(format(eventOld.start, "HH:mm"), formatAndNormalizeTime(eventOld.end));
        const disruptionsToUpdate = findDisruptions(format(event.start, "HH:mm"), formatAndNormalizeTime(event.end));
        if (oldDisruptions && oldDisruptions.length > 0) {
            const disruptionData = Object.assign({}, oldDisruptions[0]);
            //If expanding, set the data to the disruptions
            if (disruptionsToUpdate && disruptionsToUpdate.length > 0) {
                disruptionsToUpdate.forEach((disruptionToUpdate) => {
                    disruptionToUpdate.comments = disruptionData.comments;
                    disruptionToUpdate.disruptionTypeId = disruptionData.disruptionTypeId;
                    disruptionToUpdate.majorDisturbances = disruptionData.majorDisturbances;
                    disruptionToUpdate.someDisturbances = disruptionData.someDisturbances;
                    disruptionToUpdate.normalOperation = disruptionData.normalOperation;
                });
            }
            //If shrinking, unset the data to the default
            if (oldDisruptions && oldDisruptions.length > 0) {
                oldDisruptions.forEach((oldDisruption) => {
                    if (!(parseTime(oldDisruption.timePeriodFrom) >= parseTime(format(event.start, "HH:mm")) &&
                        parseTime(oldDisruption.timePeriodTo) <= parseTime(formatAndNormalizeTime(event.end)))) {
                        oldDisruption.majorDisturbances = false;
                        oldDisruption.someDisturbances = false;
                        oldDisruption.normalOperation = true;
                        oldDisruption.disruptionTypeId = 0;
                        oldDisruption.comments = "";
                    }
                });
            }
        }
        if (processStatus) {
            saveProcessStatusesMutation.mutate([processStatus]);
        }
    };
    const renderEventContent = (eventInfo) => {
        return _jsx(PlanCreateCalendarEvent, { eventInfo: eventInfo });
    };
    const saveDisruption = (disruptionToSave) => {
        var _a;
        const disruptionsToUpdate = findDisruptions(disruptionToSave.timePeriodFrom, disruptionToSave.timePeriodTo);
        if (disruptionsToUpdate && disruptionsToUpdate.length > 0) {
            disruptionsToUpdate.forEach((disruptionToUpdate) => {
                disruptionToUpdate.comments = disruptionToSave.comments;
                disruptionToUpdate.disruptionTypeId = disruptionToSave.disruptionTypeId;
                disruptionToUpdate.majorDisturbances = disruptionToSave.majorDisturbances;
                disruptionToUpdate.someDisturbances = disruptionToSave.someDisturbances;
                disruptionToUpdate.normalOperation = disruptionToSave.normalOperation;
            });
        }
        if (processStatus) {
            saveProcessStatusesMutation.mutate([processStatus]);
        }
        if (selectInfo) {
            selectInfo.view.calendar.unselect();
        }
        if (clickInfo) {
            clickInfo.view.calendar.unselect();
        }
        setSelectInfo(null);
        setClickInfo(null);
        (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.close();
    };
    const checkIfNoIssues = () => {
        const hasIssuesAndIsSaved = !(processStatus === null || processStatus === void 0 ? void 0 : processStatus.disruptions.some((x) => !x.normalOperation)) && !!(processStatus === null || processStatus === void 0 ? void 0 : processStatus.updatedAt);
        setNoIssues(hasIssuesAndIsSaved || false);
    };
    return (_jsxs(_Fragment, { children: [isFetching || saveProcessStatusesMutation.isPending ? (_jsx(Spinner, {})) : (_jsx("div", { className: disabled ? "full-calendar-disabled" : "", onMouseDown: () => {
                    if (!disabled)
                        setNoIssues(false);
                }, onClick: () => {
                    setTimeout(() => {
                        if (!disabled)
                            checkIfNoIssues();
                    }, 0);
                }, onTouchStart: () => {
                    if (!disabled)
                        setNoIssues(false);
                }, onTouchEnd: () => {
                    setTimeout(() => {
                        if (!disabled)
                            checkIfNoIssues();
                    }, 0);
                }, children: _jsx(FullCalendar, { plugins: [timeGridPlugin, interactionPlugin], initialView: "timeGridDay", initialDate: selectedDay, editable: !disabled, selectable: !disabled, selectMirror: true, selectOverlap: false, eventOverlap: false, dayMaxEvents: true, height: "auto", dayHeaders: false, initialEvents: getEvents(processStatus, selectedDay, !disabled), select: handleDateSelect, eventContent: renderEventContent, eventClick: handleEventClick, locale: nbLocale, slotLabelFormat: {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                    }, eventTimeFormat: {
                        hour: "2-digit",
                        minute: "2-digit",
                        meridiem: false,
                    }, slotMinTime: "00:00:00", slotMaxTime: "24:00:00", slotLabelInterval: "06:00:00", slotDuration: "01:00:00", eventResizableFromStart: true, eventDurationEditable: true, longPressDelay: 100, eventDrop: (info) => {
                        if (info.event && info.event.end && info.event.start) {
                            if (info.event.end >
                                new Date(info.event.start.getFullYear(), info.event.start.getMonth(), info.event.start.getDate(), 24, 0)) {
                                info.revert(); // Revert the event to avoid overlap beyond the day
                            }
                            else {
                                handleEventDrop(info); // Custom drop handler logic
                            }
                        }
                    }, eventResize: (info) => handleEventResize(info), allDaySlot: false, eventConstraint: {
                        start: "06:00", // Start of the day
                        end: "24:00", // End of the day
                    }, datesSet: handleDatesSet }, selectedDayFormatted) })), noIssues && (_jsx("div", { className: `plan-create-modal-no-issues ${disabled ? "plan-create-modal-no-issues-disabled" : ""}`, children: _jsxs("h3", { className: "ds-paragraph ds-paragraph--sm ds-line-height--md", children: [_jsx(CheckmarkCircleIcon, { title: "a11y-title", fontSize: "40px" }), t("plan.create.fillInProcess.noIssues")] }) })), _jsx(PlanCreateDisruptionModal, { modalRef: modalRef, disruptionInfo: selectedDisruption, saveDisruption: saveDisruption, cancelDisruption: cancelDisruption }), _jsx(PlanCreateErrorModal, { errorModalRef: errorModalRef, modalState: errorModalState })] }));
};
export default PlanCreateCalendar;
