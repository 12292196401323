import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { StopSignOutlined } from "@common-packages/react-icons";
import Widget from "@components/Widget/Widget";
import { FacilityOutOfOrder } from "../components";
export const FacilityOutOfOrderWidget = () => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t("facility.outOfOrder.title"), subtitle: t("facility.openCases.subtitle"), icon: {
                    component: (_jsx(StopSignOutlined, { height: "2rem", width: "2rem" })),
                } }), _jsx(Widget.Body, { children: _jsx(FacilityOutOfOrder, {}) })] }));
};
FacilityOutOfOrderWidget.displayName = "FacilityOutOfOrderWidget";
