import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./HeaderBigScreen.scss";
import useCurrentDateTime from "@hooks/useCurrentDateTime";
import { useQuery } from "@tanstack/react-query";
import AvinorLogoLink from "@components/AvinorLogo/AvinorLogoLink";
import LanguageSwitcher from "@components/LanguageSwitcher/LanguageSwitcher";
import { getWeatherData } from "@features/Plan/services";
import { useIataContext } from "@contexts/IataContext";
const CurrentWeather = () => {
    var _a;
    const { iata } = useIataContext();
    const { data: weatherData, isLoading: weatherLoading, error: weatherError, } = useQuery({
        queryFn: () => getWeatherData(iata),
        queryKey: ["getWeatherData", iata],
    });
    const currentHour = new Date().getUTCHours();
    const currentWeather = weatherData === null || weatherData === void 0 ? void 0 : weatherData.properties.timeseries.find((timeserie) => {
        const timeserieHour = new Date(timeserie.time).getUTCHours();
        return timeserieHour === currentHour;
    });
    if (weatherLoading) {
        return null;
    }
    if (weatherError || !weatherData || !currentWeather) {
        return null;
    }
    const airTemperature = Math.round(currentWeather === null || currentWeather === void 0 ? void 0 : currentWeather.data.instant.details.air_temperature);
    return (_jsxs("div", { className: "header-bigscreen-weather", children: [_jsx("img", { className: "header-bigscreen-weather-icon", src: `/weather-icons-avinor/${(_a = currentWeather === null || currentWeather === void 0 ? void 0 : currentWeather.data.next_1_hours) === null || _a === void 0 ? void 0 : _a.summary.symbol_code}.svg`, alt: "weather icon" }), airTemperature, "\u00BAC"] }));
};
const CurrentTime = () => {
    const { currentTime } = useCurrentDateTime();
    return _jsx("time", { className: "header-bigscreen-time", children: currentTime });
};
const HeaderBigScreen = () => {
    return (_jsxs("div", { className: "header-bigscreen", children: [_jsx("div", { className: "header-bigscreen-logo", children: _jsx(AvinorLogoLink, { to: "/" }) }), _jsxs("div", { className: "header-bigscreen-right", children: [_jsx(CurrentWeather, {}), _jsx(CurrentTime, {}), _jsx(LanguageSwitcher, {})] })] }));
};
export default HeaderBigScreen;
