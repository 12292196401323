import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelectOptions } from "@hooks/useSelectOptions";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button from "@components/Buttons/Button/Button";
import { Grid } from "@components/Layout";
import Select from "@components/Select/Select";
import Widget from "@components/Widget/Widget";
import { breakpoints } from "@constants";
import { DelayCodesBarChart, DelayCodesPerFlightBarChart } from "@charts/apexcharts/OnTimePerformance";
import { useMediaQuery } from "@hooks";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useDelayCodeMetaDataContext } from "@contexts/DelayCodeMetaDataContext";
import { UserDomains } from "@contexts/PermissionsContext";
import { SelectOptionsProvider, useSelectOptionContext } from "@contexts/SelectOptionsContext";
import useDelayCodesData from "../hooks/useDelayCodesData";
const delayCategoryOptions = [
    { value: "others", label: "Others" },
    { value: "P", label: "Passenger/Baggage" },
    { value: "C", label: "Cargo/Mail" },
    { value: "G", label: "Aircraft/Ramp Handling" },
    { value: "T", label: "Technical/Aircraft Equipment" },
    { value: "damageFailure", label: "Damage/EDP eqp. Failure" },
    { value: "F", label: "Flight Operations/Crewing" },
    { value: "W", label: "Weather" },
    { value: "restrictions", label: "Air Traffic Flow Management Restrictions" },
    { value: "authorities", label: "Airport/Government Authorities" },
    { value: "R", label: "Reactionary" },
    { value: "M", label: "Miscellaneous" },
];
const DelayCodesFilters = () => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    const { data: delayCodes } = useDelayCodesData();
    const { picker: { from }, } = useDatePickerContext();
    const { delayCodeMetaData: selectedDelayCodeMetaData } = useDelayCodeMetaDataContext(from);
    const { options: delayCodeOptions, setOptions: setSelectedDelayCodes } = useSelectOptionContext("delayCodes");
    const { options: delayCodesAirlineOptions, setOptions: setSelectedAirlines } = useSelectOptionContext("delayCodesAirlines");
    const { options } = useMemo(() => {
        var _a, _b;
        const airlineNames = (_b = (_a = delayCodes === null || delayCodes === void 0 ? void 0 : delayCodes.delayCodes) === null || _a === void 0 ? void 0 : _a.flatMap((delaycode) => delaycode.airlineBreakdown.map((airline) => airline.airlineName))) !== null && _b !== void 0 ? _b : [];
        const uniqueAirlineNames = Array.from(new Set(airlineNames));
        const options = useSelectOptions(delayCodesAirlineOptions, uniqueAirlineNames.map((name) => ({ [name]: name })), (key) => {
            return { value: key, label: key };
        });
        return options;
    }, [delayCodes]);
    if (!selectedDelayCodeMetaData) {
        return (_jsxs(Grid, { columns: isMobile ? 1 : 2, style: { marginLeft: isMobile ? "initial" : "50%", padding: "0.5rem" }, children: [_jsx(Select, { isMulti: true, label: t("categories"), options: delayCategoryOptions, onChange: (option) => setSelectedDelayCodes(option), defaultValue: delayCodeOptions }), _jsx(Select, { isMulti: true, label: t("airlines"), options: options, onChange: (option) => setSelectedAirlines(option), defaultValue: delayCodesAirlineOptions })] }));
    }
    return null;
};
export const DelayCodesBarChartWidget = () => {
    const { picker: { from }, } = useDatePickerContext();
    const { delayCodeMetaData: selectedDelayCodeMetaData, setDelayCodeMetaData: setSelectedDelayCodeMetaData } = useDelayCodeMetaDataContext(from);
    const { t } = useTranslation();
    const perFlightTitle = t("charts.onTimePerformance.bar.delayCodes.perFlight.title", {
        delayCode: selectedDelayCodeMetaData === null || selectedDelayCodeMetaData === void 0 ? void 0 : selectedDelayCodeMetaData.delayCode,
    });
    const perFlightSubTitle = t("charts.onTimePerformance.bar.delayCodes.perFlight.subTitle");
    const delayCodesTitle = t("charts.onTimePerformance.bar.delayCodes.title");
    const delayCodesSubTitle = t("charts.onTimePerformance.bar.delayCodes.subTitle");
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: selectedDelayCodeMetaData ? perFlightTitle : delayCodesTitle, subtitle: selectedDelayCodeMetaData ? perFlightSubTitle : delayCodesSubTitle }), _jsxs(SelectOptionsProvider, { children: [_jsx(DelayCodesFilters, {}), _jsx(_Fragment, { children: selectedDelayCodeMetaData && (_jsx(Button, { variant: "flat", style: { alignSelf: "start" }, onClick: () => {
                                setSelectedDelayCodeMetaData(null);
                            }, children: t("back") })) }), _jsx(Widget.Body, { children: selectedDelayCodeMetaData ? _jsx(DelayCodesPerFlightBarChart, {}) : _jsx(DelayCodesBarChart, {}) })] })] }));
};
DelayCodesBarChartWidget.displayName = "DelayCodesBarChartWidget";
DelayCodesBarChartWidget.permissions = { requiredDomain: UserDomains.Avinor };
