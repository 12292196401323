import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./Widget.scss";
import clsx from "clsx";
import React from "react";
import Tooltip from "../Tooltip";
const styleSheet = {
    widget: clsx("widget"),
    header: clsx("widget__header"),
    footer: clsx("widget__footer"),
    numericIndicator: clsx("widget__numeric-indicator"),
    container: clsx("widget__container"),
    title: clsx("widget__title"),
    titleContainer: clsx("widget__title-container"),
    icon: clsx("widget__icon"),
    subTitle: (visibility) => clsx("widget__sub-title", {
        "widget__sub-title--hidden": visibility === "hidden",
        "widget__sub-title--none": visibility === "none",
    }),
    body: (center) => clsx("widget__body", {
        "widget__body--center": center,
    }),
};
const Widget = ({ style, children, className }) => {
    return (_jsx("section", { className: clsx(styleSheet.widget, className), style: style, children: children }));
};
const Container = ({ style, children }) => {
    return (_jsx("div", { className: styleSheet.container, style: style, children: children }));
};
const Header = ({ title, subtitle, subtitleVisibility, alignment, icon }) => {
    const { component, tooltip } = icon || {};
    const { id = "react-tooltip", html, place } = tooltip || {};
    const headerClasses = clsx(styleSheet.header, {
        "widget__header--1line": subtitle && alignment === "1line",
    }); // 1
    if (!title && !subtitle)
        return null;
    const EnhancedIcon = () => {
        if (!component)
            return null;
        return tooltip ? (_jsxs(_Fragment, { children: [React.cloneElement(component, {
                    "data-tooltip-id": id,
                    "data-tooltip-html": html,
                    "data-tooltip-place": place,
                }), _jsx(Tooltip, { id: id })] })) : (component);
    };
    return (_jsxs("div", { className: headerClasses, children: [_jsxs("div", { className: styleSheet.titleContainer, children: [title && _jsx("h3", { className: styleSheet.title, children: title }), _jsx(EnhancedIcon, {})] }), _jsx("p", { className: styleSheet.subTitle(subtitleVisibility), children: subtitle })] }));
};
const Footer = ({ children }) => {
    return children ? _jsx("p", { className: styleSheet.footer, children: children }) : _jsx(_Fragment, {});
};
const NumericIndicator = ({ children, size = "normal" }) => {
    const classes = clsx(styleSheet.numericIndicator, {
        "widget__numeric-indicator-small": size === "small",
        "widget__numeric-indicator-normal": size === "normal",
    });
    return _jsx("span", { className: classes, children: children });
};
const Body = ({ children, center, style }) => {
    return (_jsx("div", { className: styleSheet.body(center), style: style, children: children }));
};
Widget.Container = Container;
Widget.Header = Header;
Widget.Footer = Footer;
Widget.Body = Body;
Widget.NumericIndicator = NumericIndicator;
export default Widget;
