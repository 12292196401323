import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./PlanOverviewAirport.scss";
import { useTranslation } from "react-i18next";
import useFlightMovementsChartData from "@features/FlightMovements/hooks/useFlightMovementsChartData";
import { useDatePickerContext } from "@contexts/DatePickerContext";
const PlanOverviewAirportFlights = ({ iata }) => {
    const { picker: { from, to }, } = useDatePickerContext();
    const { t } = useTranslation();
    const { flightMovements, isLoading } = useFlightMovementsChartData({ date: { from, to }, iata: iata });
    if (isLoading || !flightMovements) {
        return null;
    }
    const totalDeparture = flightMovements.reduce((sum, x) => sum + (x.departure || 0), 0);
    const totalArrival = flightMovements.reduce((sum, x) => sum + (x.arrival || 0), 0);
    const total = totalDeparture + totalArrival;
    return (_jsxs("div", { className: "plan-overview-airport", children: [_jsx("p", { className: "plan-overview-airport-label-main", children: t("common.flights") }), _jsx("p", { className: "plan-overview-airport-label-secondary", children: t("common.arrival") }), _jsx("p", { className: "plan-overview-airport-label-secondary", children: t("common.departure") }), _jsx("p", { className: "plan-overview-airport-value-main", children: total }), _jsx("p", { className: "plan-overview-airport-value-secondary", children: totalDeparture }), _jsx("p", { className: "plan-overview-airport-value-secondary", children: totalArrival })] }));
};
export default PlanOverviewAirportFlights;
