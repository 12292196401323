import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PeopleGroup3Outlined } from "@common-packages/react-icons";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { useIataContext } from "@contexts/IataContext";
import { PaxEnroute } from "../components";
export const PaxEnrouteWidget = ({ title, subTitle, footerText, }) => {
    const { iata } = useIataContext();
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t(title, { iata }), subtitle: t(subTitle), icon: {
                    component: (_jsx(PeopleGroup3Outlined, { width: "1.5rem", height: "1.5rem" })),
                } }), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Widget.Body, { children: _jsx(PaxEnroute, {}) }) }), _jsx(Widget.Footer, { children: footerText })] }));
};
PaxEnrouteWidget.displayName = "PaxEnrouteWidget";
