import { AirportTrainsStatusesTableWidget, AirportTrainsStatusesDepartureTableWidget, AirportTrainsStatusesArrivalTableWidget, } from "@features/AirportTransportation/widgets";
import { AirportRoadDelaysWidget } from "@features/AirportTransportation/widgets/AirportRoadDelaysWidget";
import { BaggageOnBeltPerformanceAverageTableWidget, BaggageOnBeltPerformanceAverageLineChartWidget, BaggageEnroutePerFlightEstimateTableWidget, BaggageEnroutePerFlightEstimateBarChartWidget, BaggageEnrouteSchengenWidget, BaggageEnrouteNonSchengenWidget, BaggageOnBeltPerformanceAveragePerFlightCombinedWidget, BaggageEnrouteTableWidget, } from "@features/Baggage/widgets";
import { BaggageDepartedTableWidget } from "@features/Baggage/widgets/BaggageDepartedTableWidget";
import { BaggageFullFlightsWidget } from "@features/Baggage/widgets/BaggageFullFlightsWidget";
import { BaggageNotSentWidget } from "@features/Baggage/widgets/BaggageNotSentWidget";
import { BaggagePrintedTagsWidget } from "@features/Baggage/widgets/BaggagePrintedTagsWidget";
import { DeIceAverageQueTime, DeIceExpectedNext60Platforms, DeIceMedianTimePerAircraft, DeIceMode, NumberOfPlanesForDeIce, PlatformSpecificDeIceData, } from "@features/DeIce/components";
import { DeIceCapacityBarChartWidget } from "@features/DeIce/widgets";
import { DeIceApronHeaterWidget } from "@features/DeIce/widgets/DeiceApronHeaterWidget";
import { FacilityOpenCasesWidget, FacilityOutOfOrderWidget, FacilityStatusTableWidget, FacilityErrorCountWidget, FacilityOpenCasesBaggageArrivalWidget, FacilityOpenCasesBaggageDepartureWidget, FacilityOpenCasesTransferWidget, FacilityOpenCasesCheckInDepartureWidget, FacilityOpenCasesSecurityDepartureWidget, FacilityOpenCasesTurnAroundWidget, } from "@features/Facility/widgets";
import { FlightMovementsBarChartWidget, FlightMovementsEnrouteWidget, FlightMovementsTodayWidget, FlightMovementsDomesticWidget, FlightMovementsInternationalWidget, } from "@features/FlightMovements/widgets";
import { FlightMovementsTotalsWidget } from "@features/FlightMovements/widgets/FlightMovementsTotalsWidget";
import { PunctualityTotalOverviewWidget, RegularityTotalOverviewWidget, PunctualityProgressBarWidget, RegularityProgressBarWidget, DelayTopFiveDelayCodesPieChartWidget, DelayAirportOperativeDelaysPieChartWidget, DelaySummaryWidget, PunctualityDayLineChartWidget, DelayCodesBarChartWidget, PunctualityReportWidget, OnTimePerformanceServiceQualityStepperWidget, OnTimePerformanceServiceQualityArrivalTableWidget, OnTimePerformanceServiceQualityDepartureTableWidget, OnTimePerformanceServiceQualityRunwayTableWidget, OnTimePerformanceServiceQualityTurnaroundTableWidget, } from "@features/OnTimePerformance/widgets";
import { OntimePerformanceOverviewWidget } from "@features/OnTimePerformance/widgets/OntimePerformanceOverviewWidget";
import { OnTimePerformanceServiceQualityCardsWidget } from "@features/OnTimePerformance/widgets/OnTimePerformanceServiceQualityCardsWidget";
import { OnTimePerformanceServiceQualityFlowRegulationWidget } from "@features/OnTimePerformance/widgets/OnTimePerformanceServiceQualityFlowRegulationWidget";
import { OnTimePerformanceServiceQualityWrapperWidget } from "@features/OnTimePerformance/widgets/OnTimePerformanceServiceQualityWrapperWidget";
import { OperationalMessagesTableWidget } from "@features/OperationalMessages/widgets";
import { PaxEnrouteWidget, PaxThroughSecurityWidget, PaxQueueTimeWidget } from "@features/Pax/widgets";
import { PaxEnrouteTableWidget } from "@features/Pax/widgets/PaxEnrouteTableWidget";
import { PaxPredictionGraphWidget } from "@features/Pax/widgets/PaxPredictionGraphWidget";
import { PaxTotalsWidget } from "@features/Pax/widgets/PaxTotalsWidget";
import { PlanOverviewProcessStatusesWidget } from "@features/Plan/widgets";
import { PlanOverviewAirportWidget } from "@features/Plan/widgets/PlanOverview/PlanOverviewAirportWidget";
import { PlanOverviewDayPickerWidget } from "@features/Plan/widgets/PlanOverview/PlanOverviewDayPickerWidget";
import { PlanOverviewMeteogramWidget } from "@features/Plan/widgets/PlanOverview/PlanOverviewMeteogramWidget";
import { PlanOverviewWeatherWidget } from "@features/Plan/widgets/PlanOverview/PlanOverviewWeatherWidget";
const widgets = {
    DeIceMode,
    DeIceAverageQueTime,
    PunctualityDayLineChartWidget,
    NumberOfPlanesForDeIce,
    DeIceMedianTimePerAircraft,
    DeIceApronHeaterWidget,
    DelayAirportOperativeDelaysPieChartWidget,
    DelaySummaryWidget,
    RegularityProgressBarWidget,
    DeIceExpectedNext60Platforms,
    PlatformSpecificDeIceData,
    PunctualityReportWidget,
    DelayTopFiveDelayCodesPieChartWidget,
    PunctualityProgressBarWidget,
    FacilityOpenCasesWidget,
    FacilityOutOfOrderWidget,
    FacilityErrorCountWidget,
    FacilityOpenCasesCheckInDepartureWidget,
    FacilityOpenCasesSecurityDepartureWidget,
    FacilityOpenCasesBaggageArrivalWidget,
    FacilityOpenCasesBaggageDepartureWidget,
    FacilityOpenCasesTransferWidget,
    FacilityOpenCasesTurnAroundWidget,
    FacilityStatusTableWidget,
    OperationalMessagesTableWidget,
    AirportTrainsStatusesTableWidget,
    AirportTrainsStatusesDepartureTableWidget,
    AirportTrainsStatusesArrivalTableWidget,
    AirportRoadDelaysWidget,
    BaggageEnroutePerFlightEstimateTableWidget,
    BaggageEnroutePerFlightEstimateBarChartWidget,
    BaggageOnBeltPerformanceAveragePerFlightCombinedWidget,
    BaggageOnBeltPerformanceAverageLineChartWidget,
    BaggageOnBeltPerformanceAverageTableWidget,
    BaggageEnrouteTableWidget,
    BaggagePrintedTagsWidget,
    BaggageDepartedTableWidget,
    BaggageFullFlightsWidget,
    BaggageNotSentWidget,
    DeIceCapacityBarChartWidget,
    DelayCodesBarChartWidget,
    PunctualityTotalOverviewWidget,
    OntimePerformanceOverviewWidget,
    RegularityTotalOverviewWidget,
    BaggageEnrouteSchengenWidget,
    BaggageEnrouteNonSchengenWidget,
    PaxThroughSecurityWidget,
    PaxEnrouteWidget,
    PaxEnrouteTableWidget,
    PaxPredictionGraphWidget,
    FlightMovementsEnrouteWidget,
    FlightMovementsTodayWidget,
    FlightMovementsDomesticWidget,
    FlightMovementsInternationalWidget,
    FlightMovementsBarChartWidget,
    FlightMovementsTotalsWidget,
    OnTimePerformanceServiceQualityStepperWidget,
    OnTimePerformanceServiceQualityArrivalTableWidget,
    OnTimePerformanceServiceQualityDepartureTableWidget,
    OnTimePerformanceServiceQualityRunwayTableWidget,
    OnTimePerformanceServiceQualityTurnaroundTableWidget,
    OnTimePerformanceServiceQualityWrapperWidget,
    OnTimePerformanceServiceQualityCardsWidget,
    OnTimePerformanceServiceQualityFlowRegulationWidget,
    PlanOverviewProcessStatusesWidget,
    PlanOverviewMeteogramWidget,
    PlanOverviewWeatherWidget,
    PlanOverviewDayPickerWidget,
    PlanOverviewAirportWidget,
    PaxQueueTimeWidget,
    PaxTotalsWidget,
};
const lookup = (name) => {
    return widgets[name] || null;
};
export default lookup;
