import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Widget from "@components/Widget/Widget";
import PlanOverviewWeatherStatus from "@features/Plan/components/PlanOverview/PlanOverviewWeatherStatus";
import PlanOverviewWeatherTable from "@features/Plan/components/PlanOverview/PlanOverviewWeatherTable";
import "./BigScreenWeatherWidget.scss";
import { useIataContext } from "@contexts/IataContext";
export const BigScreenWeatherWidget = () => {
    const { iata } = useIataContext();
    return (_jsx(Widget.Container, { children: _jsx(Widget.Body, { children: _jsxs("div", { className: "WeatherWidget-content", children: [_jsx("h3", { className: "widget__title", children: "V\u00E6rvarsel: Oslo Lufthavn" }), _jsxs("div", { className: "WeatherWidget-wrapper", children: [_jsx("div", { className: "WeatherWidget-weather-status", children: _jsx(PlanOverviewWeatherStatus, { iata: iata }) }), _jsx(PlanOverviewWeatherTable, { iata: iata })] })] }) }) }));
};
BigScreenWeatherWidget.displayName = "BigScreenWeatherWidget";
