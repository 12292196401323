import { t } from "i18next";
import { Component } from "react";
export const getAvinorColor = (color) => getComputedStyle(document.documentElement).getPropertyValue(color).toString().trim();
export class Transform extends Component {
}
Transform.rotate = (degree = 0) => {
    return `rotate(${degree})`;
};
Transform.translate = (x, y) => {
    return `translate(${x}, ${y})`;
};
export const isInUnion = (value, unionValues) => {
    return unionValues.includes(value);
};
export const trainCompanyMapping = {
    FLT: "Flytoget",
    VY: "Vy",
    SJN: "SJ Nord",
};
export const mapTrainCompanyName = (key) => {
    return trainCompanyMapping[key] || "Unknown";
};
export const extractUniqueKeys = (data, excludeKeys) => {
    const uniqueKeysSet = new Set();
    data.forEach((obj) => {
        Object.keys(obj)
            .filter((key) => !excludeKeys.has(key))
            .forEach((key) => uniqueKeysSet.add(key));
    });
    return Array.from(uniqueKeysSet);
};
export const minutesToHoursAndMinutes = (minutes, t) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (hours === 0) {
        return t("timeFormats.minuteShort", { count: remainingMinutes });
    }
    if (hours === 1 && remainingMinutes === 0) {
        return t("timeFormats.hourShort", { count: hours });
    }
    return `${t("timeFormats.hourShort", { count: hours })} ${t("timeFormats.minuteShort", {
        count: remainingMinutes,
    })}`;
};
export const filterByKeys = (data, filterKeys) => {
    const filteredData = {};
    for (const key of filterKeys) {
        if (key in data) {
            filteredData[key] = data[key];
        }
    }
    return filteredData;
};
export const formatStringsWithConjunction = (list, conjunction = "and") => {
    if (list.length === 0)
        return "??";
    if (list.length === 1)
        return list[0];
    return list.reduce((prev, curr, index, array) => {
        if (index === array.length - 1) {
            return `${prev} ${conjunction} ${curr}`;
        }
        else {
            return prev ? `${prev}, ${curr}` : curr;
        }
    }, "");
};
export const getProgressStatusText = (percent, completed, planned) => {
    if (completed !== undefined && planned !== undefined) {
        return t("common.progressStatusCompletedAndPlanned", {
            percent: roundIfNeeded(percent || 0),
            completed,
            planned,
        });
    }
    else if (completed !== undefined) {
        return t("common.progressStatusCompleted", { percent: roundIfNeeded(percent || 0), completed });
    }
    else if (planned !== undefined) {
        return t("common.progressStatusPlanned", { percent: roundIfNeeded(percent || 0), planned });
    }
    else {
        return "";
    }
};
export const omit = (obj, props) => {
    return Object.entries(obj)
        .filter(([key]) => !props.includes(key))
        .reduce((prevObj, [key, value]) => (Object.assign(Object.assign({}, prevObj), { [key]: value })), {});
};
// https://github.com/gregberge/react-merge-refs
export default function mergeRefs(refs) {
    return (value) => {
        refs.forEach((ref) => {
            if (typeof ref === "function") {
                ref(value);
            }
            else if (ref !== null && ref !== undefined) {
                ref.current = value;
            }
        });
    };
}
/**
 * Rounds a number to two decimal places if it has decimals.
 * Returns the number as-is if it's an integer.
 *
 * @param {number} number - The number to be rounded.
 * @return {number} The rounded number, or the original number if it's an integer.
 */
export const roundIfNeeded = (number) => {
    return number % 1 === 0 ? number : Number(number.toFixed(2));
};
export const calculatePercentage = (value, total) => {
    return total != 0 ? (value / total) * 100 : 0;
};
export const formatNumber = (numberToFormat) => {
    const formatter = new Intl.NumberFormat("no-NO");
    return formatter.format(numberToFormat);
};
export const getTableColumnWidthPercentage = (numberOfColumns) => {
    return "" + Math.round((100 / numberOfColumns) * 100) / 100 + "%";
};
export const getDomainName = (email) => {
    const parts = email.split("@");
    if (parts.length !== 2)
        return "";
    const domainParts = parts[1].split(".");
    if (domainParts.length < 2 || !domainParts[0])
        return "";
    return domainParts[0].charAt(0).toUpperCase() + domainParts[0].slice(1);
};
