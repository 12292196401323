import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { InfoICircleOutlined } from "@common-packages/react-icons";
import { isToday } from "date-fns";
import { useTranslation } from "react-i18next";
import { getDateToReadableText, isSingleDay } from "@utils/DateUtils";
import { FlightMovementsFilters } from "@components/Filters/FlightMovementsFilters/FlightMovementsFilters";
import Widget from "@components/Widget/Widget";
import { Granularity } from "@enums";
import { FlightMovementsBarChart } from "@charts/chartjs/FlightMovements";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useGranularityContext } from "@contexts/GranularityContext";
import { useScreenMode } from "@contexts/ScreenModeContext";
export const FlightMovementsBarChartWidget = ({ title, subTitle }) => {
    const { picker: { id, from, to }, } = useDatePickerContext();
    const { granularity: selectedGranularity } = useGranularityContext({ from, currentPage: id });
    const { isTvScreen } = useScreenMode();
    const { t } = useTranslation();
    const readableDateRange = getDateToReadableText().toLowerCase();
    const formatNorwegianDate = (date) => {
        const formattedDate = new Date(date).toLocaleDateString("nb-NO", {
            weekday: "long",
            month: "long",
            day: "2-digit",
        });
        return formattedDate;
    };
    const formatDisplayDate = (isBarClicked, date) => {
        if (isBarClicked && isToday(from)) {
            return formatNorwegianDate(date);
        }
        else {
            return t("charts.flightMovements.bar.allFlightMovements");
        }
    };
    const localTitle = formatDisplayDate(selectedGranularity !== Granularity.Day, from);
    const localSubTitle = isSingleDay(from, to) && !isToday(from)
        ? t("charts.flightMovements.bar.distributedOn", { date: formatNorwegianDate(from) })
        : t("charts.flightMovements.bar.distributedOn", { date: readableDateRange });
    const mainTitle = t(title) || localTitle;
    const mainSubTitle = t(subTitle) || localSubTitle;
    const explanation = t("charts.flightMovements.bar.explanation");
    return (_jsxs(Widget.Container, { children: [isTvScreen ? (_jsx(_Fragment, { children: _jsx(Widget.Header, { title: mainTitle, subtitleVisibility: "none" }) })) : (_jsxs(_Fragment, { children: [_jsx(Widget.Header, { title: mainTitle, subtitle: mainSubTitle, icon: {
                            component: (_jsx(InfoICircleOutlined, { width: "1rem", height: "1rem" })),
                            tooltip: {
                                id: "flightMovementsBarChartWidget",
                                html: `<h3>${t(title)}</h3><p>${explanation}</p>`,
                            },
                        } }), _jsx(FlightMovementsFilters, {})] })), _jsx(Widget.Body, { children: _jsx(FlightMovementsBarChart, {}) })] }));
};
FlightMovementsBarChartWidget.displayName = "FlightMovementsBarChartWidget";
