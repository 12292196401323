import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Grid } from "@components/Layout";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import Spinner from "@components/Spinner/Spinner";
import { useDelayNumbers } from "../hooks";
export const DelayTotalFlightDelays = () => {
    const { totalDelays, isLoading } = useDelayNumbers();
    if (isLoading)
        return _jsx(Spinner, {});
    return _jsx(_Fragment, { children: isLoading ? "" : totalDelays });
};
export const DelaySummary = ({ format = "small" }) => {
    const { delayGreaterThanOrEqualTo15min, delayGreaterThanOrEqualTo3min, isLoading } = useDelayNumbers();
    const { t } = useTranslation();
    if (isLoading)
        return _jsx(_Fragment, {});
    return (_jsxs(Grid, { columns: 2, children: [_jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", title: t("onTimePerformance.delay.widgets.summary.between3min15min"), value: delayGreaterThanOrEqualTo3min || "N/A", valueSize: format }), _jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", title: t("onTimePerformance.delay.widgets.summary.moreThan15min"), value: delayGreaterThanOrEqualTo15min || "N/A", valueSize: format })] }));
};
