import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isToday } from "date-fns";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { InfoICircleOutlined } from "@avinor/react-icons";
import { getDateToReadableText, isSingleDay } from "@utils/DateUtils";
import { FlightMovementsFilters } from "@components/Filters/FlightMovementsFilters/FlightMovementsFilters";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { Granularity } from "@enums";
import { FlightMovementsBarChart } from "@charts/chartjs/FlightMovements";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useGranularityContext } from "@contexts/GranularityContext";
import { useScreenMode } from "@contexts/ScreenModeContext";
export const FlightMovementsBarChartWidget = ({ title, subTitle }) => {
    const { picker: { id, from, to }, } = useDatePickerContext();
    const { granularity: selectedGranularity } = useGranularityContext({ from, currentPage: id });
    const { screenMode } = useScreenMode();
    const { t } = useTranslation();
    const readableDateRange = getDateToReadableText().toLowerCase();
    const formatNorwegianDate = (date) => {
        const formattedDate = new Date(date).toLocaleDateString("nb-NO", {
            weekday: "long",
            month: "long",
            day: "2-digit",
        });
        return formattedDate;
    };
    const formatDisplayDate = (isBarClicked, date) => {
        if (isBarClicked && isToday(from)) {
            return formatNorwegianDate(date);
        }
        else {
            return t("charts.flightMovements.bar.allFlightMovements");
        }
    };
    const localTitle = formatDisplayDate(selectedGranularity !== Granularity.Day, from);
    const localSubTitle = isSingleDay(from, to) && !isToday(from)
        ? t("charts.flightMovements.bar.distributedOn", { date: formatNorwegianDate(from) })
        : t("charts.flightMovements.bar.distributedOn", { date: readableDateRange });
    const mainTitle = t(title) || localTitle;
    const mainSubTitle = t(subTitle) || localSubTitle;
    const explanation = t("charts.flightMovements.bar.explanation");
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: mainTitle, subtitle: mainSubTitle, icon: screenMode === "STANDARD_MODE"
                    ? {
                        component: (_jsx(InfoICircleOutlined, { width: "1rem", height: "1rem" })),
                        tooltip: {
                            id: "flightMovementsBarChartWidget",
                            html: `<h3>${t(title)}</h3><p>${explanation}</p>`,
                        },
                    }
                    : undefined }), screenMode === "STANDARD_MODE" && _jsx(FlightMovementsFilters, {}), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Widget.Body, { children: _jsx(FlightMovementsBarChart, {}) }) })] }));
};
FlightMovementsBarChartWidget.displayName = "FlightMovementsBarChartWidget";
