import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./PlanOverviewRemarks.scss";
import { PenOutlined } from "@common-packages/react-icons";
import { Paragraph } from "@digdir/designsystemet-react";
import { useQuery } from "@tanstack/react-query";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "@utils/DateUtils";
import Spinner from "@components/Spinner/Spinner";
import { FormatPattern } from "@enums";
import { usePlanDayPickerContext } from "@features/Plan/contexts/PlanDayPickerContext";
import { getPlanOverview } from "@features/Plan/services";
import useRoles from "@auth/hooks/useRoles";
import { useIataContext } from "@contexts/IataContext";
import { PlanCreateRemarksModal } from "../PlanCreate/PlanCreateRemarksModal";
export const PlanOverviewRemarks = ({ bigScreen = false }) => {
    const { iata } = useIataContext();
    const { planDate, planDateFormatted, dateIsPast } = usePlanDayPickerContext();
    const modalRef = useRef(null);
    const { hasAdminRole } = useRoles();
    const { t } = useTranslation();
    const { data: planOverview, isLoading } = useQuery({
        queryFn: () => getPlanOverview({ iata, date: planDateFormatted }),
        queryKey: ["getPlanOverview", { iata, planDateFormatted }],
    });
    if (isLoading) {
        return (_jsx("div", { className: "plan-overview-remarks", children: _jsx(Spinner, {}) }));
    }
    if (!(planOverview === null || planOverview === void 0 ? void 0 : planOverview.remarks) && !hasAdminRole) {
        return null;
    }
    const planDateTitle = formatDate(false, planDate, FormatPattern.dd_MM_yyyy);
    return (_jsxs("div", { className: `plan-overview-remarks ${dateIsPast ? "plan-overview-remarks-yesterday" : ""}`, children: [_jsxs("div", { className: "plan-overview-remarks-wrapper", children: [_jsxs("div", { className: "plan-overview-remarks-title-wrapper", children: [_jsx("span", { className: "plan-overview-remarks-title", children: dateIsPast
                                    ? t("plan.overview.remarks.title") + " " + planDateTitle
                                    : t("plan.overview.remarks.titleYesterday") + " " + planDateTitle }), hasAdminRole && !bigScreen && (_jsx(PenOutlined, { title: t("plan.overview.remarks.editIconTitle"), fontSize: "1rem", color: "black", className: "plan-overview-remarks-edit-icon", onClick: () => { var _a; return (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.showModal(); } }))] }), (planOverview === null || planOverview === void 0 ? void 0 : planOverview.remarks) && (_jsx(Paragraph, { size: "sm", className: "plan-overview-remarks-paragraph", children: planOverview.remarks }))] }), hasAdminRole && !bigScreen && (_jsx(PlanCreateRemarksModal, { modalRef: modalRef, currentRemarks: planOverview === null || planOverview === void 0 ? void 0 : planOverview.remarks }))] }));
};
