import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./SignOutButton.scss";
import { BoxArrowOutRightOutlined } from "@common-packages/react-icons";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { AvinorColor, breakpoints } from "@constants";
import { useSignOut } from "@auth/hooks/useSignOut";
import { useMediaQuery } from "@hooks";
export const styleSheet = {
    root: classNames("logout-button"),
    rootMobile: classNames("logout-button-mobile"),
    wrapper: classNames("logout-button-mobile__wrapper"),
    text: classNames("logout-button-mobile__wrapper__text"),
};
const SignOutButton = () => {
    const { t } = useTranslation();
    const { isAuthenticated, handleSignOut } = useSignOut();
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    return (_jsx(_Fragment, { children: isAuthenticated && (_jsx("button", { onClick: handleSignOut, className: isMobile ? styleSheet.rootMobile : styleSheet.root, children: isMobile ? (_jsxs("div", { className: styleSheet.wrapper, children: [_jsx(BoxArrowOutRightOutlined, { width: "1.5rem", height: "1.5rem", color: AvinorColor.Grey50 }), _jsxs("span", { className: styleSheet.text, children: [" ", t("common.signOut")] })] })) : (_jsxs("div", { className: "sign-out-wrapper-desktop", children: [_jsx(BoxArrowOutRightOutlined, { width: "1.5rem", height: "1.5rem", color: "white" }), t("common.signOut")] })) })) }));
};
export default SignOutButton;
