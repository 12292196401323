export const Airports = [
    {
        iata: "OSL",
        coordinates: {
            lat: 60.193917,
            lon: 11.100361,
            altitude: 208,
        },
    },
    {
        iata: "BGO",
        coordinates: {
            lat: 60.293386,
            lon: 5.218142,
            altitude: 50,
        },
    },
    {
        iata: "TRD",
        coordinates: {
            lat: 63.457556,
            lon: 10.92425,
            altitude: 17,
        },
    },
    {
        iata: "SVG",
        coordinates: {
            lat: 58.876778,
            lon: 5.637856,
            altitude: 9,
        },
    },
];
