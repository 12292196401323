import { jsx as _jsx } from "react/jsx-runtime";
import { Delay } from "@enums/delay";
import { Cell } from "@table-library/react-table-library";
import { SortToggleType, useSort } from "@table-library/react-table-library/sort";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ArrowDown, ArrowsUpDown, ArrowUp } from "@common-packages/react-icons";
import ErrorMessageWidget from "@components/ErrorMessageWidget/ErrorMessageWidget";
import Spinner from "@components/Spinner/Spinner";
import Table from "@components/Table/Table";
import { addIdToObj, renderCellWithBagFactorMarking } from "@components/Table/utils";
import { useFlightStageContext } from "@contexts/FlightStageContext";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
import { getBaggageEnroutePerFlightEstimate } from "../services";
const tableColumnHeaders = {
    flightID: { title: "Flight", weight: 1 },
    originIata: { title: "Origin", weight: 1 },
    etoa: { title: "ETOA", weight: 1 },
    pax: { title: "Pax", weight: 1 },
    transferPax: { title: "Transfer Pax", weight: 1.5 },
    actualBagsTotal: { title: "Total bags (actual)", weight: 1.5 },
    actualBagsTerminating: { title: "Bags til OSL (actual)", weight: 1.5 },
    estimatedBagsTotal: { title: "Total bags (estimert)", weight: 1.5 },
    estimatedBagsNonTransfer: { title: "Bags til OSL (estimert)", weight: 1.5 },
    belt: { title: "Bagasjebånd", weight: 1 },
    aircraftType: { title: "Flytype", weight: 1.2 },
    bagfactor: { title: "Bagfaktor", weight: 1.3 },
};
const headers = Object.keys(tableColumnHeaders);
const getTableColumnHeaders = () => {
    return [
        {
            headers: headers.map((header) => ({
                key: header,
                sortKey: header,
                content: tableColumnHeaders[header].title,
                cellProps: { weight: tableColumnHeaders[header].weight },
            })),
        },
    ];
};
const createSortFunctions = () => {
    const baseSortFunctions = {
        flightID: (array) => array.sort((a, b) => a.flightID.localeCompare(b.flightID)),
        originIata: (array) => array.sort((a, b) => a.originIata.localeCompare(b.originIata)),
        aircraftType: (array) => array.sort((a, b) => a.aircraftType.localeCompare(b.aircraftType)),
        etoa: (array) => array.sort((a, b) => a.etoa.localeCompare(b.etoa)),
        pax: (array) => array.sort((a, b) => a.pax - b.pax),
        transferPax: (array) => array.sort((a, b) => a.transferPax - b.transferPax),
        bagfactor: (array) => array.sort((a, b) => a.bagfactor - b.bagfactor),
        estimatedBagsTotal: (array) => array.sort((a, b) => a.estimatedBagsTotal - b.estimatedBagsTotal),
        estimatedBagsNonTransfer: (array) => array.sort((a, b) => a.estimatedBagsNonTransfer - b.estimatedBagsNonTransfer),
        belt: (array) => array.sort((a, b) => a.belt - b.belt),
        actualBagsTotal: (array) => array.sort((a, b) => a.actualBagsTotal - b.actualBagsTotal),
        actualBagsTerminating: (array) => array.sort((a, b) => a.actualBagsTerminating - b.actualBagsTerminating),
    };
    return baseSortFunctions;
};
const filterColumnsByHeaders = (data) => {
    const preparedTableHeaders = data.map((item) => {
        const extractedProperties = {};
        headers.forEach((header) => {
            if (item[header] !== undefined) {
                extractedProperties[header] = item[header];
            }
        });
        return extractedProperties;
    });
    return preparedTableHeaders;
};
export const BaggageEnroutePerFlightEstimateTable = ({ displayHoursLimit }) => {
    const { iata } = useIataContext();
    const { utc } = useUtcContext();
    const { t } = useTranslation();
    const { data: baggageEnroutePerFlightEstimate, isLoading } = useQuery({
        queryFn: () => getBaggageEnroutePerFlightEstimate({
            iata,
            hoursTo: displayHoursLimit,
            returnZulu: utc,
        }),
        queryKey: ["getBaggageEnroutePerFlightEstimate", { iata, displayHoursLimit, utc }],
        refetchInterval: Delay.ThirtySeconds,
    });
    const { stages: flightStage } = useFlightStageContext("baggageTable");
    const title = t("baggage.widgets.enroutePerFlightEstimateTable.title", { iata: iata });
    const subTitle = t("baggage.widgets.enroutePerFlightEstimateTable.description", { iata: iata });
    const getHiddenColumns = () => {
        return displayHoursLimit > 0 ? ["belt"] : [];
    };
    const filteredBaggageEnroutePerFlightEstimate = useMemo(() => {
        const selectedTravelTypeFilters = [
            ...(flightStage.includes("international") ? ["I"] : []),
            ...(flightStage.includes("domestic") ? ["D"] : []),
            ...(flightStage.includes("schengen") ? ["S"] : []),
        ];
        const baggageEnroutePerFlightEstimateFilteredByTravelType = (baggageEnroutePerFlightEstimate === null || baggageEnroutePerFlightEstimate === void 0 ? void 0 : baggageEnroutePerFlightEstimate.filter((flight) => selectedTravelTypeFilters.includes(flight.schIntDom))) ||
            [];
        const preparedTableColumns = filterColumnsByHeaders(baggageEnroutePerFlightEstimateFilteredByTravelType);
        return addIdToObj(preparedTableColumns);
    }, [flightStage, displayHoursLimit, utc, baggageEnroutePerFlightEstimate]);
    const sortOptions = useSort({ nodes: filteredBaggageEnroutePerFlightEstimate.nodes }, {}, {
        sortToggleType: SortToggleType.AlternateWithReset,
        sortIcon: {
            size: "1.5rem",
            iconDefault: _jsx(ArrowsUpDown, {}),
            iconUp: _jsx(ArrowUp, {}),
            iconDown: _jsx(ArrowDown, {}),
        },
        sortFns: createSortFunctions(),
    });
    if (isLoading)
        return _jsx(Spinner, {});
    if (!baggageEnroutePerFlightEstimate || (baggageEnroutePerFlightEstimate === null || baggageEnroutePerFlightEstimate === void 0 ? void 0 : baggageEnroutePerFlightEstimate.length) === 0) {
        return (_jsx(ErrorMessageWidget, { title: title, description: subTitle, underline: "header" }));
    }
    const customCellRenderers = {
        bagFactor: ({ row, cellIndex, value }) => renderCellWithBagFactorMarking(row, cellIndex, value),
        actualBagsTotal: ({ value }) => _jsx(Cell, { children: value ? value : "-" }),
        actualBagsTerminating: ({ value }) => _jsx(Cell, { children: value ? value : "-" }),
        estimatedBagsTotal: ({ value }) => _jsx(Cell, { children: value ? value : "-" }),
        estimatedBagsNonTransfer: ({ value }) => _jsx(Cell, { children: value ? value : "-" }),
    };
    const keysToExclude = ["bagfactorDefaulted", "schIntDom", "gate"];
    const dateColumns = ["etoa"];
    return (_jsx("div", { children: _jsx(Table, { data: filteredBaggageEnroutePerFlightEstimate, sort: sortOptions, dateColumns: dateColumns, excludeKeys: keysToExclude, cellRenderers: customCellRenderers, headerGroups: getTableColumnHeaders(), hiddenColumns: getHiddenColumns(), footerText: "* bruker standard bagfaktor", style: { minWidth: "1230px" }, maxHeight: "60vh", useDesignsystemetTheme: true }) }));
};
