import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, Heading, Modal, Paragraph } from "@digdir/designsystemet-react";
import { CheckmarkCircleFillIcon, FloppydiskIcon } from "@navikt/aksel-icons";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./DeIceSettingsModal.scss";
import AuthorizedButton from "@components/Buttons/AuthorizedButton/AuthorizedButton";
import { LeaveWithoutSavingModal } from "@components/Modals/LeaveWithoutSavingModal";
import Spinner from "@components/Spinner/Spinner";
import { Delay } from "@enums";
import { useDeIceHasChangesContext } from "@features/DeIce/contexts/DeIceHasChangesContext";
import { useDeIceWeatherContext } from "@features/DeIce/contexts/DeIceWeatherContext";
import { EWeatherCondition } from "@features/DeIce/enums";
import useDeIceCapacityData from "@features/DeIce/hooks/useDeIceCapacityData";
import { useDeIceDateSelector } from "@features/DeIce/hooks/useDeIceDateSelector";
import { useSubmitDeIceCapacityAndPadConfig } from "@features/DeIce/hooks/useSubmitDeIceCapacity";
import { getPadConfiguration } from "@features/DeIce/services";
import useRoles from "@auth/hooks/useRoles";
import { useIataContext } from "@contexts/IataContext";
import DeIceBays from "../DeIceBays/DeIceBays";
import DeIceCapacityScheduler from "../DeIceCapacityScheduler/DeIceCapacityScheduler";
import DeIcePads from "../DeIcePads/DeIcePads";
import DeIceTimeEstimator from "../DeIceTimeEstimator/DeIceTimeEstimator";
export const DeIceSettingsModal = ({ modalRef }) => {
    const from = useDeIceDateSelector();
    const { data: deIceHandlerCapacityData } = useDeIceCapacityData(from);
    const [deIceHandlerCapacity, setDeIceHandlerCapacity] = useState([]);
    const { iata } = useIataContext();
    const deIceDataDate = useDeIceDateSelector();
    const [modifiedPads, setModifiedPads] = useState([]);
    const { deIceHasChanges, setDeIceHasChanges } = useDeIceHasChangesContext();
    const { t } = useTranslation();
    const { operationStatus, submitDeIceCapacityAndPadConfig, resetMutation } = useSubmitDeIceCapacityAndPadConfig();
    const { setDeIceWeather, getWeatherTypeKey } = useDeIceWeatherContext();
    const { hasAdminRole } = useRoles();
    const { data: deIceConfiguration, isLoading } = useQuery({
        queryFn: () => getPadConfiguration({
            date: deIceDataDate,
            iata,
        }),
        refetchInterval: 0,
        staleTime: 0,
        refetchOnWindowFocus: false,
        gcTime: 0,
        queryKey: ["getPadConfiguration", { deIceDataDate, iata }],
    });
    const leaveWithoutSavingModalRef = useRef(null);
    useEffect(() => {
        if (deIceHandlerCapacityData === null || deIceHandlerCapacityData === void 0 ? void 0 : deIceHandlerCapacityData.handlerCapacity) {
            setDeIceHandlerCapacity(deIceHandlerCapacityData.handlerCapacity);
            mapAndSetWeather();
        }
    }, [deIceHandlerCapacityData]);
    useEffect(() => {
        if (deIceConfiguration) {
            setModifiedPads(deIceConfiguration.pads);
        }
    }, [deIceConfiguration]);
    useEffect(() => {
        if (operationStatus == "success") {
            setTimeout(() => {
                var _a;
                (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.close();
                resetMutation();
            }, Delay.SaveSuccessDelay);
        }
    }, [operationStatus]);
    const mapAndSetWeather = () => {
        var _a;
        if (deIceHandlerCapacityData && ((_a = deIceHandlerCapacityData === null || deIceHandlerCapacityData === void 0 ? void 0 : deIceHandlerCapacityData.handlerCapacity) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            const weatherType = deIceHandlerCapacityData.handlerCapacity[0]
                .weatherType;
            // Map the weatherType string to the corresponding enum value
            const mappedWeatherCondition = EWeatherCondition[weatherType];
            setDeIceWeather(mappedWeatherCondition);
        }
    };
    const saveDeIceCapacityAndPadConfig = () => {
        const selectedWeatherTypeKey = getWeatherTypeKey();
        if (selectedWeatherTypeKey) {
            deIceHandlerCapacity.forEach((x) => (x.weatherType = selectedWeatherTypeKey));
        }
        else {
            console.error("Couldn't find selected weather");
        }
        submitDeIceCapacityAndPadConfig(deIceHandlerCapacity, modifiedPads);
        setDeIceHasChanges(false);
    };
    const closeModal = () => {
        var _a, _b, _c;
        if (deIceHasChanges) {
            (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.close();
            (_b = leaveWithoutSavingModalRef.current) === null || _b === void 0 ? void 0 : _b.showModal();
        }
        else {
            (_c = modalRef.current) === null || _c === void 0 ? void 0 : _c.close();
        }
    };
    const resetData = () => {
        setModifiedPads((deIceConfiguration === null || deIceConfiguration === void 0 ? void 0 : deIceConfiguration.pads) || []);
        setDeIceHandlerCapacity((deIceHandlerCapacityData === null || deIceHandlerCapacityData === void 0 ? void 0 : deIceHandlerCapacityData.handlerCapacity) || []);
        setDeIceHasChanges(false);
        mapAndSetWeather();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Modal.Root, { children: _jsxs(Modal.Dialog, { ref: modalRef, onInteractOutside: () => closeModal(), style: {
                        minWidth: "0",
                        width: "95vw",
                        maxWidth: "1200px",
                    }, children: [_jsx(Modal.Header, { children: t("deice.capacity.controlPanel.settings") }), _jsx(Modal.Content, { children: _jsxs("div", { style: {
                                    minHeight: operationStatus != "success" ? "625px" : "auto",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }, children: [operationStatus == "error" && (_jsxs(Alert, { severity: "danger", size: "sm", children: [_jsx(Heading, { level: 2, size: "2xs", spacing: true, children: "Det har skjedd en feil" }), _jsx(Paragraph, { size: "sm", children: "Det oppsto en feil under lagringen av konfigurasjonen. Vennligst pr\u00F8v igjen senere." })] })), (operationStatus == "idle" || operationStatus == "error") && (_jsxs("div", { className: "deice-control-panel-content", children: [_jsxs("div", { className: "deice-control-panel-top-row", children: [_jsx("div", { className: "deice-control-panel-top-row-col1", children: _jsx(DeIcePads, { modifiedPads: modifiedPads, setModifiedPads: setModifiedPads, isLoading: isLoading }) }), _jsx("div", { className: "deice-control-panel-top-row-col2", children: _jsx(DeIceBays, { modifiedPads: modifiedPads, setModifiedPads: setModifiedPads, isLoading: isLoading }) })] }), _jsxs("div", { className: "deice-control-panel-bottom-row", children: [_jsx(DeIceCapacityScheduler, { deIceHandlerCapacity: deIceHandlerCapacity, setDeIceHandlerCapacity: setDeIceHandlerCapacity }), _jsx(DeIceTimeEstimator, {})] })] })), operationStatus == "success" && (_jsxs("div", { style: {
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "10px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }, children: [_jsx("div", { style: { marginTop: "20px" }, children: _jsx(CheckmarkCircleFillIcon, { title: "a11y-title", fontSize: "3.5rem", color: "#2a9d5a" }) }), _jsx("h2", { style: { color: "var(--avinor-green-400)" }, children: t("saved") }), _jsx("div", { style: { marginBottom: "30px" }, children: t("deice.capacity.controlPanel.modal.savedMessage") })] })), (isLoading || operationStatus === "pending") && _jsx(Spinner, {})] }) }), _jsx(Modal.Footer, { children: _jsxs("div", { style: { display: "flex", justifyContent: "flex-end", width: "100%", gap: "10px" }, children: [operationStatus != "pending" && (_jsx(Button, { color: "accent", size: "sm", variant: "tertiary", onClick: () => closeModal(), children: t("cancel") })), (operationStatus == "idle" || operationStatus == "error") && (_jsxs(AuthorizedButton, { hasRole: hasAdminRole, variant: "primary", size: "sm", color: "accent", style: { minWidth: "100px" }, onClick: () => saveDeIceCapacityAndPadConfig(), children: [_jsx(FloppydiskIcon, { "aria-hidden": true, fontSize: "1.5rem" }), t("save")] }))] }) })] }) }), _jsx(LeaveWithoutSavingModal, { onHasChangesModalRef: leaveWithoutSavingModalRef, onConfirm: resetData, onCancel: () => { var _a; return (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.showModal(); } })] }));
};
