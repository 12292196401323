import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormatPattern } from "@enums/format";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { Home, ZoomArea, Move, CirclePlus, CircleMinus } from "@avinor/react-icons";
import { formatDate } from "@utils/DateUtils";
import PaxPredictionDirectionFilter, { PaxPredictionDirection, } from "@components/Filters/PaxPredictionDirectionFilter/PaxPredictionDirectionFilter";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Spinner from "@components/Spinner/Spinner";
import { AvinorColor } from "@constants";
import { usePlanDayPickerContext } from "@features/Plan/contexts/PlanDayPickerContext";
import { defaultApexChartOptions } from "@charts/apexcharts/defaultApexChartOptions";
import Tooltip from "@charts/shared/components/Tooltip/Tooltip";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
import { getPaxPredictionGraphData } from "../services";
import { PaxPredictionTotalsTable } from "./PaxPredictionTotalsTable";
export const PaxPredictionGraph = () => {
    var _a;
    const { iata } = useIataContext();
    const [filter, setFilter] = useState(PaxPredictionDirection.Arrival);
    const { t } = useTranslation();
    const { utc } = useUtcContext();
    const { planDateFormatted } = usePlanDayPickerContext();
    const { data: paxPredictionGraphData, isLoading } = useQuery({
        queryFn: () => getPaxPredictionGraphData({ iata, date: planDateFormatted, direction: filter }),
        queryKey: ["getPaxPredictionGraphData", { iata, filter, planDateFormatted }],
    });
    if (isLoading)
        return _jsx(Spinner, {});
    if (!paxPredictionGraphData || !((_a = paxPredictionGraphData === null || paxPredictionGraphData === void 0 ? void 0 : paxPredictionGraphData.intervals) === null || _a === void 0 ? void 0 : _a.some((interval) => interval.total > 0)))
        return _jsx(NoDataAvailable, {});
    const series = [
        {
            name: "Non-Schengen",
            data: paxPredictionGraphData.intervals.map((interval) => interval.nonSchengen),
            stroke: AvinorColor.ChartMintGreen300,
        },
        {
            name: "Schengen",
            data: paxPredictionGraphData.intervals.map((interval) => interval.schengen),
            stroke: AvinorColor.ChartVioletLight600,
        },
        {
            name: "Domestic",
            data: paxPredictionGraphData.intervals.map((interval) => interval.domestic),
            stroke: AvinorColor.ChartVioletLight400,
        },
    ];
    const xaxisCategories = paxPredictionGraphData.intervals.map((interval) => interval.to);
    const sharedStyles = {
        colors: AvinorColor.Grey900,
        fontWeight: "normal",
    };
    const options = {
        colors: series.map((s) => s.stroke),
        chart: {
            toolbar: {
                tools: {
                    download: false,
                    zoom: ReactDOMServer.renderToStaticMarkup(_jsx(ZoomArea, {})),
                    zoomin: ReactDOMServer.renderToStaticMarkup(_jsx(CirclePlus, {})),
                    zoomout: ReactDOMServer.renderToStaticMarkup(_jsx(CircleMinus, {})),
                    pan: ReactDOMServer.renderToStaticMarkup(_jsx(Move, {})),
                    reset: ReactDOMServer.renderToStaticMarkup(_jsx(Home, {})),
                },
            },
            fontFamily: "Roboto, sans-serif",
            type: "area",
            stacked: true,
            height: 450,
            zoom: {
                type: "x",
                allowMouseWheelZoom: false,
            },
            animations: {
                enabled: true,
                easing: "easeinout",
                speed: 600,
                animateGradually: {
                    enabled: false,
                    delay: 0,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 600,
                },
            },
        },
        grid: {
            show: true,
            strokeDashArray: 4,
            xaxis: {
                lines: {
                    show: true,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 0,
            curve: "smooth",
        },
        fill: {
            type: "solid",
        },
        xaxis: {
            categories: xaxisCategories,
            type: "datetime",
            title: {
                text: t("time"),
                style: sharedStyles,
                offsetY: 5,
            },
            labels: {
                datetimeUTC: utc,
            },
        },
        yaxis: {
            title: {
                text: t("Predicted number of pax"),
                style: sharedStyles,
            },
            labels: {
                formatter: function (value) {
                    return value.toString();
                },
            },
        },
        tooltip: {
            fixed: {
                enabled: false,
            },
            custom: function ({ dataPointIndex }) {
                var _a, _b, _c, _d;
                const interval = paxPredictionGraphData.intervals[dataPointIndex];
                const tooltipElement = (_jsxs(Tooltip, { children: [_jsxs(Tooltip.Title, { children: [formatDate(utc, interval.from, FormatPattern.HH_mm), " -", " ", formatDate(utc, interval.to, FormatPattern.HH_mm)] }), _jsxs(Tooltip.List, { children: [_jsx(Tooltip.ListItem, { style: { type: "bullet", bgColor: AvinorColor.VioletLight100 }, content: { value: "Total" }, status: { value: (_a = interval.total) === null || _a === void 0 ? void 0 : _a.toString() } }, "total"), _jsx(Tooltip.ListItem, { style: { type: "bullet", bgColor: AvinorColor.ChartVioletLight400 }, content: { value: "Domestic" }, status: { value: (_b = interval.domestic) === null || _b === void 0 ? void 0 : _b.toString() } }, "domestic"), _jsx(Tooltip.ListItem, { style: { type: "bullet", bgColor: AvinorColor.ChartVioletLight600 }, content: { value: "Schengen" }, status: { value: (_c = interval.schengen) === null || _c === void 0 ? void 0 : _c.toString() } }, "schengen"), _jsx(Tooltip.ListItem, { style: { type: "bullet", bgColor: AvinorColor.ChartMintGreen300 }, content: { value: "Non-Schengen" }, status: { value: (_d = interval.nonSchengen) === null || _d === void 0 ? void 0 : _d.toString() } }, "nonSchengen")] })] }));
                return ReactDOMServer.renderToStaticMarkup(tooltipElement);
            },
        },
        legend: defaultApexChartOptions.legend,
        annotations: defaultApexChartOptions.annotations,
        responsive: [
            {
                breakpoint: 768,
                options: {
                    xaxis: {
                        title: {
                            text: undefined,
                        },
                    },
                    yaxis: {
                        title: {
                            text: undefined,
                        },
                    },
                },
            },
        ],
    };
    const total = paxPredictionGraphData.intervals.reduce((sum, x) => sum + x.total, 0);
    const totalDomestic = paxPredictionGraphData.intervals.reduce((sum, x) => sum + x.domestic, 0);
    const totalNonSchengen = paxPredictionGraphData.intervals.reduce((sum, x) => sum + x.nonSchengen, 0);
    const totalSchengen = paxPredictionGraphData.intervals.reduce((sum, x) => sum + x.schengen, 0);
    return (_jsxs("div", { children: [_jsx("div", { style: { display: "flex", justifyContent: "flex-end", marginBottom: "10px" }, children: _jsx(PaxPredictionDirectionFilter, { filter: filter, setFilter: setFilter }) }), _jsx(PaxPredictionTotalsTable, { date: planDateFormatted, total: total, totalDomestic: totalDomestic, totalNonSchengen: totalNonSchengen, totalSchengen: totalSchengen, isLoading: isLoading }), _jsx(ReactApexChart, { options: options, series: series, type: "area", height: 450 }, filter)] }));
};
PaxPredictionGraph.displayName = "PaxPredictionGraph";
