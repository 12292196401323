import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Spinner from "@components/Spinner/Spinner";
import { getWeatherData } from "@features/Plan/services";
import "./PlanOverviewWeatherTable.scss";
const weatherIconUrl = (symbol_code) => `/weather-icons/${symbol_code}.svg`;
const windDirectionStyle = (direction) => ({
    transform: `rotate(${direction}deg)`,
});
const getWeatherRow = (temperature, precipitation, windSpeed, windSpeedGusts, windDirection, weatherSymbol, periodStart, periodEnd) => {
    const isDaytime = periodStart >= 6 && periodStart < 18;
    const timeLabel = `${periodStart < 10 ? 0 : ""}${periodStart} – ${periodEnd < 10 ? 0 : ""}${periodEnd}`;
    return (_jsxs("tr", { children: [_jsx("th", { "data-align": "left", className: "plan-weather-table__time", children: timeLabel }), _jsx("td", { "data-align": "center", children: _jsx("div", { className: "plan-weather-table__weather-symbol-container", children: weatherSymbol && (_jsx("img", { className: "plan-weather-table__weather-symbol", src: weatherIconUrl(weatherSymbol), alt: isDaytime ? "day weather" : "night weather" })) }) }), _jsx("td", { "data-align": "right", className: "plan-weather-table__temperature", children: _jsxs("span", { className: "plan-weather-table__temperature__value", "data-is-warm": temperature > 0, children: [Math.round(temperature), "\u00B0"] }) }), _jsx("td", { "data-align": "right", className: "plan-weather-table__precipitation", children: `${precipitation} mm` }), _jsx("td", { "data-align": "right", className: "plan-weather-table__wind", children: _jsxs("span", { className: "plan-weather-table__wind__value", "data-has-wind-arrow": "true", children: [Math.round(windSpeed), " (", Math.round(windSpeedGusts), ")", _jsx("span", { className: "plan-weather-table__wind__direction", style: windDirectionStyle(windDirection), children: _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24", children: _jsx("path", { fill: "currentColor", fillRule: "evenodd", d: "M11.53 3l-.941 12.857L7 15l5.001 6L17 15l-3.587.857L12.471 3h-.941z", clipRule: "evenodd" }) }) })] }) })] }, timeLabel));
};
const PlanOverviewWeatherTable = ({ iata }) => {
    const { data: weatherData, isLoading: weatherLoading, error: weatherError, } = useQuery({
        queryFn: () => getWeatherData(iata),
        queryKey: ["getWeatherData", iata],
    });
    const currentTime = new Date();
    const nextHourUtc = currentTime.getUTCHours() + 1;
    let nextHour = currentTime.getHours();
    if (currentTime.getMinutes() >= 30) {
        nextHour += 1;
    }
    nextHour = nextHour % 24; // Ensure it wraps around at midnight
    // Find the timeseries object that matches the current hour
    const currentWeather = weatherData === null || weatherData === void 0 ? void 0 : weatherData.properties.timeseries.find((timeserie) => {
        const timeserieHour = new Date(timeserie.time).getUTCHours();
        return timeserieHour === nextHourUtc;
    });
    if (weatherLoading) {
        return _jsx(Spinner, {});
    }
    if (weatherError || !weatherData || !currentWeather) {
        return _jsx(NoDataAvailable, {});
    }
    const maxHour = 24;
    const periods = [
        { start: nextHour, end: Math.ceil((nextHour + 1) / 6) * 6 },
        { start: (Math.ceil((nextHour + 1) / 6) * 6) % maxHour },
        { start: (Math.ceil((nextHour + 1) / 6) * 6 + 6) % maxHour },
        { start: (Math.ceil((nextHour + 1) / 6) * 6 + 12) % maxHour },
    ];
    const weatherRows = [];
    periods.forEach((x) => {
        var _a, _b, _c, _d;
        const timeserie = weatherData === null || weatherData === void 0 ? void 0 : weatherData.properties.timeseries.find((timeserie) => {
            const timeserieHour = new Date(timeserie.time).getHours();
            return timeserieHour === x.start;
        });
        if (timeserie) {
            const temperature = timeserie.data.instant.details.air_temperature;
            const windSpeed = timeserie.data.instant.details.wind_speed;
            const windSpeedGusts = timeserie.data.instant.details.wind_speed_of_gust;
            const windDirection = timeserie.data.instant.details.wind_from_direction;
            const weatherSymbol = (_b = (_a = timeserie.data.next_6_hours) === null || _a === void 0 ? void 0 : _a.summary.symbol_code) !== null && _b !== void 0 ? _b : "";
            let precipitation = (_d = (_c = timeserie.data.next_6_hours) === null || _c === void 0 ? void 0 : _c.details.precipitation_amount) !== null && _d !== void 0 ? _d : 0;
            if (x.end) {
                precipitation = 0; // Reset precipitation to sum over the range.
                weatherData === null || weatherData === void 0 ? void 0 : weatherData.properties.timeseries.forEach((serie) => {
                    var _a, _b;
                    const serieHour = new Date(serie.time).getHours();
                    const serieDate = new Date(serie.time).getDate();
                    // Check if the hour falls within the period and it's the same date.
                    if (serieHour >= x.start && serieHour < x.end && serieDate === new Date(timeserie.time).getDate()) {
                        precipitation += (_b = (_a = serie.data.next_1_hours) === null || _a === void 0 ? void 0 : _a.details.precipitation_amount) !== null && _b !== void 0 ? _b : 0;
                    }
                });
                // Round the precipitation to 2 decimal places
                precipitation = Math.round(precipitation * 100) / 100;
            }
            const row = getWeatherRow(temperature, precipitation, windSpeed, windSpeedGusts, windDirection, weatherSymbol, x.start, x.end ? x.end : (x.start + 6) % 24);
            weatherRows.push(row);
        }
    });
    return (_jsxs("table", { className: "plan-weather-table", children: [_jsx("caption", { className: "plan-weather-table-nrk-sr", children: "V\u00E6rvarsel for det neste d\u00F8gnet" }), _jsx("thead", { className: "plan-weather-table-nrk-sr", children: _jsxs("tr", { children: [_jsx("th", { children: "Tid" }), _jsx("th", { children: "V\u00E6rsymbol" }), _jsx("th", { children: "Temperatur" }), _jsx("th", { children: "Nedb\u00F8r" }), _jsx("th", { children: "Vind" })] }) }), _jsx("tbody", { className: "plan-weather-table__body", children: weatherRows })] }));
};
export default PlanOverviewWeatherTable;
