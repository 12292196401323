import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./PlanCreateRemarksModal.scss";
import { Alert, Button, Heading, Modal, Paragraph, Textarea } from "@digdir/designsystemet-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePlanDayPickerContext } from "@features/Plan/contexts/PlanDayPickerContext";
import { saveRemarks } from "@features/Plan/services";
import { useIataContext } from "@contexts/IataContext";
export const PlanCreateRemarksModal = ({ modalRef, currentRemarks }) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [newRemarks, setNewRemarks] = useState(currentRemarks || "");
    const [savedError, setSavedError] = useState(false);
    const { iata: iataCode } = useIataContext();
    const { planDateFormatted, dateIsPast } = usePlanDayPickerContext();
    const defaultMessage = dateIsPast ? "Oppsummering: " : "Kjente utfordringer: ";
    const saveRemarksMutation = useMutation({
        mutationFn: ({ remarks }) => saveRemarks({
            body: {
                iata: iataCode,
                date: planDateFormatted,
                remarks: remarks,
            },
        }),
        onSuccess: () => {
            var _a;
            queryClient.invalidateQueries({ queryKey: ["getPlanOverview"] });
            setNewRemarks("");
            (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.close();
        },
        onError: (error) => {
            setSavedError(true);
            console.error(`saveRemarks() ERROR \n${error}`);
        },
    });
    useEffect(() => {
        setSavedError(false);
        setNewRemarks(currentRemarks || defaultMessage);
    }, [currentRemarks]);
    const handleRemarksChange = (e) => {
        setNewRemarks(e.target.value);
    };
    const onConfirm = () => {
        setSavedError(false);
        saveRemarksMutation.mutate({ remarks: newRemarks });
    };
    const onCancel = () => {
        var _a;
        setNewRemarks(currentRemarks || defaultMessage);
        setSavedError(false);
        (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.close();
    };
    return (_jsx("div", { className: "plan-create-remarks-modal", children: _jsx(Modal.Root, { children: _jsxs(Modal.Dialog, { ref: modalRef, children: [_jsxs(Modal.Header, { children: [dateIsPast
                                ? t("plan.overview.remarks.title") + " " + planDateFormatted
                                : t("plan.overview.remarks.titleYesterday") + " " + planDateFormatted, _jsx(Paragraph, { size: "sm", children: t("plan.create.remarks.subtitle") })] }), _jsx(Modal.Content, { children: _jsxs("div", { children: [savedError && (_jsx("div", { style: { marginBottom: "0.625rem" }, children: _jsxs(Alert, { severity: "danger", size: "sm", children: [_jsx(Heading, { level: 2, size: "2xs", spacing: true, children: t("plan.create.remarks.errorTitle") }), _jsx(Paragraph, { size: "sm", children: t("plan.create.remarks.errorMessage") })] }) })), _jsx(Textarea, { cols: 40, rows: 6, description: "", error: "", label: t("plan.create.remarks.textareaLabel"), size: "sm", value: newRemarks, onChange: handleRemarksChange })] }) }), _jsx(Modal.Footer, { children: _jsxs("div", { style: { display: "flex", justifyContent: "flex-end", width: "100%", gap: "0.625rem" }, children: [_jsx(Button, { color: "accent", size: "sm", variant: "tertiary", onClick: () => onCancel(), disabled: saveRemarksMutation.isPending, children: t("cancel") }), _jsx(Button, { color: "accent", size: "sm", variant: "primary", onClick: onConfirm, disabled: saveRemarksMutation.isPending, children: saveRemarksMutation.isPending ? t("saving") : t("save") })] }) })] }) }) }));
};
