import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Progress from "@components/Progress/Progress";
import Spinner from "@components/Spinner/Spinner";
import useDelayCodesData from "../hooks/useDelayCodesData";
import { useDelayNumbers } from "../hooks/useDelayNumbers";
import { findTopN } from "../utils";
import "./DelayCausesProgressBars.scss";
export const DelayCausesProgressBars = () => {
    var _a;
    const { data, isLoading } = useDelayCodesData();
    const { delayGreaterThanOrEqualTo15min, isLoading: isLoadingTotal } = useDelayNumbers();
    const top3DelayCodes = findTopN(((_a = data === null || data === void 0 ? void 0 : data.delayCodes) === null || _a === void 0 ? void 0 : _a.filter((x) => x.greaterthan15min > 0)) || [], 3);
    if (isLoading || isLoadingTotal)
        return _jsx(Spinner, {});
    const delayNumbersData = top3DelayCodes.map((delayCode) => ({
        name: delayCode.delaycode,
        value: delayCode.greaterthan15min,
        percentage: (delayCode.greaterthan15min / delayGreaterThanOrEqualTo15min) * 100,
    }));
    return (_jsx("div", { className: "delay-causes-progress", children: delayNumbersData
            .sort((a, b) => b.percentage - a.percentage)
            .map((delay, index) => (_jsxs("div", { className: "delay-causes-progress-container", children: [_jsx("div", { className: "delay-causes-progress-bar", children: _jsx(Progress, { completedPercentage: delay.percentage, textOnBar: `${delay.value}`, variant: "plain", size: "medium", percentageVisibility: "hidden" }) }), _jsx("div", { className: "delay-causes-progress-code", children: _jsx("span", { children: delay.name }) })] }, index))) }));
};
