import { useQuery } from "@tanstack/react-query";
import { useIataContext } from "@contexts/IataContext";
import { EDeIceHandler } from "../enums";
import { getDeIceCapacity } from "../services";
const useDeIceCapacityData = (date) => {
    const { iata } = useIataContext();
    const { data, error } = useQuery({
        queryFn: () => getDeIceCapacity({
            params: {
                date: date,
                iata,
            },
        }),
        queryKey: ["getDeIceCapacity", { iata, date }],
    });
    if (data && Array.isArray(data.handlerCapacity) && data.handlerCapacity.length === 0) {
        data.handlerCapacity = [
            {
                handler: EDeIceHandler.ALL,
                morningTotalCars: 0,
                morningCarsPerPlane: 0,
                afternoonTotalCars: 0,
                afternoonCarsPerPlane: 0,
                weatherType: "SN",
            },
            {
                handler: EDeIceHandler.MENZIES_SGH,
                morningTotalCars: 0,
                morningCarsPerPlane: 0,
                afternoonTotalCars: 0,
                afternoonCarsPerPlane: 0,
                weatherType: "SN",
            },
            {
                handler: EDeIceHandler.SGH,
                morningTotalCars: 0,
                morningCarsPerPlane: 0,
                afternoonTotalCars: 0,
                afternoonCarsPerPlane: 0,
                weatherType: "SN",
            },
            {
                handler: EDeIceHandler.WGH,
                morningTotalCars: 0,
                morningCarsPerPlane: 0,
                afternoonTotalCars: 0,
                afternoonCarsPerPlane: 0,
                weatherType: "SN",
            },
            {
                handler: EDeIceHandler.MENZIES,
                morningTotalCars: 0,
                morningCarsPerPlane: 0,
                afternoonTotalCars: 0,
                afternoonCarsPerPlane: 0,
                weatherType: "SN",
            },
        ];
    }
    return { data, error, iata };
};
export default useDeIceCapacityData;
