import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMsal } from "@azure/msal-react";
import { Button } from "@digdir/designsystemet-react";
import { EnterIcon, PersonPlusIcon } from "@navikt/aksel-icons";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { InfoICircleOutlined } from "@avinor/react-icons";
import "./SignInCard.scss";
import AvinorLogoSmall from "@components/AvinorLogo/AvinorLogoSmall";
import { AvinorColor } from "@constants";
import { SignInHelpModal } from "../SignInHelpModal/SignInHelpModal";
const SignInCard = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();
    const signInHelpModalRef = useRef(null);
    const handleSigninClick = () => {
        instance.loginRedirect();
    };
    const handleAvinorTilgangClick = () => {
        window.open("https://avinortest.service-now.com/sp?id=sc_cat_item&sys_id=12aef6ac33ab8210ec5be5d13d5c7bb5&sysparm_category=01f70bfc1b4f65104432edf3b24bcba3", "_blank", "noopener,noreferrer" // Adds security headers to prevent the new page from accessing window.opener
        );
    };
    const handleExternBrukerTilgangClick = () => {
        window.open("https://myaccess.microsoft.com/@avinor.onmicrosoft.com#/access-packages", "_blank", "noopener,noreferrer" // Adds security headers to prevent the new page from accessing window.opener
        );
    };
    const openHelpModal = () => {
        var _a;
        (_a = signInHelpModalRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
    };
    return (_jsxs("div", { className: "signin-wrapper", children: [_jsx("div", { className: "signin-card-wrapper", children: _jsxs("section", { className: "signin-card", children: [_jsxs("div", { className: "signin-card-header", children: [_jsx("h1", { children: t("signin.title") }), _jsxs(Button, { onClick: openHelpModal, variant: "tertiary", children: [_jsx("span", { children: t("signin.helpButton") }), _jsx(InfoICircleOutlined, { color: AvinorColor.VioletPrimary })] })] }), _jsx("p", { className: "signin-card-description", children: t("signin.description") }), _jsx("div", { className: "signin-card-button-signin", children: _jsxs(Button, { onClick: handleSigninClick, children: [_jsx(EnterIcon, { title: "a11y-title", fontSize: "1.5rem" }), t("signin.loginButton")] }) }), _jsxs(_Fragment, { children: [_jsxs("div", { className: "divider", children: [_jsx("span", { className: "divider-line" }), _jsx("span", { className: "divider-text", children: t("signin.orText") }), _jsx("span", { className: "divider-line" })] }), _jsxs("div", { className: "signin-card-buttons-requestaccess", children: [_jsxs(Button, { onClick: handleAvinorTilgangClick, variant: "secondary", disabled: true, children: [_jsx(AvinorLogoSmall, {}), t("signin.avinorAccessButton")] }), _jsxs(Button, { onClick: handleExternBrukerTilgangClick, variant: "secondary", children: [_jsx(PersonPlusIcon, { title: "a11y-title", fontSize: "1.5rem" }), t("signin.externalAccessButton")] })] })] })] }) }), _jsx(SignInHelpModal, { signInHelpModalRef: signInHelpModalRef })] }));
};
export default SignInCard;
