import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { orderBy } from "lodash";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Spinner from "@components/Spinner/Spinner";
import { AvinorColor, breakpoints } from "@constants";
import useDelayCodesData from "@features/OnTimePerformance/hooks/useDelayCodesData";
import { iataDelayCodes, isUnknownDelayCode } from "@features/OnTimePerformance/utils";
import { defaultApexChartOptions } from "@charts/apexcharts/defaultApexChartOptions";
import { handleFilteringOnSelectedAirlineCodes, handleFilteringOnSelectedDelayCodes, handleFilteringOnSelectedDelayCodesAndAirlineCodes, } from "@charts/apexcharts/OnTimePerformance/DelayCodes/utils";
import ToolTip from "@charts/shared/components/Tooltip/Tooltip";
import { useMediaQuery } from "@hooks";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useDelayCodeMetaDataContext } from "@contexts/DelayCodeMetaDataContext";
import { useSelectOptionContext } from "@contexts/SelectOptionsContext";
const calculateTotalDelays = (airlineBreakdown, delayType) => {
    return airlineBreakdown.reduce((acc, airline) => acc + airline[delayType], 0);
};
const BarChart = () => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const { options: selectedDelayCodes } = useSelectOptionContext("delayCodes");
    const { options: selectedAirlines } = useSelectOptionContext("delayCodesAirlines");
    const { data: delayCodes, isLoading } = useDelayCodesData();
    const is4kScreen = useMediaQuery(`(min-width: ${breakpoints.width4k})`);
    const [chartAnnotationPosition, setChartAnnotationPosition] = useState([true, true]);
    const { picker: { from }, } = useDatePickerContext();
    const { setDelayCodeMetaData: setSelectedDelayCodeMetaData } = useDelayCodeMetaDataContext(from);
    if (isLoading)
        return _jsx(Spinner, {});
    if (!delayCodes || ((_b = (_a = delayCodes === null || delayCodes === void 0 ? void 0 : delayCodes.delayCodes) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) === 0)
        return _jsx(NoDataAvailable, {});
    let filteredDelayCodes = [...((_c = delayCodes === null || delayCodes === void 0 ? void 0 : delayCodes.delayCodes) !== null && _c !== void 0 ? _c : [])];
    if (selectedDelayCodes && (selectedDelayCodes === null || selectedDelayCodes === void 0 ? void 0 : selectedDelayCodes.length) > 0) {
        filteredDelayCodes = handleFilteringOnSelectedDelayCodes(delayCodes, selectedDelayCodes);
    }
    if (selectedAirlines && (selectedAirlines === null || selectedAirlines === void 0 ? void 0 : selectedAirlines.length) > 0) {
        filteredDelayCodes = handleFilteringOnSelectedAirlineCodes(delayCodes, selectedAirlines);
    }
    if (selectedDelayCodes && selectedAirlines && (selectedDelayCodes === null || selectedDelayCodes === void 0 ? void 0 : selectedDelayCodes.length) > 0 && (selectedAirlines === null || selectedAirlines === void 0 ? void 0 : selectedAirlines.length) > 0) {
        filteredDelayCodes = handleFilteringOnSelectedDelayCodesAndAirlineCodes(delayCodes, selectedDelayCodes, selectedAirlines);
    }
    const delayCodesWithTotals = filteredDelayCodes.map((delayCode) => {
        const greaterThan3Min = calculateTotalDelays(delayCode.airlineBreakdown, "greaterthan3min");
        const greaterThan15Min = calculateTotalDelays(delayCode.airlineBreakdown, "greaterthan15min");
        return Object.assign(Object.assign({}, delayCode), { greaterThan3Min,
            greaterThan15Min });
    });
    const sortedDelayCodes = orderBy(delayCodesWithTotals, ["greaterThan15Min", "greaterThan3Min"], ["desc", "desc"]);
    const series = [
        {
            name: t("charts.onTimePerformance.bar.delayCodes.series.greaterthan3min"),
            data: sortedDelayCodes.map((delayCode) => calculateTotalDelays(delayCode.airlineBreakdown, "greaterthan3min")),
        },
        {
            name: t("charts.onTimePerformance.bar.delayCodes.series.greaterthan15min"),
            data: sortedDelayCodes.map((delayCode) => calculateTotalDelays(delayCode.airlineBreakdown, "greaterthan15min")),
        },
    ];
    const xaxisCategories = sortedDelayCodes.map((delayCode) => delayCode.delaycode);
    const sharedStyles = {
        colors: AvinorColor.Grey900,
        fontSize: "0.875rem",
        fontWeight: "normal",
    };
    const options = {
        colors: [AvinorColor.MintGreenPrimaryLight, AvinorColor.VioletPrimaryLight, AvinorColor.Grey300],
        chart: {
            toolbar: {
                tools: {
                    download: false,
                },
            },
            fontFamily: "Roboto, sans-serif",
            type: "bar",
            stacked: true,
            events: {
                dataPointSelection: function (_, __, { dataPointIndex }) {
                    setSelectedDelayCodeMetaData({
                        airlineBreakdown: sortedDelayCodes[dataPointIndex].airlineBreakdown,
                        delayCode: sortedDelayCodes[dataPointIndex].delaycode,
                    });
                },
                legendClick: function (_, seriesIndex) {
                    setChartAnnotationPosition((prev) => prev.map((visible, index) => (index === seriesIndex ? !visible : visible)));
                },
            },
        },
        annotations: {
            points: sortedDelayCodes.map((delayCode) => {
                const greaterThan3Min = calculateTotalDelays(delayCode.airlineBreakdown, "greaterthan3min");
                const greaterThan15Min = calculateTotalDelays(delayCode.airlineBreakdown, "greaterthan15min");
                const calculateTotalDelaysBasedOnLegendSelection = () => {
                    const totalDelays = (chartAnnotationPosition[0] ? greaterThan3Min : 0) +
                        (chartAnnotationPosition[1] ? greaterThan15Min : 0);
                    return totalDelays;
                };
                const totalDelays = calculateTotalDelaysBasedOnLegendSelection();
                if (totalDelays === 0)
                    return {};
                return {
                    x: delayCode.delaycode,
                    y: totalDelays,
                    label: {
                        style: {
                            fontSize: "0.875rem",
                        },
                        orientation: "horizontal",
                        text: totalDelays.toString(),
                        offsetY: -20,
                    },
                };
            }),
        },
        dataLabels: {
            style: {
                colors: [AvinorColor.Grey800, AvinorColor.White, AvinorColor.White],
            },
        },
        grid: defaultApexChartOptions.grid,
        xaxis: {
            categories: xaxisCategories,
            title: {
                text: t("charts.onTimePerformance.bar.delayCodes.xaxisTitle.delayCode"),
                style: sharedStyles,
            },
            labels: {
                style: sharedStyles,
            },
        },
        yaxis: {
            title: {
                text: t("charts.onTimePerformance.bar.delayCodes.yaxisTitle"),
                style: sharedStyles,
            },
            labels: {
                formatter: function (value) {
                    if (typeof value === "string")
                        return value;
                    return Math.round(value).toString();
                },
                style: sharedStyles,
            },
        },
        legend: defaultApexChartOptions.legend,
        tooltip: {
            fixed: {
                enabled: true,
                position: "topRight",
            },
            custom: function ({ dataPointIndex }) {
                const { delaycode, greaterthan15min, greaterthan3min, totalDelays } = sortedDelayCodes[dataPointIndex];
                const listItems = {
                    greaterthan15min,
                    greaterthan3min,
                    totalDelays,
                };
                const mapKeyToColor = {
                    greaterthan15min: AvinorColor.ChartVioletLight400,
                    greaterthan3min: AvinorColor.ChartMintGreen300,
                    totalDelays: AvinorColor.Grey300,
                };
                const tooltipElement = (_jsxs(ToolTip, { children: [_jsxs(ToolTip.Title, { children: [t("charts.onTimePerformance.bar.delayCodes.xaxisTitle.delayCode"), ": ", delaycode] }), _jsx(ToolTip.SubTitle, { children: isUnknownDelayCode(delaycode)
                                ? "AIRLINE INTERNAL CODES"
                                : iataDelayCodes.map((iataDelayCode) => {
                                    if (iataDelayCode.shortName === delaycode) {
                                        return iataDelayCode.fullName;
                                    }
                                }) }), _jsx(ToolTip.List, { wide: true, children: Object.entries(listItems)
                                .filter(([, value]) => value > 0)
                                .map(([key, value]) => {
                                return (_jsx(ToolTip.ListItem, { total: true, wide: false, style: {
                                        type: "bullet",
                                        bgColor: mapKeyToColor[key],
                                    }, content: {
                                        value: t(`charts.onTimePerformance.bar.delayCodes.series.${key}`),
                                    }, status: {
                                        value,
                                    }, horizontalLine: { top: key === "totalDelays" } }, key));
                            }) })] }));
                return ReactDOMServer.renderToStaticMarkup(tooltipElement);
            },
        },
        responsive: [
            {
                breakpoint: 768,
                options: {
                    xaxis: {
                        title: {
                            text: undefined,
                        },
                    },
                    yaxis: {
                        title: {
                            text: undefined,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                        },
                    },
                    annotations: undefined,
                },
            },
        ],
    };
    return (_jsx(ReactApexChart, { options: options, series: series, type: "bar", height: is4kScreen ? 1200 : 600 }));
};
export default BarChart;
