import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlaneRightOutlined } from "@common-packages/react-icons";
import { useTranslation } from "react-i18next";
import { getDateToReadableText } from "@utils/DateUtils";
import Widget from "@components/Widget/Widget";
import { FlightMovementsToday, FlightMovementsTotal } from "../components/FlightMovementsToday";
export const FlightMovementsTotalsWidget = ({ format = "normal" }) => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { style: { justifyContent: "space-between" }, children: [_jsx(Widget.Header, { title: t("flightMovements.widgets.today.title"), subtitle: getDateToReadableText(), alignment: format == "small" ? "1line" : "2lines", icon: format == "small"
                    ? undefined
                    : {
                        component: (_jsx(PlaneRightOutlined, { width: "1.5rem", height: "1.5rem" })),
                    } }), _jsxs("div", { style: { display: "grid", gridTemplateColumns: "1fr 1fr", alignItems: "center" }, children: [_jsx(Widget.NumericIndicator, { children: _jsx(FlightMovementsTotal, {}) }), _jsx(FlightMovementsToday, { format: "xsmall" })] })] }));
};
FlightMovementsTotalsWidget.displayName = "FlightMovementsTotalsWidget";
