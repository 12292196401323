import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@digdir/designsystemet-react";
import { CheckmarkCircleIcon } from "@navikt/aksel-icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useProcessStatus from "@features/Plan/hooks/useProcessStatus";
import { saveProcessStatuses } from "@features/Plan/services";
import { useIataContext } from "@contexts/IataContext";
import { PlanCreateErrorModal } from "./PlanCreateErrorModal";
export const PlanCreateNoIssuesButton = ({ selectedDayFormatted, processTypeId, }) => {
    const queryClient = useQueryClient();
    const { iata } = useIataContext();
    const { t } = useTranslation();
    const errorModalRef = useRef(null);
    const [errorModalState, setErrorModalState] = useState({});
    const { processStatus } = useProcessStatus({
        date: selectedDayFormatted,
        processTypeId: processTypeId,
    });
    const saveProcessStatusesMutation = useMutation({
        mutationFn: (processesToSave) => saveProcessStatuses({
            iata: iata,
            processesToSave: processesToSave.map((process) => ({
                id: process.id,
                disruptions: process.disruptions,
                processTypeId: process.processType.id,
                date: process.date,
                updatedAt: process.updatedAt,
                updatedBy: process.updatedBy,
            })),
        }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["getPlanOverview"] });
            queryClient.refetchQueries({ queryKey: ["getPlanOverview"] });
        },
        onError: (error) => {
            var _a, _b, _c, _d;
            console.error(error);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const isUnauthorized = ((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.request) === null || _b === void 0 ? void 0 : _b.status) == 401;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const isConflict = ((_d = (_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.request) === null || _d === void 0 ? void 0 : _d.status) == 409;
            setErrorModalState({
                message: isUnauthorized
                    ? t("plan.create.widget.unauthorizedError")
                    : isConflict
                        ? t("plan.create.widget.conflictError")
                        : t("plan.create.widget.unexpectedError"),
            });
            setTimeout(() => {
                var _a;
                (_a = errorModalRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
            }, 0);
            queryClient.invalidateQueries({ queryKey: ["getPlanOverview"] });
            queryClient.refetchQueries({ queryKey: ["getPlanOverview"] });
        },
    });
    const saveEmptyStatus = () => {
        if (processStatus) {
            saveProcessStatusesMutation.mutate([processStatus]);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Button, { color: "accent", size: "sm", variant: "primary", onClick: () => saveEmptyStatus(), children: [_jsx(CheckmarkCircleIcon, { title: "a11y-title", fontSize: "1.5rem" }), t("plan.create.fillInProcess.noIssues")] }), _jsx(PlanCreateErrorModal, { errorModalRef: errorModalRef, modalState: errorModalState })] }));
};
