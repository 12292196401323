import { AvinorColor } from "@constants";
export const defaultStyles = {
    placeholder: (base, state) => (Object.assign(Object.assign({}, base), { color: state.selectProps.menuIsOpen ? AvinorColor.VioletLight400 : AvinorColor.Grey400 })),
    container: (base) => (Object.assign({}, base)),
    control: (base, props) => {
        return Object.assign(Object.assign({}, base), { border: `1px solid ${AvinorColor.Grey300}`, cursor: "pointer", borderRadius: 0, backgroundColor: "var(--avinor-grey-50)", boxShadow: props.isFocused ? "0px 0px 0px 0.125rem var(--avinor-blue-400)" : base.boxShadow, "&:hover": {
                borderColor: AvinorColor.VioletLight500,
                backgroundColor: AvinorColor.VioletLight50,
                color: AvinorColor.VioletLight400,
            } });
    },
    option: (base, state) => (Object.assign(Object.assign({}, base), { cursor: "pointer", color: state.isSelected
            ? AvinorColor.VioletLight50
            : state.isFocused
                ? AvinorColor.VioletLight400
                : AvinorColor.Grey800, backgroundColor: state.isSelected
            ? AvinorColor.VioletLight400
            : state.isFocused
                ? AvinorColor.VioletLight50
                : AvinorColor.White, ":active": {
            backgroundColor: AvinorColor.VioletLight500,
            color: AvinorColor.White,
        } })),
    menu: (base) => (Object.assign(Object.assign({}, base), { borderRadius: 0, marginTop: 1, boxShadow: "0px 0.125rem 0.25rem 0px #0000001A" })),
    multiValue: (base) => (Object.assign(Object.assign({}, base), { backgroundColor: AvinorColor.VioletLight400, color: AvinorColor.White })),
    multiValueLabel: (base) => (Object.assign(Object.assign({}, base), { color: AvinorColor.White })),
    multiValueRemove: (base) => (Object.assign(Object.assign({}, base), { color: AvinorColor.White, "&:hover": {
            color: AvinorColor.White,
            backgroundColor: AvinorColor.VioletLight400,
        } })),
    indicatorSeparator: (base) => (Object.assign(Object.assign({}, base), { display: "none" })),
    dropdownIndicator: (base, state) => (Object.assign(Object.assign({}, base), { color: state.selectProps.menuIsOpen ? AvinorColor.VioletLight400 : AvinorColor.Grey800, "&:hover": {
            color: state.selectProps.menuIsOpen ? AvinorColor.VioletLight400 : AvinorColor.Grey800,
        } })),
    clearIndicator: (base) => (Object.assign(Object.assign({}, base), { color: AvinorColor.Grey800, "&:hover": {
            color: AvinorColor.Grey800,
        } })),
};
