import { jsx as _jsx } from "react/jsx-runtime";
import { PlaneDownOutlined, Turnaround, PlaneUpRightOutlined } from "@common-packages/react-icons";
import { useRecoveredDelay } from "@features/OnTimePerformance/hooks/useRecoverDelay";
import { useArrivalPunctuality, useDeparturePunctuality, } from "@features/OnTimePerformance/hooks/useRegularityAndPunctualityPeriodData";
import { useInboundTaxitime, useOutboundTaxitime } from "@features/OnTimePerformance/hooks/useTaxiTime";
export var OnTimePerformanceServiceQualityStepperSteps;
(function (OnTimePerformanceServiceQualityStepperSteps) {
    OnTimePerformanceServiceQualityStepperSteps["Arrival"] = "Arrival";
    OnTimePerformanceServiceQualityStepperSteps["InboundTaxitime"] = "Inbound Taxitime";
    OnTimePerformanceServiceQualityStepperSteps["ArrivalPunctuality"] = "Arrival Punctuality";
    OnTimePerformanceServiceQualityStepperSteps["Turnaround"] = "Turnaround";
    OnTimePerformanceServiceQualityStepperSteps["RecoveredDelay"] = "Recovered delay";
    OnTimePerformanceServiceQualityStepperSteps["Departure"] = "Departure";
    OnTimePerformanceServiceQualityStepperSteps["DeparturePunctuality"] = "Departure Punctuality";
    OnTimePerformanceServiceQualityStepperSteps["OutboundTaxitime"] = "Outbound Taxitime";
})(OnTimePerformanceServiceQualityStepperSteps || (OnTimePerformanceServiceQualityStepperSteps = {}));
export const steps = [
    {
        key: OnTimePerformanceServiceQualityStepperSteps.Arrival,
        icon: (_jsx(PlaneDownOutlined, { width: "2rem", height: "2rem" })),
    },
    { key: OnTimePerformanceServiceQualityStepperSteps.InboundTaxitime, hook: useInboundTaxitime },
    { key: OnTimePerformanceServiceQualityStepperSteps.ArrivalPunctuality, hook: useArrivalPunctuality },
    {
        key: OnTimePerformanceServiceQualityStepperSteps.Turnaround,
        icon: (_jsx(Turnaround, { width: "2rem", height: "2rem" })),
    },
    {
        key: OnTimePerformanceServiceQualityStepperSteps.RecoveredDelay,
        hook: useRecoveredDelay,
    },
    {
        key: OnTimePerformanceServiceQualityStepperSteps.Departure,
        icon: (_jsx(PlaneUpRightOutlined, { width: "2rem", height: "2rem" })),
    },
    {
        key: OnTimePerformanceServiceQualityStepperSteps.DeparturePunctuality,
        hook: useDeparturePunctuality,
    },
    {
        key: OnTimePerformanceServiceQualityStepperSteps.OutboundTaxitime,
        hook: useOutboundTaxitime,
    },
];
