import { format, parse } from "date-fns";
import "./PlanCreateCalendar.scss";
//Get the class name for the event based on the disruption and if it is editable
export const getEventClassname = (disruption, isEditable) => {
    let className = "plan-create-calendar-event-normaloperation";
    if (disruption.someDisturbances) {
        className = "plan-create-calendar-event-somedisturbances";
    }
    if (disruption.majorDisturbances) {
        className = "plan-create-calendar-event-majorDisturbances";
    }
    if (!isEditable) {
        className += " plan-create-calendar-event-disabled";
    }
    return className;
};
//Converts the disruptions from the processStatus to events for the calendar
export const getEvents = (processStatus, currentDay, isEditable) => {
    const planCalendarEvents = [];
    const selectedDate = format(currentDay, "yyyy-MM-dd");
    const selectedDatePlusOne = format(new Date().setDate(currentDay.getDate() + 1), "yyyy-MM-dd");
    // Sort disruptions by start time to ensure consecutive ones are together
    const sortedDisruptions = [...processStatus.disruptions].sort((a, b) => parseInt(a.timePeriodFrom.replace(":", ""), 10) - parseInt(b.timePeriodFrom.replace(":", ""), 10));
    let currentEvent = null;
    sortedDisruptions.forEach((x, index) => {
        if (!x.normalOperation) {
            const eventStart = `${selectedDate}T${x.timePeriodFrom}:00`;
            const eventEnd = x.timePeriodTo == "23:59" ? `${selectedDatePlusOne}T00:00:00` : `${selectedDate}T${x.timePeriodTo}:00`;
            if (!currentEvent) {
                // Start a new event
                currentEvent = {};
                currentEvent.start = eventStart;
                currentEvent.end = eventEnd;
                currentEvent.className = getEventClassname(x, isEditable);
                currentEvent.extendedProps = x;
            }
            else {
                const previousDisruption = sortedDisruptions[index - 1];
                // Check if current disruption is consecutive and has the same details
                if (previousDisruption &&
                    previousDisruption.timePeriodTo === x.timePeriodFrom &&
                    previousDisruption.comments === x.comments &&
                    previousDisruption.disruptionTypeId === x.disruptionTypeId &&
                    previousDisruption.someDisturbances == x.someDisturbances &&
                    previousDisruption.majorDisturbances == x.majorDisturbances &&
                    previousDisruption.normalOperation == x.normalOperation) {
                    // Extend the current event's end time
                    currentEvent.end = eventEnd;
                }
                else {
                    // Push the current event to the list and start a new one
                    planCalendarEvents.push(currentEvent);
                    currentEvent = {};
                    currentEvent.start = eventStart;
                    currentEvent.end = eventEnd;
                    currentEvent.className = getEventClassname(x, isEditable);
                    currentEvent.extendedProps = x;
                }
            }
        }
    });
    // Push the last event if it exists
    if (currentEvent) {
        planCalendarEvents.push(currentEvent);
    }
    return planCalendarEvents;
};
//Normalize time to 23:59 if it is 00:00
export const normalizeTime = (time) => {
    if (time === "00:00") {
        return "23:59"; // Normalize 00:00 to 23:59
    }
    return time;
};
//Formats a date to HH:mm and normalizes it (00:00 -> 23:59)
export const formatAndNormalizeTime = (date) => {
    const formattedTime = format(date, "HH:mm");
    return normalizeTime(formattedTime);
};
//Parses a time string (Assuming times are in 24-hour format, e.g., "06:00", "13:00") to a Date object
export const parseTime = (time) => {
    return parse(time, "HH:mm", new Date());
};
