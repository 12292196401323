import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PeopleGroup3Outlined } from "@common-packages/react-icons";
import { useQuery } from "@tanstack/react-query";
import { addMinutes, isAfter, isBefore, startOfMinute } from "date-fns";
import { useTranslation } from "react-i18next";
import { formatDate } from "@utils/DateUtils";
import { PaxPredictionDirection } from "@components/Filters/PaxPredictionDirectionFilter/PaxPredictionDirectionFilter";
import { Grid } from "@components/Layout";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { FormatPattern } from "@enums";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
import { getPaxPredictionGraphData } from "../services";
import "./PaxNextHourSummaryCard.scss";
export const PaxNextHourSummaryCard = ({ format = "small", direction, }) => {
    const { iata } = useIataContext();
    const { picker: { from }, } = useDatePickerContext();
    const { utc } = useUtcContext();
    const formattedFrom = formatDate(utc, from, FormatPattern.yyyy_MM_dd);
    const { data, isLoading } = useQuery({
        queryFn: () => getPaxPredictionGraphData({
            iata,
            date: formattedFrom,
            direction: direction == "arrival" ? PaxPredictionDirection.Arrival : PaxPredictionDirection.Departure,
        }),
        queryKey: ["getPaxPredictionGraphData", iata, formattedFrom, direction],
    });
    let paxEnrouteNextHourTotal = "-";
    let paxEnrouteNextHourInnland = "-";
    let paxEnrouteNextHourUtland = "-";
    if (data && data.intervals && data.intervals.length > 0) {
        const now = new Date();
        const currentIntervalStart = startOfMinute(addMinutes(now, -now.getMinutes() % 15)); // Start of the current 15-minute interval
        const oneHourLater = addMinutes(currentIntervalStart, 60); // Add 1 hour to get the boundary for the next hour
        // Filter intervals for the current 15 mins + 1 hour
        const nextHourIntervals = data.intervals.filter((interval) => isAfter(new Date(interval.from), currentIntervalStart) &&
            isBefore(new Date(interval.from), oneHourLater));
        // Sum domestic and international passengers
        const totalDomestic = nextHourIntervals.reduce((sum, interval) => sum + interval.domestic, 0);
        const totalInternational = nextHourIntervals.reduce((sum, interval) => sum + interval.schengen + interval.nonSchengen, 0);
        paxEnrouteNextHourInnland = totalDomestic.toLocaleString("nb-NO");
        paxEnrouteNextHourUtland = totalInternational.toLocaleString("nb-NO");
        paxEnrouteNextHourTotal = (totalDomestic + totalInternational).toLocaleString("nb-NO");
    }
    const { t } = useTranslation();
    if (isLoading)
        return _jsx(Spinner, {});
    return (_jsxs("div", { className: "pax-next-hour-summary-card", children: [_jsxs("div", { className: "pax-next-hour-summary-card-total", children: [_jsx(PeopleGroup3Outlined, { width: "2.5rem", height: "2.5rem", color: "var(--ds-color-neutral-13)" }), _jsx(Widget.NumericIndicator, { size: "small", children: paxEnrouteNextHourTotal })] }), _jsxs(Grid, { columns: 2, children: [_jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", title: t("domestic"), value: paxEnrouteNextHourInnland, valueSize: format }), _jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", title: t("utland"), value: paxEnrouteNextHourUtland, valueSize: format })] })] }));
};
