import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./PlanOverviewAirportWidget.scss";
import { isToday, isYesterday } from "date-fns";
import { useTranslation } from "react-i18next";
import Widget from "@components/Widget/Widget";
import PlanOverviewAirportFlights from "@features/Plan/components/PlanOverview/PlanOverviewAirportFlights";
import PlanOverviewAirportPax from "@features/Plan/components/PlanOverview/PlanOverviewAirportPax";
import PlanOverviewWeatherStatus from "@features/Plan/components/PlanOverview/PlanOverviewWeatherStatus";
import { useDatePickerContext } from "@contexts/DatePickerContext";
export const PlanOverviewAirportWidget = ({ iata }) => {
    const { t } = useTranslation();
    const subTitle = t(`airportIata.${iata.toLowerCase()}`);
    const { picker: { from }, } = useDatePickerContext();
    const datePickerIsToday = isToday(from);
    const datePickerIsYesterday = isYesterday(from);
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: iata === null || iata === void 0 ? void 0 : iata.toUpperCase(), subtitle: subTitle }), _jsxs(Widget.Body, { children: [datePickerIsToday && (_jsx("div", { className: "plan-overview-airport-weather", children: _jsx(PlanOverviewWeatherStatus, { iata: iata }) })), _jsx("div", { className: "plan-overview-airport-flights", children: _jsx(PlanOverviewAirportFlights, { iata: iata }) }), !datePickerIsYesterday && _jsx(PlanOverviewAirportPax, { iata: iata })] })] }));
};
PlanOverviewAirportWidget.displayName = "PlanOverviewAirportWidget";
