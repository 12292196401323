import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PersonGroupFillIcon, WrenchFillIcon } from "@navikt/aksel-icons";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import usePlanMetadata from "@features/Plan/hooks/usePlanMetadata";
import "./PlanOverviewDisruptionAreaTooltip.scss";
export const PlanOverviewDisruptionAreaTooltip = ({ disruption, id }) => {
    var _a;
    const { planMetaData } = usePlanMetadata();
    const { t } = useTranslation();
    const getCause = (sd) => {
        var _a;
        const cause = (_a = planMetaData === null || planMetaData === void 0 ? void 0 : planMetaData.causesOfDisruptions.find((x) => x.id == sd.disruptionTypeId)) === null || _a === void 0 ? void 0 : _a.disruptionName;
        if (cause) {
            return t("plan.create.disruptions.causes." + cause);
        }
        else
            return "";
    };
    return (_jsxs(Tooltip, { id: id, children: [_jsx("h3", { children: disruption.timePeriodFrom + " - " + disruption.timePeriodTo }), _jsx("div", { children: (_a = disruption.subDisruptions) === null || _a === void 0 ? void 0 : _a.map((sd, index) => {
                    return (_jsxs("div", { className: "plan-area-tooltip", children: [_jsxs("div", { className: "plan-area-tooltip-info", children: [_jsx("span", { className: "plan-area-tooltip-info-label", children: _jsx("span", { className: sd.someDisturbances
                                                ? "plan-area-tooltip-info-circle plan-area-tooltip-info-orange"
                                                : "plan-area-tooltip-info-circle plan-area-tooltip-info-red" }) }), _jsxs("span", { className: "plan-area-tooltip-info-name", children: [sd.processTypeName, " "] }), _jsxs("span", { className: "plan-area-tooltip-info-cause", children: [sd.disruptionTypeId == 1 ? (_jsx(WrenchFillIcon, { title: "a11y-title", fontSize: "18px" })) : (_jsx(PersonGroupFillIcon, { title: "a11y-title", fontSize: "18px" })), getCause(sd)] })] }), _jsx("div", { className: "plan-area-tooltip-comments", children: _jsx("span", { children: sd.comments }) })] }, index));
                }) })] }));
};
