import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Modal } from "@digdir/designsystemet-react";
import { useTranslation } from "react-i18next";
export const LeaveWithoutSavingModal = ({ title, content, onHasChangesModalRef, onConfirm, onCancel, }) => {
    const { t } = useTranslation();
    const cancelButtonClicked = () => {
        var _a;
        if (onCancel) {
            onCancel();
        }
        (_a = onHasChangesModalRef.current) === null || _a === void 0 ? void 0 : _a.close();
    };
    return (_jsx(Modal.Root, { children: _jsxs(Modal.Dialog, { ref: onHasChangesModalRef, children: [_jsx(Modal.Header, { children: title || t("modals.leaveWithoutSavingModal.header") }), _jsx(Modal.Content, { children: content || t("modals.leaveWithoutSavingModal.content") }), _jsx(Modal.Footer, { children: _jsxs("div", { style: { display: "flex", justifyContent: "flex-end", width: "100%", gap: "0.625rem" }, children: [_jsx(Button, { color: "accent", size: "sm", variant: "primary", onClick: () => {
                                    var _a;
                                    (_a = onHasChangesModalRef.current) === null || _a === void 0 ? void 0 : _a.close();
                                    onConfirm();
                                }, children: t("modals.leaveWithoutSavingModal.buttonConfirm") }), _jsx(Button, { color: "accent", size: "sm", variant: "tertiary", onClick: () => cancelButtonClicked(), children: t("modals.leaveWithoutSavingModal.buttonCancel") })] }) })] }) }));
};
